.Francisca-program-wrp {
    .each-program-sec {
        ul {
            li {
                display: flex;
                align-items: center;
            margin-bottom: 15px;
                span{
                    width: 40px;
                    height: 40px;
                    display: block;
                    svg{
                        width: 40px;
                        height: 40px;
                    }
                }
                p {
                    margin: 0;
                    padding-left: 15px;
                    text-align: left;
                    color: var(--Text82);
                }
            }
        }
        &.Green1C{
            span{
                svg {
                    circle {
                        fill: var(--Green1C);
                    }
                }
            }
        }
        &.Amaranth{
            span{
                svg {
                    circle {
                        fill: var(--Amaranth);
                    }
                }
            }
        }
        &.Cobalt {
            span{
                svg {
                    circle {
                        fill: var(--Cobalt);
                    }
                }
            }
        }
        &.Monza {
            span{
                svg {
                    circle {
                        fill: var(--Monza);
                    }
                }
            }
        }
    }
}