.rafaelaTemplates {

    .sqr-btn {
        display: inline-block;
        padding: 10px 40px;
        margin-top: 30px;
        border-radius: 8px;
    }

    .main-banner-wrp {
        .bannerBG {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            min-height: 100vh;

            @media only screen and (max-width: 650px) {
                & {
                    min-height: calc(100vh - 50px);
                }
            }

            span {
                display: block;
                width: 100%;
                height: 100%;

                img {
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                }

            }

            @media only screen and (min-width: 650px) {
                & {
                    .mobBG {
                        display: none;
                    }
                }
            }

            @media only screen and (max-width: 650px) {
                & {
                    .pcBG {
                        display: none;
                    }
                }
            }

            &+div {
                z-index: 9;
            }
        }

        .banner-align {
            display: flex;
            height: 100vh;
            width: 100%;
            align-items: center;

            @media only screen and (max-width: 650px) {
                & {
                    height: calc(100vh - 50px);
                    padding-bottom: 25px;
                }
            }

            .banner-con-sec {
                max-width: 550px;
                background: url(./images/bannerLogoBG.png);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;

                .banner-con-box {
                    display: flex;
                    flex-direction: column;
                    border-radius: 8px;
                    padding: 25px 30px;

                    span {
                        display: block;
                        max-width: 275px;
                        margin: 0 auto;
                        ;
                        flex: 1;
                    }

                    @media only screen and (min-width: 650px) {
                        & {
                            .mob-icon {
                                display: none;
                            }
                        }
                    }

                    @media only screen and (max-width: 650px) {
                        & {
                            .pc-icon {
                                display: none;
                            }

                            .mob-icon {
                                max-width: 230px;
                                width: 100%;
                                margin: 0 auto;
                            }
                        }
                    }

                    .banner-con {
                        flex: 2;
                        padding-left: 35px;

                        h1 {
                            margin: 0;
                            color: var(--Blue);
                            font-family: var(--Shadows);
                            font-weight: 700;
                            font-size: 45px;
                            line-height: 1;
                            text-align: center;
                        }

                        p {
                            margin-top: 10px;
                            margin-bottom: 0;
                            color: #616279;
                        }

                        @media only screen and (max-width: 650px) {
                            & {
                                .pc-icon {
                                    display: none;
                                }

                                h1 {
                                    color: var(--White);
                                    font-size: 24px;
                                    text-align: center;

                                    br {
                                        display: none;
                                    }
                                }

                                p {
                                    display: none;
                                }
                            }
                        }
                    }

                    @media only screen and (max-width: 650px) {
                        & {
                            background: none;
                            flex-direction: column;
                            padding: 0;
                            padding-bottom: 40px;
                        }
                    }
                }

                .sqr-btn {
                    background: var(--RedED);
                    color: var(--White, #FFFFFF);
                    margin: 0 auto;
                    display: block;
                    width: 70%;

                    @media only screen and (max-width: 650px) {
                        & {
                            width: 100%;
                        }
                        &:not(:last-child) {
                            margin-right: 0px;
                        }
                    }
                }
            }

        }
    }

    .service-slider-sec {
        padding: 50px 0 0;
    }

    .about-section-wrp {
        .about-grid-wrp.f-wrp {
            display: flex;
            flex-wrap: wrap;
            gap: 100px;
            align-items: center;

            .about-con-sec,
            .about-img-sec {
                flex: 1;
            }

            .about-con-sec {
                text-align: center;

                h1 {
                    display: flex;
                    color: var(--RedED);
                    font-family: var(--Shadows);
                    align-items: center;
                    line-height: 1;
                    justify-content: center;
                    font-size: 42px;

                    img {
                        height: 25px;
                        object-fit: contain;
                        object-position: left;
                        padding-left: 15px;
                        width: auto;
                    }
                }

                p {
                    color: var(--Text00);
                }

                span {
                    font-family: var(--Cedarville);
                    color: var(--RedED);
                    font-size: 25px;
                }
            }

            @media only screen and (max-width: 1200px) {
                & {
                    gap: 30px;
                }
            }

            @media only screen and (max-width: 991px) {
                & {
                    flex-direction: column;
                }
            }
        }
    }

    .contact-blk-wrp {

        .contact-sec-blk {
            display: flex;
            max-width: 1920px;

            @media only screen and (max-width: 767px) {
                & {
                    flex-direction: column;
                }
            }

            .contact-con {
                flex: 2;
                background: var(--Purple);

                &>.f-wrp {
                    max-width: 600px;
                    padding: 30px 0 60px;
                    margin: 0 auto;
                    float: none;
                    text-align: center;
                    color: var(--White);
                }

                h1 {
                    margin-bottom: 10px;
                }

                h4 {
                    margin-top: 0;
                }

                h1,
                h4 {
                    font-family: var(--Walter);
                }

                p {
                    max-width: 550px;
                    margin: 0 auto;
                    margin-bottom: 25px;
                    padding: 0 15px;
                }

                ul {
                    display: grid;
                    grid-template-columns: repeat(auto-fill, 200px);
                    justify-content: center;

                    @media only screen and (max-width: 1200px) {
                        & {
                            grid-template-columns: repeat(auto-fill, 300px);
                        }
                    }

                    li {
                        padding-left: 15px;
                        padding-right: 15px;

                        .each-contact-block {
                            margin-bottom: 15px;

                            .contact-icon {
                                display: block;
                                width: 75px;
                                margin: 0 auto;
                            }

                            p {
                                margin-top: 10px;
                                margin-bottom: 0;
                                padding: 0;
                                white-space: pre-wrap;
                                white-space: -moz-pre-wrap;
                                white-space: -pre-wrap;
                                white-space: -o-pre-wrap;
                                word-wrap: break-word;
                            }
                        }
                    }
                }

                .sqr-btn {
                    background: var(--RedED);
                    color: var(--White);
                    display: block;
                    width: 90%;
                    margin: 0 auto;
                    margin-top: 30px;
                }
            }

            .contact-img {
                flex: 1;

                span {
                    position: relative;
                    width: 100%;
                    display: block;
                    height: 100%;

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: bottom;

                        @media only screen and (max-width: 767px) {
                            & {
                                position: relative;
                            }
                        }
                    }
                }
            }
        }
    }

    .program-sec-wrp {
        .program-sec-header {
            text-align: center;

            h1 {
                font-family: var(--Walter);
                color: var(--Purple);
                margin-bottom: 15px;
                margin-top: 0;
            }

            p {
                margin: 0;
                color: var(--PurpleText);
            }
        }

        .program-con-wrp {
            background: var(--RedED);

            .program-grid {
                display: grid;
                grid-template-columns: repeat(auto-fill, 320px);
                justify-content: center;
                padding-top: 50px;
                padding-bottom: 10px;

                @media only screen and (max-width: 1200px) {
                    & {
                        grid-template-columns: repeat(auto-fill, 300px);
                    }
                }

                .each-program-sec {
                    margin-bottom: 25px;
                    text-align: center;
                    color: var(--White);

                    .program-icon {
                        display: block;
                        width: 75px;
                        margin: 0 auto;
                    }

                    h3 {
                        font-family: var(--Walter);
                    }

                    p {
                        margin-top: 10px;
                        margin-bottom: 0;
                        padding: 0;
                        white-space: pre-line;

                        i {
                            font-size: 8px;
                        }
                    }
                }
            }
        }
    }

    .credential-sec-wrp {
        background: var(--Blue);
        padding-bottom: 50px;

        .credential-header {
            padding-bottom: 30px;
            padding-top: 30px;

            h1 {
                text-align: center;
                font-family: var(--Walter);
                color: var(--White);
            }
        }

        .credential-content-wrp {
            display: flex;
            gap: 30px;

            @media only screen and (max-width: 991px) {
                & {
                    flex-direction: column;
                }
            }

            .each-credential-blk {
                display: flex;
                flex: 1;
                align-items: center;

                @media only screen and (max-width: 991px) {
                    & {
                        flex: unset;
                    }
                }

                .credential-img-sec {
                    display: block;
                    max-width: 115px;

                    @media only screen and (max-width: 767px) {
                        & {
                            max-width: 75px;
                        }
                    }
                }

                .credential-content-sec {
                    padding-left: 20px;

                    p {
                        color: var(--White);
                        white-space: pre-line;
                        margin: 0;
                        font-size: 14px;
                    }
                }
            }
        }
    }


    .activities-section-wrp {
        .activities-grid-wrp.f-wrp {
            display: flex;
            flex-wrap: wrap;
            gap: 100px;
            align-items: center;

            .activities-con-sec,
            .activities-img-sec {
                flex: 1;
            }

            .activities-con-sec {
                text-align: center;

                h1 {
                    color: var(--Blue);
                    font-family: var(--Walter);
                    text-align: left;
                }

                p {
                    color: var(--Text36);
                    text-align: left;
                }

                span {
                    font-family: var(--Cedarville);
                    color: var(--Text00);
                    font-size: 25px;
                    width: 35px;
                }

                ul {
                    column-count: 2;

                    @media only screen and (max-width: 600px) {
                        & {
                            column-count: 1;
                            padding-left: 10%;
                        }
                    }

                    li {
                        display: flex;
                        align-items: center;
                        margin-bottom: 15px;

                        p {
                            margin: 0;
                            padding-left: 15px;
                            text-align: left !important;

                            @media only screen and (max-width: 600px) {
                                & {
                                    text-align: left !important;
                                }
                            }
                        }
                    }
                }
            }

            @media only screen and (max-width: 1200px) {
                & {
                    gap: 30px;
                }
            }

            @media only screen and (max-width: 991px) {
                & {
                    flex-direction: column-reverse;

                    h1 {
                        text-align: center !important;
                    }

                    p {
                        text-align: center !important;
                    }
                }
            }
        }
    }

    .gallery-section-wrp {
        .gallery-sec-header {
            text-align: center;
            background: var(--RedED);

            h1 {
                font-family: var(--Walter);
                color: var(--White);
            }
        }
    }

    .theme-band {
        background: var(--Blue);
    }

    .theme-band {
        button {
            margin: 0 auto;
            display: table;
            background: var(--White);
            color: var(--Text00);
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }

    .about-founder-wrp {
        .founder-sec-header {
            text-align: center;
            background: var(--Purple);
            color: var(--White);

            h1 {
                font-family: var(--Walter);
                color: var(--White);
            }

            @media only screen and (max-width: 767px) {
                & {
                    padding-bottom: 200px;

                    .founder-img,
                    .founder-con-blk {
                        display: none;
                    }
                }
            }
        }

        .founder-con-sec.founder1 {
            background: var(--Blue);
            padding-top: 70px;

            .founder-con-wrp {
                max-width: 767px;
                margin: 0 auto;
                align-items: center;
            }

            padding-bottom: 90px;
        }

        .founder-con-sec.founder2 {
            margin-top: -85px;

            .each-founder-sec {
                max-width: 250px;
                text-align: center;

                @media only screen and (max-width: 767px) {
                    & {
                        margin: 0 auto;
                        float: none;
                    }
                }

                .founder-icon {
                    width: 150px;
                    display: block;
                    margin: 0 auto;
                }

                h3 {
                    font-family: var(--Walter);
                    color: var(--Blue);
                    margin-bottom: 0;
                }

                span {
                    color: var(--Text00);
                    font-size: 14px;
                }

                p {
                    color: var(--Blue);
                }
            }
        }


        .founder-con-wrp {
            display: flex;

            h1 {
                font-family: var(--Walter);
                color: var(--White);
            }

            @media only screen and (max-width: 767px) {
                & {
                    flex-direction: column;
                }
            }

            .founder-img {
                flex: 1;
                padding-right: 140px;

                @media only screen and (min-width: 1200px) {
                    & {
                        padding-right: 95px;
                    }
                }

                @media only screen and (min-width: 992px) {
                    & {
                        padding-right: 50px;
                    }
                }

                @media only screen and (min-width: 576px) {
                    & {
                        padding-right: 25px;
                    }
                }

                @media only screen and (max-width: 767px) {
                    & {
                        padding-right: 0px;
                        margin-top: 0px !important;
                        max-width: 200px;
                        display: block;
                    }
                }


                h2 {
                    font-family: var(--Walter);
                    color: var(--Purple);
                    text-align: center;
                    margin-bottom: 0;
                }

                p {
                    text-align: center;
                    margin-top: 0;
                }
            }

            .founder-con-blk {
                flex: 2;

                @media only screen and (max-width: 767px) {
                    & {
                        text-align: center;
                    }
                }
            }
        }

        .founder-con-sec {
            .founder-con-wrp {
                p {
                    color: var(--White);

                    @media only screen and (max-width: 767px) {
                        & {
                            text-align: center;
                        }
                    }
                }

                .mob-para {
                    display: none;

                    @media only screen and (max-width: 767px) {
                        & {
                            display: block;
                        }
                    }
                }
            }
        }

    }

    .testimonial-section-wrp {
        .testimonial-sec-header {
            h1 {
                font-family: var(--Walter);
                color: var(--Blue);
                text-align: center;
            }
        }
    }



    .pay-optiopn-wrp {
        background: var(--RedED);
        padding: 25px 0 50px;
        margin-top: 155px;

        @media only screen and (max-width: 767px) {
            & {
                .row {
                    flex-direction: column-reverse;
                }
            }
        }

        .payment-content-sec {
            max-width: 475px;

            @media only screen and (max-width: 767px) {
                & {
                    padding-top: 50px;
                }
            }

            @media only screen and (min-width: 767px) {
                & {
                    button {
                        display: none;
                    }
                }
            }

            button {
                width: 100%;
                background: var(--White);
                color: var(--Orange);
            }

            * {
                color: var(--White);
            }

            h1 {
                font-family: var(--Walter);
            }
        }

        .payment-button-sec {
            max-width: 475px;
            float: right;

            span {
                margin-top: -175px;
                display: block;
            }

            .sqr-btn {
                width: 100%;
                background: var(--White);
                color: var(--Orange);

                @media only screen and (max-width: 767px) {
                    & {
                        display: none;
                    }
                }
            }
        }

    }

    @media only screen and (max-width: 650px) {
        .gap.f-wrp {
            &+.gap {
                display: none;
            }
        }
    }

}