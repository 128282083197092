.TemplatesOne {
    .sqr-btn {
        display: inline-block;
        padding: 10px 40px;
        margin-top: 30px;
        border-radius: 8px;
    }

    .main-banner-wrp {
        .bannerBG {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            min-height: 100vh;

            .scroll-top,
            .scroll-bottom {
                position: absolute;
                left: 0;
            }

            .scroll-top {
                top: -1px;
            }

            .scroll-bottom {
                bottom: -1px;
            }

            @media only screen and (max-width: 650px) {
                & {
                    min-height: calc(100vh - 50px);
                }
            }

            span {
                display: block;
                width: 100%;
                height: 100%;

                img {
                    height: 100%;
                    object-fit: cover;
                    object-position: bottom;
                }

                &.scrollBG {
                    width: 50px;
                }
            }

            @media only screen and (min-width: 650px) {
                & {
                    .mobBG {
                        display: none;
                    }
                }
            }

            @media only screen and (max-width: 650px) {
                & {
                    .pcBG {
                        display: none;
                    }
                }
            }

            &+div {
                z-index: 9;
            }
        }

        .banner-align {
            display: flex;
            min-height: 100vh;
            width: 100%;
            align-items: center;
            height: 100%;

            @media only screen and (max-width: 650px) {
                & {
                    height: calc(100vh - 50px);
                    min-height: auto;
                    align-items: center;
                    padding-bottom: 25px;
                }
            }

            .banner-con-sec {
                max-width: 750px;
                padding: 15px 0 30px;

                @media only screen and (max-width: 650px) {
                    & {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                    }
                }

                .banner-con-box {
                    display: flex;


                    @media only screen and (min-width: 650px) {
                        & {
                            .mob-icon {
                                display: none;
                            }
                        }
                    }

                    @media only screen and (max-width: 650px) {
                        & {
                            .pc-icon {
                                display: none;
                            }

                            .mob-icon {
                                max-width: 230px;
                                width: 100%;
                                margin: 0 auto;
                            }
                        }
                    }

                    .banner-con {
                        padding-top: 20px;
                        flex: 2;

                        span {
                            display: block;
                            width: 150px;
                            height: 100px;
                            padding-bottom: 8px;

                            @media only screen and (max-width: 650px) {
                                & {
                                    margin: 0 auto;
                                }
                            }

                            img {
                                height: 100%;
                                object-fit: contain;
                                object-position: left;
                            }
                        }

                        h1 {
                            margin: 0;
                            color: var(--White);
                            font-family: var(--Baloo);
                            font-weight: 700;
                            font-size: 52px;
                            line-height: 1.2;
                            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.50);
                        }

                        p {
                            margin-top: 10px;
                            margin-bottom: 0;
                            color: var(--White);
                            max-width: 475px;
                            font-weight: 500;
                        }

                        @media only screen and (max-width: 650px) {
                            & {
                                .pc-icon {
                                    display: none;
                                }

                                h1 {
                                    color: var(--White);
                                    font-size: 24px;
                                    text-align: center;

                                    // br {
                                    //     display: none;
                                    // }
                                }

                                p {
                                    text-align: center;
                                }
                            }
                        }

                        @media only screen and (max-width: 576px) {
                            & {
                                h1 {
                                    font-size: 42px;
                                    padding-left: 10px;
                                    padding-right: 10px;
                                }

                                p {
                                    font-size: 16px;
                                    padding-left: 10px;
                                    padding-right: 10px;
                                }
                            }
                        }
                    }

                    @media only screen and (max-width: 650px) {
                        & {
                            background: none;
                            flex-direction: column;
                            padding: 0;
                        }
                    }

                }

                .banner-btn-sec.f-wrp {
                    display: flex;
                    gap: 30px;
                    padding-top: 30px;

                    @media only screen and (max-width: 650px) {
                        & {
                            flex-direction: column;
                            padding-top: 0px;
                        }
                    }
                }

                .sqr-btn {
                    color: var(--White, #FFFFFF);
                    border-radius: 50px;
                    margin-top: 0px;

                    &:nth-child(1) {
                        background: var(--primary);
                    }

                    &.child2 {
                        background: var(--petrol);
                        box-shadow: 0px 4px 4px 0px #00000040;
                    }

                    // &:not(:last-child) {
                    //     margin-right: 30px;
                    // }

                    @media only screen and (max-width: 650px) {
                        & {
                            width: 100%;

                            // &:not(:last-child) {
                            //     margin-right: 0px;
                            // }
                        }
                    }
                }
            }

        }
    }

    .service-slider-sec {
        padding: 50px 0;

        .service-sec-header {
            text-align: center;
            padding-bottom: 50px;

            h1 {
                font-family: var(--Baloo);
                color: var(--petrol);
                margin-bottom: 15px;
                font-weight: 700;
                margin-top: 0;
                font-size: 32px;
            }

            p {
                max-width: 560px;
                margin: 0 auto;
                color: var(--Text36);
            }
        }
    }

    .about-section-wrp {
        .about-grid-wrp.f-wrp {
            display: flex;
            flex-wrap: wrap;
            gap: 100px;
            align-items: center;

            .about-con-sec,
            .about-img-sec {
                flex: 1;
            }

            .about-con-sec {
                text-align: left;

                h1 {
                    color: var(--petrol);
                    font-family: var(--Baloo);
                    font-size: 32px;
                    line-height: 1;
                    font-weight: 700;

                    img {
                        height: 25px;
                        object-fit: contain;
                        object-position: left;
                        padding-left: 15px;
                        width: auto;
                    }
                }

                p {
                    color: var(--Text36);
                }

                span {
                    font-family: var(--Cedarville);
                    color: var(--Purple);
                    font-size: 25px;
                }
            }

            .about-img-sec {
                display: flex;
                position: relative;
                flex-direction: column;

                span {
                    &::after {
                        content: '';
                        width: 100%;
                        height: 100%;
                        border: 20px solid var(--primaryLightShade);
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 1;
                        border-radius: 100%;
                        box-shadow: inset 0 0 2px 0px rgba(0, 0, 0, 0.5);
                    }
                }

                .about-main-img {
                    height: 80%;
                    padding-bottom: 80%;
                    width: 80%;
                    position: relative;
                    margin: 0 auto;

                    &>span {
                        height: 85%;
                        width: 85%;
                        overflow: hidden;
                        position: absolute;
                        display: block;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                    }

                    img {
                        width: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        border-radius: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                        margin: 0 auto;
                        right: 0;
                    }
                }

                .about-img-subset {
                    display: flex;
                    z-index: 2;
                    height: calc(50% - 30px);
                    width: 80%;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    margin: 0 auto;
                    right: 0;
                    gap: 30px;

                    span {
                        height: 100%;
                        width: 100%;
                        overflow: hidden;
                        position: relative;

                        img {
                            width: 100%;
                            margin: 0 auto;
                            border-radius: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                            overflow: hidden;
                        }
                    }
                }

            }

            @media only screen and (max-width: 1200px) {
                & {
                    gap: 30px;
                }
            }

            @media only screen and (max-width: 991px) {
                & {
                    flex-direction: column;
                }
            }
        }
    }

    .info-wrapper-section.f-wrp {
        margin: 0 auto;
        max-width: 835px;
        float: none;

        @media only screen and (max-width: 850px) {
            & {
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        .routine-box {
            background: var(--primaryLightShade);
            padding: 5px 45px;
            border-radius: 20px;

            @media only screen and (max-width: 576px) {
                & {
                    padding: 5px 25px;
                }
            }

            ul {
                padding-top: 45px;

                li {
                    float: left;
                    width: 50%;
                    margin-bottom: 45px;

                    @media only screen and (max-width: 650px) {
                        & {
                            width: 100%;
                        }
                    }

                    .each-routine-box {
                        display: flex;
                        align-items: center;

                        .icon-sec {
                            width: 50px;
                            display: block;
                            height: 50px;
                            background: var(--primary);
                            padding: 8px;
                            border-radius: 100%;

                            svg {
                                width: 100%;
                                height: 100%;

                                circle {
                                    fill: var(--primary);
                                }

                                path {
                                    fill: var(--white);
                                }
                            }
                        }

                        .content-sec {
                            padding-left: 20px;

                            h3 {
                                color: var(--primary);
                                font-family: var(--Baloo);
                                font-weight: 700;
                                font-size: 22px;
                            }

                            h3,
                            p {
                                margin: 0;

                            }

                            p {
                                font-size: 12px;
                                color: var(--Text36);
                            }
                        }
                    }
                }
            }
        }
    }

    .programs-slider-sec {
        .programs-sec-header {
            h1 {
                font-family: var(--Baloo);
                color: var(--petrol);
                margin-bottom: 15px;
                font-weight: 700;
                margin-top: 0;
                font-size: 32px;
                text-align: center;
            }

            P {
                max-width: 660px;
                text-align: center;
                margin: 0 auto;
                color: var(--Text36);
            }
        }
    }

    .daily-schedule-sec {
        .daily-schedule-sec-header {
            h1 {
                font-family: var(--Baloo);
                color: var(--petrol);
                margin-bottom: 15px;
                font-weight: 700;
                margin-top: 0;
                font-size: 32px;
                text-align: center;
            }

            P {
                max-width: 660px;
                text-align: center;
                margin: 0 auto;
                color: var(--Text36);
            }
        }

        .schedule-con-box {
            &.f-wrp {
                max-width: 1020px;
                margin: 0 auto;
                float: none;
                display: flex;
                flex-direction: column;
            }

            .schedule-sec-box {
                padding-top: 15px;

                &.f-wrp {
                    display: flex;
                    gap: 30px;
                    max-width: 675px;
                    text-align: center;
                    margin: 0 auto;

                    @media only screen and (max-width: 576px) {
                        & {
                            flex-direction: column;
                        }
                    }
                }

                .each-schedule-box {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-radius: 20px;
                    padding: 25px;

                    h1 {
                        font-family: var(--Baloo);
                        margin-bottom: 15px;
                        font-weight: 700;
                        margin-top: 0;
                        font-size: 32px;
                        text-align: center;
                        line-height: 1.3;
                        padding: 0 35px;
                    }

                    p {
                        color: var(--Text36);
                    }

                    span {
                        font-weight: 700;
                    }

                    &.infant {
                        background: var(--friendlyLightBG1);
                        color: var(--friendlyOrange);
                    }

                    &.Toddler {
                        background: var(--friendlyLightBG2);
                        color: var(--friendlyPink);
                    }
                }

                &+p {
                    margin: 0;
                    text-align: center;
                    padding: 30px 0;
                    color: var(--Text36);
                }
            }

            .timeTable-con-box {
                display: flex;
                gap: 30px;

                @media only screen and (max-width: 576px) {
                    & {
                        flex-direction: column;
                    }
                }

                .each-timeTable-box {
                    background: var(--friendlyLightBG);
                    border-radius: 20px;
                    padding: 25px;

                    ul {
                        li {
                            &:not(:last-child) {
                                padding-bottom: 15px;
                            }

                            span {
                                font-weight: 700;
                                color: var(--Text36);
                            }

                            p {
                                color: var(--friendlyText13);
                                margin: 0;
                            }
                        }
                    }
                }
            }


        }
    }

    .gallery-section-wrp {
        .gallery-sec-header {
            text-align: center;

            h1 {
                font-family: var(--Baloo);
                color: var(--petrol);
                margin-bottom: 15px;
                font-weight: 700;
                margin-top: 0;
                font-size: 32px;
                text-align: center;
            }

            p {
                margin-top: 0;
            }
        }
    }

    .install-blk-wrp {

        .install-sec-blk {
            display: flex;
            max-width: 1920px;
            border-radius: 24px;
            overflow: hidden;

            @media only screen and (max-width: 767px) {
                & {
                    flex-direction: column;
                }
            }

            .install-con {
                flex: 1;
                background: var(--primaryLightShade);

                &>.f-wrp {
                    max-width: 600px;
                    padding: 30px;
                    margin: 0 auto;
                    float: none;
                    // text-align: center;
                    color: var(--Text36);
                }

                h1 {
                    margin-bottom: 10px;
                    font-size: 32px;
                    font-weight: 700;
                }

                h4 {
                    margin-top: 0;
                }

                h1,
                h4 {
                    font-family: var(--Baloo);
                    color: inherit;
                }

                p {
                    max-width: 550px;
                    margin: 0 auto;
                    color: inherit;
                }

                .sqr-btn {
                    background: var(--primary);
                    color: var(--White);
                    width: 100%;
                    margin-top: 40px;
                }
            }

            .install-img {
                flex: 1.3;

                span {
                    position: relative;
                    width: 100%;
                    display: block;
                    height: 100%;

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: bottom;

                        @media only screen and (max-width: 767px) {
                            & {
                                position: relative;
                            }
                        }
                    }
                }
            }
        }
    }

    .approach-blk-wrp {
        .approach-sec-header {
            h1 {
                font-family: var(--Baloo);
                color: var(--Petrol);
                margin-bottom: 15px;
                font-weight: 700;
                margin-top: 0;
                font-size: 32px;
                text-align: center;
            }

            P {
                max-width: 660px;
                text-align: center;
                margin: 0 auto;
                color: var(--Text36);
            }
        }
    }

    .info-blk-wrp {

        .info-sec-blk {
            display: flex;
            max-width: 1920px;
            border-radius: 24px;
            overflow: hidden;

            @media only screen and (max-width: 767px) {
                & {
                    flex-direction: column-reverse;
                }
            }

            .info-con {
                flex: 1;
                background: var(--primaryLightShade);

                &>.f-wrp {
                    max-width: 600px;
                    padding: 30px;
                    margin: 0 auto;
                    float: none;
                    // text-align: center;
                    color: var(--Text36);
                }

                h1 {
                    margin-bottom: 10px;
                    font-size: 32px;
                    font-weight: 700;
                    margin-top: 0;
                }

                h4 {
                    margin-top: 0;
                }

                h1,
                h4 {
                    font-family: var(--Baloo);
                }

                p {
                    max-width: 550px;
                    margin: 0 auto;
                }

                ul {
                    padding-top: 25px;

                    li {
                        display: flex;

                        a {
                            display: inherit;
                            color: inherit;
                            font-size: inherit;
                        }

                        &:not(:last-child) {
                            margin-bottom: 25px;
                        }

                        span {
                            width: 25px;
                            height: 25px;
                            margin-right: 7px;

                            svg {
                                height: inherit;
                                width: inherit;
                            }
                        }
                    }
                }

                ul.social-media-list {
                    display: flex;
                    line-height: 1;
                    gap: 30px;
                    padding-top: 50px;

                    li {
                        margin: 0;
                        margin-bottom: 10px;

                        .social-btn {
                            padding: 0;
                            width: 50px;
                            height: 50px;
                            min-width: 10px;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                                object-position: center;
                            }
                        }
                    }
                }

                .sqr-btn {
                    background: var(--primary);
                    color: var(--White);
                    width: 100%;
                    margin-top: 30px;
                }
            }

            .info-img {
                flex: 1.3;

                span {
                    position: relative;
                    width: 100%;
                    display: block;
                    height: 100%;

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: bottom;

                        @media only screen and (max-width: 767px) {
                            & {
                                position: relative;
                            }
                        }
                    }
                }
            }
        }
    }

    .teachers-blk-wrp {
        .teachers-sec-header {
            h1 {
                font-family: var(--Baloo);
                color: var(--petrol);
                margin-bottom: 15px;
                font-weight: 700;
                margin-top: 0;
                font-size: 32px;
                text-align: center;
            }

            P {
                max-width: 660px;
                text-align: center;
                margin: 0 auto;
                color: var(--Text36);
            }
        }
    }

    .payment-blk-wrp {

        .payment-sec-blk {
            display: flex;
            max-width: 1920px;
            border-radius: 24px;
            overflow: hidden;

            @media only screen and (max-width: 767px) {
                & {
                    flex-direction: column;
                }
            }

            .payment-con {
                flex: 1;
                background: var(--primaryLightShade);

                &>.f-wrp {
                    max-width: 600px;
                    padding: 30px;
                    margin: 0 auto;
                    float: none;
                    color: var(--Text36);
                }

                h1 {
                    margin-bottom: 10px;
                    font-size: 32px;
                    font-weight: 700;
                }

                h4 {
                    margin-top: 0;
                }

                h1,
                h4 {
                    font-family: var(--Baloo);
                    color: inherit;
                }

                p {
                    max-width: 550px;
                    margin: 0 auto;
                    color: inherit;
                }

                .sqr-btn {
                    background: var(--primary);
                    color: var(--White);
                    width: 100%;
                    margin-top: 40px;
                }
            }

            .payment-img {
                flex: 1.3;

                span {
                    position: relative;
                    width: 100%;
                    display: block;
                    height: 100%;

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: bottom;

                        @media only screen and (max-width: 767px) {
                            & {
                                position: relative;
                            }
                        }
                    }
                }
            }
        }
    }

    .testimonial-blk-wrp {
        .testimonial-sec-header {
            h1 {
                font-family: var(--Baloo);
                color: var(--petrol);
                margin-bottom: 15px;
                font-weight: 700;
                margin-top: 0;
                font-size: 32px;
                text-align: center;
            }

            P {
                max-width: 660px;
                text-align: center;
                margin: 0 auto;
                color: var(--Text36);
                max-width: 500px;
            }
        }
    }

    @media only screen and (max-width: 650px) {
        .gap.f-wrp {
            &+.gap {
                display: none;
            }
        }
    }
}

.TemplateOne {
    .ppContact-popup {
        max-width: calc(100% - 20px);
        margin: 0 auto;

        .pp-contact-head {
            display: flex;
            justify-content: space-between;
            height: 50px;
            margin-bottom: 10px;
            align-items: center;

            p {
                font-size: 24px;
                font-weight: 700;
                color: var(--Text36);
                margin-top: 0;
                margin-bottom: 0px;

                @media screen and (max-width: 500px) {
                    & {
                        font-size: 20px;
                    }
                }
            }

            button {
                padding: 0;
                min-width: 0;
                min-height: 0;
                max-height: max-content;
                height: max-content;

                svg {
                    path {
                        fill: var(--Text36);
                    }
                }
            }
        }

        .pp-contact-form {
            max-width: 550px;
            margin: 0 auto;
            float: none;
            background: var(--white);
            padding: 30px;
            margin-top: 50px;
            border-radius: 15px;

            label.MuiInputLabel-formControl {
                font-size: 13px;
            }

            .MuiInputBase-root.MuiOutlinedInput-root {
                width: 100%;

            }

            .MuiOutlinedInput-notched {
                border-color: #98948A;
            }

            textarea {
                color: var(--Text36);
            }

            .app-text-input-container {
                .MuiFormControl-root {
                    .MuiInputBase-formControl {
                        input {
                            color: var(--Text36);
                        }

                        input:-internal-autofill-selected {
                            background-color: #fff !important;
                        }

                        input:-webkit-autofill,
                        input:-webkit-autofill:hover,
                        input:-webkit-autofill:focus,
                        textarea:-webkit-autofill,
                        textarea:-webkit-autofill:hover,
                        textarea:-webkit-autofill:focus,
                        select:-webkit-autofill,
                        select:-webkit-autofill:hover,
                        select:-webkit-autofill:focus {
                            -webkit-text-fill-color: var(--Text36) !important;
                            caret-color: var(--Text36) !important;

                            .light-bg & {
                                -webkit-text-fill-color: var(--Text36) !important;
                                caret-color: var(--Text36) !important;
                                -webkit-box-shadow: 0 0 0 300px var(--Text36) inset !important;
                            }
                        }
                    }
                }
            }
        }

        .MuiFormControl-root.MuiTextField-root .MuiOutlinedInput-notchedOutline,
        .MuiFormControl-root .MuiOutlinedInput-notchedOutline {
            border-color: var(--Text36);
        }

        .pp-contact-frm {
            .login-btn {
                background: var(--primary);
                border-radius: 50px;
            }
        }
    }



    &.colorPattern1 {
        --primary: #349471;
        --secondary: #87D0B6;
        --primaryLightShade: #87D0B640;
        --white: #FFFFFF;
        --petrol: #103741;
        --Text36: #363D3F;
        --serviceClr1: #349471;
        --serviceBg1: #87D0B640;
        --serviceClr2: #FA3E86;
        --serviceBg2: #FA3E8640;
        --serviceClr3: #3ED7FA;
        --serviceBg3: #3ED7FA40;
        --serviceClr4: #49C4BC;
        --serviceBg4: #E9FAFD;
        --programColor1: #349471;
        --programColor2: #FA3E86;
        --programColor3: #3ED7FA;
        --programColor4: #49C4BC;
        --programText: #827C70;
        --ageColor: #3ED7FA;
        --daysColor: #FA3E86;
        --hoursColor: #349471;
    }

    &.colorPattern2 {
        --primary: #355394;
        --secondary: #89A0D0;
        --primaryLightShade: #89A0D040;
        --white: #FFFFFF;
        --petrol: #103741;
        --Text36: #363D3F;
        --serviceClr1: #355394;
        --serviceBg1: #89A0D040;
        --serviceClr2: #FA9541;
        --serviceBg2: #FA954140;
        --serviceClr3: #CEAE7E;
        --serviceBg3: #FFF8E8;
        --serviceClr4: #49C4BC;
        --serviceBg4: #E9FAFD;
        --programColor1: #355394;
        --programColor2: #FA9541;
        --programColor3: #CEAE7E;
        --programColor4: #49C4BC;
        --programText: #827C70;
        --ageColor: #CEAE7E;
        --daysColor: #FA9541;
        --hoursColor: #355394;

    }

    &.colorPattern3 {
        --primary: #683C8F;
        --secondary: #C5BACE;
        --primaryLightShade: #683C8F40;
        --white: #FFFFFF;
        --petrol: #103741;
        --Text36: #363D3F;
        --serviceClr1: #683C8F;
        --serviceBg1: #C5BACE40;
        --serviceClr2: #2C7848;
        --serviceBg2: #E9F3EE;
        --serviceClr3: #C4A066;
        --serviceBg3: #FFF8E8;
        --serviceClr4: #49C4BC;
        --serviceBg4: #E9FAFD;
        --programColor1: #683C8F;
        --programColor2: #2C7848;
        --programColor3: #C4A066;
        --programColor4: #49C4BC;
        --programText: #827C70;
        --ageColor: #C4A066;
        --daysColor: #2C7848;
        --hoursColor: #683C8F;

    }

    &.colorPattern4 {

        --primary: #FE5D37;
        --secondary: #FFF0EC;
        --primaryLightShade: #FE5D3740;
        --white: #FFFFFF;
        --petrol: #103741;
        --Text36: #363D3F;
        --serviceClr1: #FE5D37;
        --serviceBg1: #FFF0EC;
        --serviceClr2: #198754;
        --serviceBg2: #E9F3EE;
        --serviceClr3: #FFC106;
        --serviceBg3: #FFF8E8;
        --serviceClr4: #0ECAF0;
        --serviceBg4: #E9FAFD;
        --programColor1: #FE5D37;
        --programColor2: #198754;
        --programColor3: #FFC106;
        --programColor4: #0ECAF0;
        --programText: #827C70;
        --ageColor: #FFAE00;
        --daysColor: #198754;
        --hoursColor: #FE5D37;

    }
}

:root {
    --primary: #349471;
    --secondary: #87D0B6;
    --primaryLightShade: #87D0B640;
    --white: #FFFFFF;
    --petrol: #103741;
    --Text36: #363D3F;
    --serviceClr1: #349471;
    --serviceBg1: #87D0B640;
    --serviceClr2: #FA3E86;
    --serviceBg2: #FA3E8640;
    --serviceClr3: #3ED7FA;
    --serviceBg3: #3ED7FA40;
    --serviceClr4: #49C4BC;
    --serviceBg4: #E9FAFD;
    --programColor1: #349471;
    --programColor2: #FA3E86;
    --programColor3: #3ED7FA;
    --programColor4: #49C4BC;
    --programText: #827C70;
    --ageColor: #3ED7FA;
    --daysColor: #FA3E86;
    --hoursColor: #349471;
}