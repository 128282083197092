.TemplatesOne-program-wrp {
    .each-program-sec {
        ul {
            li {
                display: flex;
                align-items: center;
                margin-bottom: 15px;

                span.program-icon {
                    width: 40px;
                    height: 40px;
                    display: block;
                    border-radius: 100%;
                    overflow: hidden;
                    padding: 10px;
                    --iconColor: var(--white);
                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }

                p {
                    margin: 0;
                    padding-left: 15px;
                    text-align: left;
                    color: var(--programText);
                }
            }
        }
    }

    .row {
        .col-xs-12 {
            &:nth-child(4n + 1) {
                .each-program-sec {
                    ul {
                        li {
                            span {
                                background-color: var(--programColor1);
                                // svg {
                                //     circle {
                                //         fill: var(--programColor1);
                                //     }

                                //     path {
                                //         fill: var(--white);
                                //     }
                                // }
                            }
                        }
                    }
                }
            }
            &:nth-child(4n + 2) {
                .each-program-sec {
                    ul {
                        li {
                            span {
                                background-color: var(--programColor2);
                            }
                        }
                    }
                }
            }
            &:nth-child(4n + 3) {
                .each-program-sec {
                    ul {
                        li {
                            span {
                                background-color: var(--programColor3);
                            }
                        }
                    }
                }
            }
            &:nth-child(4n + 4) {
                .each-program-sec {
                    ul {
                        li {
                            span {
                                background-color: var(--programColor4);
                            }
                        }
                    }
                }
            }
        }
    }
}