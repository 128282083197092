.profile-card {
    *{
        color: #FFF4EE;
        // height: auto;
    }
    background: #211e1d;
    padding: 24px;
    border-radius: 20px;
    .btn-sec {
        ul {
            display: flex;
            justify-content: flex-end;

            li:not(:last-child) {
                margin-right: 20px;
            }
        }

        padding-bottom: 30px;
    }

    .profile-sec {
        .profile-img-sec {
            padding-bottom: 30px;
            text-align: center;
            span {
                display: block;
                width: 126px;
                height: 126px;
                margin: 0 auto;
                border-radius: 100%;
                overflow: hidden;
                border: 6px solid #FF7940;
                img{
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
            h5{
                font-weight: 700;
                margin: 0;
                margin-top: 15px;
                font-size: 18px;
            }
            p{
                margin: 0;
            }
        }
    }

    .basic-details {
        .each-detail-sec {
            display: flex;
            & > div{
                flex: 1;
            }
            p{
                margin-bottom: 0;
            }
        }
    }

}

.delete-popup{
    .MuiModal-backdrop {
        background: rgba(0, 0, 0, 0.8);
    }
    .form-group .custom-textfield{
        margin-top: 10px;
    }
    .MuiPaper-rounded.MuiDialog-paper{
        box-shadow: none;
        width: 100%;
        h2{
            font-size: 22px;
            font-family: 'Andika', sans-serif;
            color: #FF7940;
        }
        p{
            span{
                color: #0F0A01;
                i{
                    font-style: normal;
                    color: #FF7940;
                    display: block;
                }
            }

        }
    }
    button{
        color: #B4AC9C;
        border-radius: 50px;
        padding: 8px 22px;
        min-width: 150px;
        height: 48px;
        border: 1px solid #B4AC9C;
    }
    button.standard-btn{
        background-color: #FF3D00;
        color: #FFF9F5;
    }
}