.ppPlatformNavItems.menu-main-wrp {
    // .MuiPaper-root.MuiPaper-rounded {
    //     margin-left: -50px;
    // }

    .each-menuItem {
        width: auto;
        margin-bottom: 10px;
        position: relative;

    }

    .MuiPaper-root.MuiPaper-elevation {
        font-size: 13px;
    }

    .MuiMenu-list {
        background: rgba(255, 196, 68, 0.68);
        box-shadow: 0px 4px 36px 0px rgba(255, 255, 255, 0.36) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.10) inset, 0px 1px 1px 0px rgba(255, 255, 255, 0.15) inset;
        backdrop-filter: blur(7px);
        padding-left: 10px;
        padding-top: 35px;
        padding-bottom: 35px;
        a {
            border-radius: 50px 0 0px 50px;
            position: relative;

            button{
                .MuiTouchRipple-root{
                    display: none !important;
                }
            }

            span {
                color: #4E0554;
            }
        }

        a:hover {
            border-radius: 50px 0 0px 50px;
            background: #ED3384;

            &::after {
                content: '';
                width: 25px;
                height: 20px;
                display: block;
                position: absolute;
                right: 0;
                bottom: -19px;
                background: url(../../../../../assets/images/ppPlatform/navAfter.png);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: right;
            }

            &::before {
                content: '';
                width: 25px;
                height: 20px;
                display: block;
                position: absolute;
                right: 0;
                top: -19px;
                background: url(../../../../../assets/images/ppPlatform/navBefore.png);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: right;
            }

            // &::after {
            //     content: '';
            //     width: 100%;
            //     height: calc(100% + 70px);
            //     display: block;
            //     position: absolute;
            //     right: 0;
            //     top: -10px;
            //     background: url(../../../../../assets/images/ppPlatform/navBG.png);
            //     background-size: contain;
            //     background-repeat: no-repeat;
            //     background-position: right;
            // }

            span {
                color: var(--White);
            }
        }
    }
}

.menu-main-wrp.ppPlatformNavItems {
    .MuiPaper-root.MuiMenu-paper {
        width: 100%;
        max-width: 250px;
        border-radius: 20px;
    }
}