.argentinaTemplate {

    .sqr-btn {
        display: inline-block;
        padding: 10px 40px;
        margin-top: 30px;
        border-radius: 8px;
    }

    .main-banner-wrp {
        padding-bottom: 120px;

        .bannerBG {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            min-height: 100vh;

            @media only screen and (max-width: 650px) {
                & {
                    min-height: calc(100vh - 50px);
                }
            }

            span {
                display: block;
                width: 100%;
                height: 100%;

                img {
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                }

            }

            @media only screen and (min-width: 650px) {
                & {
                    .mobBG {
                        display: none;
                    }
                }
            }

            @media only screen and (max-width: 650px) {
                & {
                    .pcBG {
                        display: none;
                    }
                }
            }

            &+div {
                z-index: 9;
            }
        }

        .banner-align {
            display: flex;
            justify-content: flex-end;
            height: 100vh;
            width: 100%;
            align-items: center;

            @media only screen and (max-width: 650px) {
                & {
                    height: calc(100vh - 50px);
                    align-items: center;
                    padding-bottom: 25px;
                }
            }

            .banner-con-sec {
                max-width: 550px;

                @media only screen and (max-width: 650px) {
                    & {
                        height: calc(100vh - 50px);
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;
                    }
                }

                .banner-con-box {
                    display: flex;
                    flex-direction: column;
                    border-radius: 8px;
                    align-items: center;
                    text-align: center;

                    span {
                        display: block;
                        max-width: 250px;
                        flex: 1;
                        min-width: 250px;
                        width: 250px;
                        padding-bottom: 20px;
                    }

                    @media only screen and (min-width: 650px) {
                        & {
                            .mob-icon {
                                display: none;
                            }
                        }
                    }

                    @media only screen and (max-width: 650px) {
                        & {
                            .pc-icon {
                                display: none;
                            }

                            .mob-icon {
                                max-width: 230px;
                                width: 100%;
                                margin: 0 auto;
                            }
                        }
                    }

                    .banner-con {
                        flex: 2;
                        padding-left: 0px;

                        h1 {
                            margin: 0;
                            color: var(--White);
                            font-family: var(--Roboto);
                            font-size: 34px;
                            line-height: 1;
                            text-align: center;
                            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                            font-weight: 400;
                        }

                        p {
                            margin-top: 10px;
                            margin-bottom: 0;
                            font-family: var(--MavenPro);
                            color: #616279;
                        }

                        @media only screen and (max-width: 650px) {
                            & {
                                .pc-icon {
                                    display: none;
                                }

                                h1 {
                                    color: var(--White);
                                    font-size: 24px;
                                    text-align: center;

                                    br {
                                        display: none;
                                    }
                                }

                                p {
                                    display: none;
                                }
                            }
                        }
                    }

                    @media only screen and (max-width: 650px) {
                        & {
                            background: none;
                            flex-direction: column;
                            padding: 0;
                        }
                    }
                }

                .sqr-btn {
                    background: var(--OrangeF6);
                    color: var(--White);

                    @media only screen and (max-width: 650px) {
                        & {
                            width: 100%;
                        }
                        &:not(:last-child) {
                            margin-right: 0px;
                        }
                    }
                }
            }

        }
    }

    .basic-detail-wrp {
        .basic-detail-con {
            margin-top: -60px;

            .row {
                &>div {
                    padding-bottom: 15px;
                }

                &>div:last-child {
                    .basic-detail-box {
                        background: var(--lightBG);

                        h3,
                        h2,
                        p {
                            color: var(--Text82);
                        }

                        button {
                            background: var(--White);
                            line-height: 1;

                            svg {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }

            .basic-detail-box {
                background: var(--OrangeF6);
                padding: 25px;
                padding-top: 30px;
                border-radius: 15px;
                height: 100%;
                color: var(--White);

                h3 {
                    margin: 0;
                    font-size: 20px;
                }

                h2 {
                    margin-top: 0px;
                    margin-bottom: 0px;
                    font-size: 28px;
                }

                p {
                    margin-bottom: 0;
                    margin-top: 10px;
                    font-size: 16px;
                    white-space: pre-line;
                }

                button {
                    margin-top: 45px;
                    border: 1px solid var(--White);
                    text-align: center;
                    width: 100%;
                    color: var(--White);
                    text-transform: none;
                }

                ul {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 26px;

                    li {
                        button {
                            margin: 0;
                            padding: 10px 40px;
                            border: 1px solid var(--Text82);
                        }
                    }
                }
            }
        }
    }

    .about-section-wrp {
        .about-section-header {
            h1 {
                color: var(--Text82);
            }
        }

        .about-grid-wrp.f-wrp {
            display: flex;
            flex-wrap: wrap;
            gap: 100px;
            align-items: center;

            .about-con-sec,
            .about-img-sec {
                flex: 1;
            }

            .about-con-sec {
                text-align: left;

                h1 {
                    display: flex;
                    color: var(--Purple);
                    font-family: var(--Walter);
                    align-items: center;
                    line-height: 1;
                    justify-content: center;

                    img {
                        height: 25px;
                        object-fit: contain;
                        object-position: left;
                        padding-left: 15px;
                        width: auto;
                    }
                }

                p {
                    color: var(--OrangeF6);
                }

                span {
                    font-family: var(--Cedarville);
                    color: var(--Purple);
                    font-size: 25px;
                }
            }

            @media only screen and (max-width: 1200px) {
                & {
                    gap: 30px;
                }
            }

            @media only screen and (max-width: 991px) {
                & {
                    flex-direction: column;
                }
            }
        }
    }


    .info-wrapper-section.f-wrp {
        margin: 0 auto;
        max-width: 835px;
        float: none;

        @media only screen and (max-width: 850px) {
            & {
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        .routine-box {
            background: var(--lightBG);
            padding: 5px 35px;
            border-radius: 20px;

            ul {
                padding-top: 30px;

                li {
                    float: left;
                    width: 50%;
                    margin-bottom: 30px;

                    @media only screen and (max-width: 650px) {
                        & {
                            width: 100%;
                        }
                    }

                    .each-routine-box {
                        display: flex;
                        align-items: center;

                        .icon-sec {
                            width: 35px;
                            display: block;
                            height: 35px;

                            svg {
                                width: 35px;
                                height: 35px;
                            }
                        }

                        .content-sec {
                            padding-left: 20px;

                            h3,
                            p {
                                margin: 0;
                                color: var(--Text82);
                            }

                            p {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }

    .service-slider-sec {
        padding: 50px 0;

        .service-section-header {

            h1,
            p {
                color: var(--Text82);
            }
        }
    }

    .our-team-sec {
        padding: 50px 0;

        .our-team-header {

            h1,
            p {
                color: var(--Text82);
            }
        }

        .team-member-wrap {
            @media only screen and (max-width: 991px) {
                & {
                    padding-top: 30px;
                }
            }

            @media only screen and (max-width: 576px) {
                .row>* {
                    padding-left: 0;
                    padding-right: 0;
                }

            }

            .each-team-box {
                text-align: center;
                @media only screen and (max-width: 767px) {
                    &{
                        margin-bottom: 35px;
                    }
                }

                @media only screen and (max-width: 576px) {
                    & {
                        background: var(--lightBG);
                        padding-bottom: 25px;
                        border-radius: 10px;
                        overflow: hidden;
                    }

                }

                h3 {
                    color: var(--OrangeF6);
                    margin-bottom: 0;
                }

                p {
                    color: var(--Text82);
                    margin: 0;
                }
            }
        }
    }

    .BG-img-separator {
        padding-top: 350px;
        @media only screen and (max-width: 576px) {
            & {
                padding-top: 250px;
            }

        }
        &.bgImg_1 {
            background: url('./images/separator_img1.png');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        &.bgImg_2 {
            background: url('./images/separator_img2.png');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    .gallery-section-wrap {
        @media only screen and (max-width: 850px) {
            & {
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        .gallery-sec-header {
            text-align: left;
            max-width: 835px;
            margin: 0 auto;
            float: none;

            h1 {
                font-family: var(--Wingdings);
                color: var(--Text82);
            }

            p {
                color: var(--Text82);
            }
        }
    }

    .curriculum-sec-wrp {
        .curriculum-header {
            color: var(--Text82);
            padding-bottom: 50px;
        }
    }


    .classes-section-wrap {
        @media only screen and (max-width: 850px) {
            & {
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        .classes-sec-header {
            text-align: left;
            max-width: 835px;
            margin: 0 auto;
            float: none;

            h1 {
                font-family: var(--Wingdings);
                color: var(--Text82);
            }

            p {
                color: var(--Text82);
            }
        }
    }

    .testimonial-section-wrp {
        @media only screen and (max-width: 850px) {
            & {
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        .testimonial-sec-header {
            text-align: left;
            max-width: 835px;
            margin: 0 auto;
            float: none;

            h1 {
                font-family: var(--Wingdings);
                color: var(--Text82);
            }

            p {
                color: var(--Text82);
            }
        }
    }

    .pay-option-wrp {
        @media only screen and (max-width: 850px) {
            & {
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        .payment-section-wrp {
            text-align: left;
            max-width: 835px;
            margin: 0 auto;
            float: none;

            .payment-content-sec {
                * {
                    color: var(--Text82);
                }

                .sqr-btn {
                    background: var(--yellowD2);
                    color: var(--White);
                }
            }
        }


    }





























    .contact-blk-wrp {

        .contact-sec-blk {
            display: flex;
            max-width: 1920px;

            @media only screen and (max-width: 767px) {
                & {
                    flex-direction: column;
                }
            }

            .contact-con {
                flex: 2;
                background: var(--Purple);

                &>.f-wrp {
                    max-width: 600px;
                    padding: 30px 0 60px;
                    margin: 0 auto;
                    float: none;
                    text-align: center;
                    color: var(--White);
                }

                h1 {
                    margin-bottom: 10px;
                }

                h4 {
                    margin-top: 0;
                }

                h1,
                h4 {
                    font-family: var(--Walter);
                }

                p {
                    max-width: 550px;
                    margin: 0 auto;
                    margin-bottom: 25px;
                    padding: 0 15px;
                }

                ul {
                    display: grid;
                    grid-template-columns: repeat(auto-fill, 200px);
                    justify-content: center;

                    @media only screen and (max-width: 1200px) {
                        & {
                            grid-template-columns: repeat(auto-fill, 300px);
                        }
                    }

                    li {
                        padding-left: 15px;
                        padding-right: 15px;

                        .each-contact-block {
                            margin-bottom: 15px;

                            .contact-icon {
                                display: block;
                                width: 75px;
                                margin: 0 auto;
                            }

                            p {
                                margin-top: 10px;
                                margin-bottom: 0;
                                padding: 0;
                            }
                        }
                    }
                }

                .sqr-btn {
                    background: var(--Orange);
                    color: var(--White);
                }
            }

            .contact-img {
                flex: 1;

                span {
                    position: relative;
                    width: 100%;
                    display: block;
                    height: 100%;

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: bottom;

                        @media only screen and (max-width: 767px) {
                            & {
                                position: relative;
                            }
                        }
                    }
                }
            }
        }
    }

    .program-sec-wrp {
        .program-sec-header {
            text-align: center;

            h1 {
                font-family: var(--Walter);
                color: var(--Purple);
                margin-bottom: 15px;
                margin-top: 0;
            }

            p {
                margin: 0;
                color: var(--PurpleText);
            }
        }

        .program-con-wrp {
            background: var(--Purple);

            .program-grid {
                display: grid;
                grid-template-columns: repeat(auto-fill, 320px);
                justify-content: center;
                padding-top: 50px;
                padding-bottom: 10px;

                @media only screen and (max-width: 1200px) {
                    & {
                        grid-template-columns: repeat(auto-fill, 300px);
                    }
                }

                .each-program-sec {
                    margin-bottom: 25px;
                    text-align: center;
                    color: var(--White);

                    .program-icon {
                        display: block;
                        width: 75px;
                        margin: 0 auto;
                    }

                    h3 {
                        font-family: var(--Walter);
                    }

                    p {
                        margin-top: 10px;
                        margin-bottom: 0;
                        padding: 0;

                        i {
                            font-size: 8px;
                        }
                    }
                }
            }
        }
    }

    .learning-sec-wrp {
        .learning-sec-header {
            text-align: center;
            margin-bottom: 40px;

            h1 {
                color: var(--Purple);
                font-family: var(--Walter);
            }

            p {
                color: var(--PurpleText);
            }
        }

        .learning-con-wrp {
            .learning-grid {
                .row {
                    &>div {
                        margin-bottom: 40px;
                    }
                }

                @media only screen and (max-width: 991px) {
                    & {
                        .hide {
                            display: none;
                        }
                    }
                }
            }

            .each-learning-sec {
                height: 100%;

                .learning-img {
                    height: 100%;

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                        border: 10px solid var(--Purple90);
                    }

                }

                .learing-header {
                    padding: 15px;
                    background: #eee;
                    height: 75px;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    z-index: 1;

                    h3 {
                        margin: 0;
                        font-size: 22px;
                        line-height: 1.2;
                        color: #fff;
                        white-space: pre-wrap;
                    }
                }

                &.yellow {
                    .learing-header {
                        background: var(--Yellow);
                    }
                }

                &.Turquoise {
                    .learing-header {
                        background: var(--Turquoise);
                    }
                }

                &.Green {
                    .learing-header {
                        background: var(--Green);
                    }
                }

                &.purple {
                    .learing-header {
                        background: var(--Purple);
                    }
                }

                &.Teal {
                    .learing-header {
                        background: var(--Teal);
                    }
                }

                &.Orange {
                    .learing-header {
                        background: var(--Orange);
                    }
                }

                &.Violet {
                    .learing-header {
                        background: var(--Violet);
                    }
                }

                &.red {
                    .learing-header {
                        background: var(--Red);
                    }
                }

                .learing-content {
                    padding: 15px;
                    background: var(--Purple90);
                    height: calc(100% - 75px);
                    min-height: 275px;

                    .learning-icon {
                        width: 75px;
                        display: block;
                        float: right;
                        margin-top: -55px;
                        z-index: 9;
                        position: relative;
                    }

                    * {
                        color: var(--White);
                    }

                    ul {
                        li {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }


    .activities-section-wrp {
        .activities-grid-wrp.f-wrp {
            display: flex;
            flex-wrap: wrap;
            gap: 100px;
            align-items: center;

            .activities-con-sec,
            .activities-img-sec {
                flex: 1;
            }

            .activities-con-sec {
                text-align: center;

                h1 {
                    color: var(--Purple);
                    font-family: var(--Walter);
                    text-align: left;
                }

                p {
                    color: var(--PurpleText);
                    text-align: left;
                }

                span {
                    font-family: var(--Cedarville);
                    color: var(--Purple);
                    font-size: 25px;
                }

                ul {
                    column-count: 2;

                    @media only screen and (max-width: 600px) {
                        & {
                            column-count: 1;
                            padding-left: 10%;
                        }
                    }

                    li {
                        display: flex;
                        align-items: center;

                        p {
                            margin: 0;
                            padding-left: 15px;
                            text-align: left !important;

                            @media only screen and (max-width: 600px) {
                                & {
                                    text-align: left !important;
                                }
                            }
                        }
                    }
                }
            }

            @media only screen and (max-width: 1200px) {
                & {
                    gap: 30px;
                }
            }

            @media only screen and (max-width: 991px) {
                & {
                    flex-direction: column-reverse;

                    h1 {
                        text-align: center !important;
                    }

                    p {
                        text-align: center !important;
                    }
                }
            }
        }
    }

    .gallery-section-wrp {
        .gallery-sec-header {
            text-align: center;
            background: var(--Orange);

            h1 {
                font-family: var(--Walter);
                color: var(--White);
            }
        }
    }

    .theme-band {
        background: var(--Purple);
    }

    .about-founder-wrp {
        .founder-sec-header {
            text-align: center;
            background: var(--Purple);
            color: var(--White);

            h1 {
                font-family: var(--Walter);
            }

            @media only screen and (max-width: 767px) {
                & {
                    padding-bottom: 200px;

                    .founder-img,
                    .founder-con-blk {
                        display: none;
                    }
                }
            }
        }

        .founder-con-wrp {
            display: flex;

            @media only screen and (max-width: 767px) {
                & {
                    flex-direction: column;
                }
            }

            .founder-img {
                flex: 1;
                padding-right: 140px;

                @media only screen and (min-width: 1200px) {
                    & {
                        padding-right: 95px;
                    }
                }

                @media only screen and (min-width: 992px) {
                    & {
                        padding-right: 50px;
                    }
                }

                @media only screen and (min-width: 576px) {
                    & {
                        padding-right: 25px;
                    }
                }

                @media only screen and (max-width: 767px) {
                    & {
                        padding-right: 0px;
                        margin-top: -165px !important;
                    }
                }

                h2 {
                    font-family: var(--Walter);
                    color: var(--Purple);
                    text-align: center;
                    margin-bottom: 0;
                }

                p {
                    text-align: center;
                    margin-top: 0;
                }
            }

            .founder-con-blk {
                flex: 2;
            }
        }

        .founder-con-sec {
            .founder-con-wrp {
                p {
                    color: var(--PurpleText);

                    @media only screen and (max-width: 767px) {
                        & {
                            text-align: center;
                        }
                    }
                }

                .mob-para {
                    display: none;

                    @media only screen and (max-width: 767px) {
                        & {
                            display: block;
                        }
                    }
                }
            }
        }

    }



    .scrolling-sec {
        background-color: var(--Purple);
        padding: 15px;

        * {
            color: var(--White);
            text-transform: uppercase;
            letter-spacing: 1.68px;
        }
    }


    @media only screen and (max-width: 650px) {
        .gap.f-wrp {
            &+.gap {
                display: none;
            }
        }
    }

}