
.main-head-wrp.EvolveAppHeader{
    background-color: transparent !important;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    .burger-prof-sec{
        display: flex;
        justify-content: flex-end;
        height: auto; 
        align-items: center;
        padding-top: 20px;
        .burger-menu-sec{
            padding-right: 5px;
        }
        .burger-icon{
            width: 70px;
            height: 70px;
            margin: 0;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
        .language-select-slide{
            margin-right: 50px;
            .pln-btn{
                color: var(--White);
                font-weight: 700;
                background: transparent;
                border: none;
                cursor: pointer;
                padding: 6px 0px;
                margin: 0px 5px;
                transition: 0.5s all;
                &::after{
                    content: '';
                    width: 100%;
                    height: 2px;
                    background-color: var(--White);
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    opacity: 0;
                    transition: 0.5s all;
                }
                &.active{
                    position: relative;
                    transition: 0.5s all;
                    &::after{
                        opacity: 1;
                        transition: 0.5s all;
                    }
                }
            }
        }
    }
}