@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;700&family=Cedarville+Cursive&family=Montserrat:wght@400;500;700&family=Roboto:wght@400;500;700&family=Shadows+Into+Light&family=Walter+Turncoat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;700&display=swap');

:root {
  --Baloo: 'Baloo 2', cursive;
  --Roboto: 'Roboto', sans-serif;
  --Walter: 'Walter Turncoat', cursive;
  --Montserrat: 'Montserrat', sans-serif;
  --Cedarville: 'Cedarville Cursive', cursive;
  --Shadows: 'Shadows Into Light', cursive;
  --MavenPro: 'Maven Pro', sans-serif;
  --RedHatDisplay: 'Red Hat Display', sans-serif;
  --Andika: 'Andika', sans-serif;


  // color
  --Purple: #57509E;
  --Purple90: #908AC7;
  --PurpleC1: #C168FE;
  --PurpleText: #868799;
  --Orange: #FE5D37;
  --White: #FFFFFF;
  --Yellow: #FFC106;
  --Green: #5CB60B;
  --GreenLight: rgba(102, 181, 25, 0.25);
  --Amaranth: #E71F72;
  --lytColor1: rgba(230, 29, 112, 0.25);
  --Cobalt: #0148B0;
  --lytColor2: rgba(1, 74, 178, 0.25);
  --Monza: #D20606;
  --lytColor3: rgba(210, 6, 6, 0.25);
  --lytColor4: rgba(231, 31, 114, 0.25);
  --lytColor4Hex: #f9c7dc;
  --Green19: #198754;
  --Green1C: #66B31C;
  --Glaucous: #001D6F;
  --Violet: #57509E;
  --Teal: #61A5A6;
  --Turquoise: #0ECAF0;
  --Red: #C3242A;
  --Text36: #363D3F;
  --Text82: #827C70;
  --Petrol: #103741;
  --Orange300: #FFF0EC;
  --Green300: #E9F3EE;
  --Yellow300: #FFF8E8;
  --Turquoise300: #E9FAFD;
  --Teal300: #E5E4F1;
  --Yellow00: #FFAE00;
  --RedED: #ED2228;
  --Blue: #004AAD;
  --Text00: #00214E;
  --GreenB4: #B4BB4B;
  --OrangeF6: #F66A05;
  --lightBG: #F4EDE5;
  --yellowD2: #D2DB59;
  --yellow5: #FFF5CF;
  --lightBlue: #01B8C1;
  --text3F: #3F3424;
  --parkTextOne: #85236C;
  --parkTextTwo: #591F71;
  --parkBG: linear-gradient(96deg, #E2468A 30.42%, #592F91 80.61%);
  --parkBGOne: #FBF1FF;
  --Black: #000;
  --parkingTextThree: #A31995;
  --parkTextFour: #4E0554;
  --parkTextFive: #2E0032;
  --parkColorSix: #A202E4;
  --parkLine: linear-gradient(289deg, #A100E4 -7.56%, rgba(134, 7, 137, 0.87) 20.54%, rgba(114, 0, 143, 0.89) 48.64%, #A202E4 88.49%);
  --scrollBG: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #4E0554;
  --redD3: #D33A4A;
  --ppColor1: #FFC444;
  --ppColor2: #FFEA6E;
  --ppColor3: #FFB619;
  --ppColor4: #ED3384;
  --ppColorBG: linear-gradient(93deg, #A31995 21.6%, #A202E4 93.71%);
  --ppShadow: rgba(78, 5, 84, 0.50);
  --textBG: linear-gradient(27deg, #ED3384 9.32%, #A31995 86.94%);
  --ppColor5: #8D1797;
  --ppColor6: #F36F56;
  --friendlyBG: #4A8530;
  --friendlyBG2: #00712B;
  --friendlyTextClr: #ADC30A;
  --friendlyLightBG: #F1ECD0;
  --friendlyLightBG1: #FCE9D8;
  --friendlyLightBG2: #FADCE9;
  --friendlyLightBG3: #EFE9C7;
  --friendlyOrange: #FE5D37;
  --friendlyPink: #FA3E86;
  --friendlyText13: #003313;
  --friendlyText70: #827C70;
  --friendlyButtonBg: linear-gradient(180deg, #B8CA06 0%, #4A8530 131.87%);
  --friendlyMediBg: #9C5DA3;
  --friendlyPlayBg: #E54E3D;
  --friendlyCareBg: #E53875;
  --friendlyMediBgLyt: #9C5DA340;
  --friendlyPlayBgLyt: #E54E3D40;
  --friendlyCareBgLyt: #E5387540;
  --friendlyProgramClr1: #B8CA06;
  --friendlyProgramClr2: #FA3E86;
  --friendlyProgramClr3: #3ED7FA;
  --friendlyProgramClr4: #49C4BC;

} 