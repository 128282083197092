.payment-status {
    font-weight: 500;
    text-transform: capitalize;
}
.status-pending {
    color: #ffba0a;
}
.status-completed {
    color: #1faf38;
}
.status-cancelled {
    color: rgb(113, 124, 241);
}
.status-failed {
    color: #bf0000;
}
.paymentTable{
    padding: 24px;
    @media only screen and (max-width: 600px) {
        &{
           padding-left: 15px;
           padding-right: 15px;
        }
    }
}