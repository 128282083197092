.parent-bulletin {
    .each-bulletin-blk.f-wrp {
        background-color: #0F0A01;
        padding: 24px;
        border-radius: 24px;

        .preview-img-sec {
            height: 200px;
            border-radius: 16px;
            overflow: hidden;

            img {
                object-fit: cover;
                object-position: center;
                height: 100%;
            }
        }

        .bulletin-con-sec {
            padding-top: 24px;

            .basic-details {
                display: flex;

                p,
                span {
                    width: max-content;
                    font-size: 12px;
                    margin: 0;
                    margin-bottom: 5px;
                    color: #98948A;

                    &:last-child {
                        margin-left: 15px;
                    }
                }

                &+.f-wrp {
                    height: 135px;
                    overflow: hidden;
                }
            }

            h1,
            h2 {
                margin: 0;
                margin-bottom: 5px;
                display: block;
                color: #ffffff;
            }

            h1 {
                font-size: 16px;
            }

            h2 {
                font-size: 14px;
            }

            p,
            span[data-text="true"] {
                color: #ffffff;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;               
            }

            [data-text="true"] {
                color: #ffffff;
            }

            ul {
                li {
                    font-size: 12px;
                }
            }
        }

        .pln-btn {
            color: #FF3D00;
            font-size: 13px;
            font-weight: normal;
            margin-top: 15px;
            svg {
                width: 17px;
                height: 17px;
            }
        }
    }

}

.bulletin-back-btn {
    button {
        color: #e4e4e4;
    }

    svg {
        path {
            fill: #e4e4e4;
        }
    }
}

.parent-bulletin-view {
    background: #0F0A01;
    padding: 24px;
    border-radius: 24px;
    font-size: 14px;
    color: #ffffff;

    .MuiGrid-container {
        .no-img + .MuiGrid-grid-xs-12 {
            max-width: 100%;
            -webkit-flex-basis: 100%;
            -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
        }
    }

    .preview-img-sec {
        border-radius: 16px;
        height: 100%;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .bulletin-con-sec {
        .basic-details {
            display: flex;

            p {
                width: max-content;
                font-size: 14px;
                margin: 0;
                margin-bottom: 5px;
                color: #fff;

                &:last-child {
                    margin-left: 15px;
                }
            }

            p,
            span[data-text="true"],
            span {
                width: max-content;
                margin: 0;
                margin-bottom: 5px;
                color: #fff;

                &:last-child {
                    margin-left: 15px;
                }
            }

            [data-text="true"] {
                color: #ffffff;
            }

            ul {
                li {
                    font-size: 12px;
                }
            }
        }

        h1,
        h2 {
            margin: 0;
            margin-bottom: 5px;
            display: block;
            color: #fff;
        }

        p {
            color: #fff;
            font-size: 13px;
        }
    }
}