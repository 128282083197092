.employeeTab-wrp{
    .red-btn{
        background-color: #FF3D00;
        color: #fff !important;
        margin-bottom: 0px;
        text-transform: none;
        @media only screen and (max-width: 991px) {
            margin-bottom: 20px;
        }
        @media only screen and (max-width: 600px) {
            &{
                position: fixed;
                z-index: 1;
                right: 15px;
                bottom: 20px;
                margin: 0;
            }
        }
    }
    @media only screen and (max-width: 600px) {
        .tab-sec-wrp{
            .tab-body{
                .MuiTabPanel-root{
                    padding: 0;
                }
                .profile-wrapper{
                    padding: 24px 10px 10px;
                }
            }
        }
    }
}
.employeeTab-section{
    padding: 0;
    
    .bulletin-con-wrp{
        background: #fff;
        border-radius: 24px;
        padding: 24px;
        @media only screen and (max-width: 600px) {
            &{
                margin-bottom: 70px;
            }
        }
    }
    @media only screen and (max-width: 600px) {
        &{
            margin-bottom: 70px;
        }
    }
}
.add-employee-layout{
    .app-text-input-container {
        .form-group{
            .custom-textfield{
                .MuiInputBase-root{
                    input:-webkit-autofill,
                    input:-webkit-autofill:hover,
                    input:-webkit-autofill:focus,
                    textarea:-webkit-autofill,
                    textarea:-webkit-autofill:hover,
                    textarea:-webkit-autofill:focus,
                    select:-webkit-autofill,
                    select:-webkit-autofill:hover,
                    select:-webkit-autofill:focus {
                        -webkit-text-fill-color: #6C6C6C !important; 
                        caret-color: #6C6C6C !important;
                        -webkit-box-shadow: 0 0 0 300px #fff inset !important;
                    }
                }
            }
        }
    }
}