.argentina-testimonial-wrp{
    margin: 15px auto;
    max-width: 935px;
    display: block;
    
    .each-testimonial-items{
        display: flex;
        .testimonial-icon{
            display: block;
            width: 50px;
            height: 50px;
            border-radius: 100%;
            overflow: hidden;
        }
        .testimonial-content{
            width: calc(100% - 50px);
            padding-left: 20px;
        }
        h2{
            color: var(--GreenB4);
            font-size: 16px;
            
        }
        p{
            margin-top: 0;
        }
    }
    .swiper.swiper-initialized{
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 50px;
    }
    .swiper-pagination-bullet-active{
        background: var(--Orange);
        width: var(--swiper-pagination-bullet-width,var(--swiper-pagination-bullet-size,12px));
        border-radius: 15px;
    }
    .swiper-button-prev::after,
    .swiper-button-next::after {
        content: '';
        width: 25px;
        height: 50px;
        display: block;
        object-fit: contain;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0;
        transition: 0.5s all;
    }

    &:hover {
        .swiper-button-prev::after,
        .swiper-button-next::after {
            opacity: 1;
            transition: 0.5s all;
        }
    }

    .swiper-button-prev::after {
        background-image: url('../images/icons/slideLeft.svg');
    }

    .swiper-button-next::after {
        background-image: url('../images/icons/slideRight.svg');
    }
    
}