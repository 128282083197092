.FriendFamily-Services-wrp {
    display: flex;
    width: 100%;
    max-width: 990px;
    margin: 0 auto;    
    
    .each-service-sec {
        display: block;
        text-align: center;
        max-width: 225px;
        width: 225px;
        padding-bottom: 35px;
        margin: 0 auto;
        float: none;

        .service-icon {
            width: 75px;
            display: block;
            margin: 0 auto;

            svg {
                width: 75px;
                height: 75px;
            }
        }

        .service-con-sec.f-wrp {
            width: 225px;
            height: 225px;
            background: #FFF0EC;
            border-radius: 100%;
            padding: 30px;

            h2 {
                margin-bottom: 0;
                font-family: var(--Baloo);
                font-weight: 700;
                text-transform: capitalize;
                font-size: 22px;
            }

            p {
                margin-bottom: 0;
                font-size: 14px;
            }
        }

        &.medical {
            .service-icon {
                svg {
                    circle {
                        fill: var(--friendlyMediBg);
                    }
                }
            }

            .service-con-sec {
                background: var(--friendlyMediBgLyt);

                h2 {
                    color: var(--friendlyMediBg);
                }

                p {
                    color: var(--Text36);
                }
            }
        }

        &.playground {
            .service-icon {
                svg {
                    circle {
                        fill: var(--friendlyPlayBg);
                    }
                }
            }

            .service-con-sec {
                background: var(--friendlyPlayBgLyt);

                h2 {
                    color: var(--friendlyPlayBg);
                }
    
                p {
                    color: var(--Text36);
                }
            } 
        }

        &.subsidized {
            .service-icon {
                svg {
                    circle {
                        fill: var(--friendlyCareBg);
                    }
                }
            }

            .service-con-sec {
                background: var(--friendlyCareBgLyt);

                h2 {
                    color: var(--friendlyCareBg);
                }
    
                p {
                    color: var(--Text36);
                }
            }
        }
    }

    .swiper-button-prev::after,
    .swiper-button-next::after {
        content: '';
        width: 25px;
        height: 50px;
        display: block;
        object-fit: contain;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0;
        transition: 0.5s all;
    }

    &:hover {

        .swiper-button-prev::after,
        .swiper-button-next::after {
            opacity: 1;
            transition: 0.5s all;
        }
    }

    .swiper-button-prev::after {
        background-image: url('../images/icons/slideLeft.svg');
    }

    .swiper-button-next::after {
        background-image: url('../images/icons/slideRight.svg');
    }
}