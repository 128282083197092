.parents-main-wrp {
    padding: 0;

    .table-sec-wrp {
        padding: 24px;
        background-color: #fff;
        border-radius: 24px;
        @media only screen and (max-width: 600px) {
            &.f-wrp{
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        .MuiDataGrid-root {
            border-color: transparent;
        }

    }

    .child-list-wrp {
        margin-bottom: 24px;
        @media only screen and (max-width: 600px) {
            &{
                margin-top: 0 !important;
            }
        }
        .panel-main-header {
            header {
                .MuiToolbar-root.MuiToolbar-regular {
                    padding-bottom: 0;
                    @media only screen and (max-width: 600px) {
                        &{
                            padding-bottom: 5px;
                            padding-top: 15px;
                        }
                    }
                }
            }

            &+span {
                color: #0F0A01;
                font-family: 'Andika', sans-serif;
            }
        }

        button.MuiButtonBase-root[aria-label='Go Back'] {
            font-size: 15px;
            padding: 0;
            margin-bottom: 20px;
            color: #0F0A01;

            svg {
                width: 16px;
                height: 12px;
            }
        }
    }
}

.panel-table-sec {
    .app-grid-tool-bar {
        justify-content: space-between;
    }

    .MuiDataGrid-root.MuiDataGrid-autoHeight {
        border-color: transparent;
    }

    [data-field="guardian"] {
        .MuiDataGrid-cellContent {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal
        }
    }
}