.rafaelaTemplates {
    .gallery-sec-wrp {
        margin: 0px 0;

        .swiper-button-prev::after,
        .swiper-button-next::after {
            content: '';
            width: 25px;
            height: 50px;
            display: block;
            object-fit: contain;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            opacity: 0;
            transition: 0.5s all;
        }

        &:hover {

            .swiper-button-prev::after,
            .swiper-button-next::after {
                opacity: 1;
                transition: 0.5s all;
            }
        }

        .swiper-button-prev::after {
            background-image: url('../images/icons/slideLeft.svg');
        }

        .swiper-button-next::after {
            background-image: url('../images/icons/slideRight.svg');
        }
    }
}