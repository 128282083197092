.burger-menu-sec.evolve {
    .menu-main-wrp {
        .each-menuItem {
            width: auto;
        }

        .MuiPaper-root.MuiPaper-elevation {
            font-size: 13px;
        }

        .MuiMenu-list {
            background: rgba(145, 36, 152, 0.50);
            box-shadow: 0px 4px 36px 0px rgba(255, 255, 255, 0.36) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.10) inset, 0px 1px 1px 0px rgba(255, 255, 255, 0.15) inset;
            backdrop-filter: blur(7px);
        }
    }
}