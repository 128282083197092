.app-text-input-container {
    .form-group {
        .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
            padding-right: 0;

            .MuiInputAdornment-root {
                position: absolute;
                right: 15px;
                padding: 0;

                button.MuiButtonBase-root {
                    &:hover {
                        background: transparent;
                    }

                    svg {
                        width: 1.2em;
                    }
                }
            }

            input {
                padding-right: 60px;
            }
        }

        .MuiFormHelperText-root {
            color: #5D5848;
            font-style: normal;
            font-weight: 300;
            font-family: 'Roboto', sans-serif;
        }
    }

    .MuiFormControl-root {
        width: 100%;
        position: relative;

        .MuiInputLabel-root {
            padding-left: 8px;
            padding-right: 8px;
            -webkit-transform: translate(14px, 13px) scale(1);
            -moz-transform: translate(14px, 13px) scale(1);
            -ms-transform: translate(14px, 13px) scale(1);
            transform: translate(14px, 13px) scale(1);
        }

        .MuiInputLabel-shrink {
            top: 0;
            transform: translate(12px, -8px) scale(0.75);
            color: #5D5848 !important;
            background: transparent;
            padding-left: 8px;
            padding-right: 8px;
        }

        .MuiInputBase-formControl {
            input {
                height: 35px;
                border-radius: 5px;
                width: 100%;
                padding: 5px 15px;
                font-size: 14px;
                line-height: 35px;
                color: #6C6C6C;
                font-family: 'Roboto', sans-serif;
                background: #ffffff;
            }

            input:-internal-autofill-selected {
                background-color: #fff !important;
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            textarea:-webkit-autofill,
            textarea:-webkit-autofill:hover,
            textarea:-webkit-autofill:focus,
            select:-webkit-autofill,
            select:-webkit-autofill:hover,
            select:-webkit-autofill:focus {
                -webkit-text-fill-color: #fff !important;
                caret-color: #fff !important;

                .light-bg &{
                    -webkit-text-fill-color: #6c6c6c !important;
                    caret-color: #6c6c6c !important;
                    -webkit-box-shadow: 0 0 0 300px #fff inset !important;
                }


            }

            .MuiOutlinedInput-notchedOutline {
                span {
                    padding-right: 0px;
                }
            }
        }

        .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
            color: #ffffff;
        }

        .MuiFormHelperText-root {
            position: absolute;
            right: 0px;
            bottom: -16px;
            font-size: 10px;
            margin: 0;
            color: #FF7940;
            font-weight: 400;
        }
    }
}

.standard-form>:not(style)+:not(style) {
    margin-top: 24px;
}

.app-text-input-container.no-padding-btm {
    .form-group {
        padding-bottom: 0 !important;

        .MuiInputBase-root {
            padding-right: 0 !important;

            .MuiInputAdornment-root {
                position: absolute !important;
                right: 15px !important;
                padding: 0 !important;
            }
        }
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.custom-checkbox {
    label {
        width: max-content;
        height: 24px;

        .MuiButtonBase-root.MuiCheckbox-root {
            color: #FFAE00;
        }

        .MuiFormControlLabel-label {
            font-size: 12px;
            color: #050E10;
            font-weight: 400;

            a {
                color: #FFAE00;
                text-decoration: underline;
            }
        }
    }
}

.standard-btn {
    transform: scale(1);
    transition: 0.5s all;
}

.standard-btn:focus {
    transform: scale(0.8);
    transition: 0.5s all
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: inherit;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}