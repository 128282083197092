
.main-head-wrp.FacilityAppHeader{
    background-color: var(--parkBGOne);
    // position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    .burger-prof-sec{
        display: flex;
        justify-content: flex-start;
        height: 60px;   
        align-items: center;
        padding-top: 10px;
        position: relative;
        .logo-wrp{
            span{
                // display: flex;
                height: 100%;
                // gap: 8px;
                // align-items: center;
                position: absolute;
                top: 10px;
                left: 0;
                width: 5vw;
                display: block;
                img{
                    object-fit: contain;
                }
                .logo{
                    width: 100%;
                }
                .slogan{
                    width: 65px;
                }
            }
        }
    }
   
}
.evolve-home{
    .menu-main-wrp {
        .each-menuItem {
            width: auto;
        }

        .MuiPaper-root.MuiPaper-elevation {
            font-size: 13px;
        }

        .MuiMenu-list {
            background: rgba(145, 36, 152, 0.50);
            box-shadow: 0px 4px 36px 0px rgba(255, 255, 255, 0.36) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.10) inset, 0px 1px 1px 0px rgba(255, 255, 255, 0.15) inset;
            backdrop-filter: blur(7px);
            border-radius: 12px;
            span{
                color: #fff;
            }
        }
    }
}