.home-main-wrp {
    background-color: #0f0a01;

    .animated-banner-wrp.f-wrp {
        display: none;

        img {
            display: none;
        }
    }

    .abt-con-wrp {
        background-image: url('../../assets/images/abtBG_main.png');
        background-position: right top;
        background-size: 100%;
        background-repeat: no-repeat;
        padding-top: 75px;
        background-color: #8ae109;

        @media screen and (max-width: 767px) {
            & {
                background-size: auto 68%;
            }
        }

        &::after {
            content: '';
            background-image: url('../../assets/images/abtBG_bottmSub.png');
            background-position: right top;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            position: absolute;
            height: 65px;
            width: 100%;
            left: 0;
            bottom: -64px;
            z-index: 99;

            @media screen and (max-width: 767px) {
                & {
                    background-size: 100% 40%;
                }
            }
        }

        .line-sec {
            display: block;
            width: 100%;
            position: absolute;
            float: left;
            height: 100%;
            overflow: hidden;

            .abt-sec-line_1,
            .abt-sec-line_2 {
                position: absolute;
                left: -25px;
                top: 50%;
                width: calc(100% + 50px);

                @media screen and (max-width: 767px) {
                    & {
                        width: calc(275% + 50px);
                    }
                }
            }

            .abt-sec-line_1 {
                margin-top: -250px;
                transform: rotate(-6.161deg);

                @media screen and (max-width: 767px) {
                    & {
                        margin-top: -130px;
                    }
                }
            }

            .abt-sec-line_2 {
                margin-top: -25px;
                transform: rotate(8.235deg);

                @media screen and (max-width: 767px) {
                    & {
                        margin-top: 50px;
                    }
                }
            }
        }

        .abt-img-sec {
            position: relative;
            width: 100%;
            display: table;

            @media screen and (max-width: 767px) {
                & {
                    display: none;
                }
            }

            img {
                position: absolute;
                width: 200px;
                top: -55px;
                display: block;
                z-index: 9999999;
                max-width: 300px;
                right: 0;
            }
        }

        .abt-header.f-wrp {
            text-align: center;
            max-width: 660px;
            margin: 0 auto;
            float: none;
            display: table;
            padding-bottom: 50px;

            h2 {
                color: #5D5848;
                font-size: 36px;
                font-family: 'Andika', sans-serif;
                font-style: normal;
                font-weight: 700;
                line-height: 124.633%;
            }

            p {
                color: #5D5848;
                text-align: center;
                font-size: 16px;
                font-family: Roboto;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        .content-sec-wrp {
            .row {
                justify-content: center;

                @media screen and (max-width: 767px) {
                    & {
                        display: none;
                    }
                }
            }

            @media screen and (min-width: 767px) {
                & .content-sec-mob {
                    display: none;
                }
            }

            @media screen and (max-width: 767px) {
                & .content-sec-mob {

                    .swiper-button-next:after,
                    .swiper-button-prev:after {
                        color: #6eb307;
                        font-size: 20px;
                    }
                }
            }

            .each-abt-sec {
                background: rgba(255, 243, 229, 0.75);
                border-radius: 100%;
                text-align: center;
                width: 235px;
                height: 235px;
                padding: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 0 auto;
                float: none;
                margin-bottom: 65px;

                @media screen and (max-width: 1200px) {
                    & {
                        width: 210px;
                        height: 210px;
                    }
                }

                * {
                    color: #fff;
                }

                &.box1 {
                    background: #D7252A;
                }

                &.box2 {
                    background: #F26335;
                }

                &.box3 {
                    background: #F4AA28;
                }

                &.box4 {
                    background: #30B44B;
                }

                &.box5 {
                    background: #38A1A5;
                }

                &.box6 {
                    background: #2C2F76;
                }

                &.box7 {
                    background: #42409A;
                }

                h3 {
                    font-family: 'Andika', sans-serif;
                    font-style: normal;
                    font-weight: 700;
                    margin-top: 5px;
                    margin-bottom: 5px;
                }

                .abt-icon {
                    width: 55px;
                    display: block;
                    height: 55px;
                    margin: 0 auto;

                    @media screen and (max-width: 1200px) {
                        & {
                            width: 40px;
                            height: 40px;
                        }
                    }
                }

                p {
                    display: block;
                    margin: 0;
                    font-size: 14px;

                    @media screen and (max-width: 1200px) {
                        & {
                            font-size: 12px;
                            width: 90%;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }

    .team-con-wrp {
        padding-bottom: 85px;
        background: #0f0a01;

        @media screen and (max-width: 991px) {
            & {
                padding-bottom: 0px;
            }
        }

        @media screen and (max-width: 767px) {
            .team-img-sec {
                img {
                    min-height: 250px;
                    object-fit: cover;
                }
            }
        }

        .team-con-sec {
            background-image: url('../../assets/images/teamTailBG.png');
            background-position: right top;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            position: absolute;
            left: 0;
            bottom: 0px;

            @media screen and (max-width: 991px) {
                & {
                    background-image: url('../../assets/images/teamTailBGMob.png');
                    position: relative;
                    left: 0;
                    bottom: 15px;
                }
            }

            span {
                width: 85%;
                display: block;
                float: right;
                padding: 30px 0px;

                @media screen and (max-width: 991px) {
                    & {
                        width: 100%;
                    }
                }
            }

            h2 {
                text-align: right;
                font-size: 46px;
                font-family: Roboto;
                font-style: normal;
                font-weight: 600;
                color: #FFF9F5;
                margin: 0;
                line-height: 1;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                svg {
                    width: 45px;
                    height: 45px;
                    margin-left: 10px;
                }

                @media screen and (max-width: 991px) {
                    & {
                        flex-direction: column;
                        text-align: center;

                        svg {
                            margin-left: 0;
                            margin-top: 10px;
                        }
                    }
                }

                @media screen and (max-width: 991px) {
                    & {
                        font-size: 32px;
                    }
                }
            }
        }

        .team-lft-objt {
            position: absolute;
            left: 0;
            bottom: 15px;
            width: 30%;
            height: 100%;
            display: flex;
            align-items: flex-end;
            z-index: 999;

            .each-objt {
                z-index: 9;
                height: 100%;
                width: 100%;

                // &.objtBG{
                //     position: absolute;
                //     left: 0;
                //     bottom: 15px;
                //     span{
                //         height: 100%;
                //         display: block;
                //         img{
                //             display: none;
                //             height: 100%;
                //             object-fit: contain;
                //             object-position: bottom;
                //         }
                //     }
                // }
                &.parentBG {
                    width: 100%;
                    position: relative;
                    background: url('../../assets/images/Union.png');
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: bottom left;
                    top: 25px;

                    span {
                        display: block;
                        width: 100%;
                        height: 75%;

                        img {
                            height: 100%;
                            object-fit: contain;
                            width: 100%;
                        }
                    }
                }
            }

            @media screen and (max-width: 991px) {
                & {
                    display: none;
                }
            }
        }
    }

    .learn-more-wrp {
        background-color: #0f0a01;

        .containe {
            max-width: 1500px;
            display: block;
            margin: 0 auto;
        }

        .learn-more-sec {
            padding: 120px 0;
            display: flex;

            @media screen and (max-width: 991px) {
                & {
                    flex-wrap: wrap;
                    justify-content: center;
                    padding: 0 0 75px;
                }
            }

            .learn-con-wrp {
                // max-width: 50vw;
                // min-height: 40vw;
                width: 50vw;
                max-width: 660px;
                height: 500px;
                min-width: 660px;
                padding: 70px 0px 0px 55px;
                background-image: url('../../assets/images/learnMoreBG.png');
                background-size: contain;
                background-repeat: no-repeat;

                @media screen and (min-width: 991px) {
                    .bg-tmp {
                        display: none;
                    }
                }

                @media screen and (max-width: 991px) {
                    & {
                        width: 100%;
                        max-width: 100%;
                        height: 100%;
                        min-width: 100%;
                        padding: 0;
                        background: none;
                    }
                }

                .content-wrp {
                    width: 495px;
                    padding: 50px 0px 0 0px;
                    display: table;
                    margin: 0 auto;
                    float: none;

                    @media screen and (max-width: 991px) {
                        & {
                            width: 100%;
                            display: block;
                            padding: 50px 15px 0 15px;
                            background: #f9efe8;
                            text-align: center;
                        }
                    }

                    @media screen and (min-width: 991px) {
                        & {
                            .learn-con-slider {
                                display: none;
                            }
                        }
                    }

                    @media screen and (max-width: 991px) {
                        & {
                            .learn-con-slider {

                                // display: none;
                                .swiper-slide {
                                    width: auto !important;

                                    ul {
                                        li {
                                            flex-direction: column;
                                            min-width: 100px;

                                            span {
                                                margin-right: 0;
                                            }

                                            svg {
                                                width: 35px;
                                                height: 35px;
                                            }

                                            p {
                                                font-size: 14px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    @media screen and (max-width: 991px) {
                        & {
                            .learn-con-slider {
                                ul {
                                    display: block;

                                    li {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }

                    h1 {
                        font-family: 'Andika', sans-serif;
                        color: #257D28;
                        font-weight: 700;
                        margin-top: 0;
                        margin-bottom: 10px;
                    }

                    p {
                        font-size: 14px;
                        margin: 0;
                    }

                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        padding-top: 20px;

                        li {
                            display: flex;
                            align-items: center;
                            width: 50%;
                            margin-bottom: 5px;

                            span {
                                margin-right: 10px;
                            }

                            p {
                                margin: 0;
                                font-size: 13px;
                            }
                        }

                        @media screen and (max-width: 991px) {
                            & {
                                display: none;
                            }
                        }
                    }
                }

            }

            .learn-img-wrp {
                width: 40vw;
                display: flex;
                position: relative;
                height: 40vw;
                max-width: 800px;
                max-height: 800px;

                @media screen and (max-width: 1110px) {
                    & {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        width: auto;
                    }
                }

                @media screen and (max-width: 991px) {
                    & {
                        position: relative;
                        height: auto;
                        max-width: 540px;
                        margin: 0 auto;
                        justify-content: flex-end;
                        top: -50px;
                    }
                }

                .main-img {
                    width: 100%;
                    position: relative;
                    padding-bottom: 20%;

                    @media screen and (max-width: 991px) {
                        & {
                            width: 75%;
                        }
                    }

                    &>img {
                        height: 100%;
                        object-fit: contain;
                    }

                    .horse-img {
                        position: absolute;
                        bottom: 0%;
                        right: -15%;

                        img {
                            width: 35vw !important;
                            max-width: 700px;

                            @media screen and (max-width: 1250px) {
                                & {
                                    width: 70% !important;
                                    float: right;
                                }
                            }
                        }

                        @media screen and (max-width: 1250px) {
                            & {
                                right: 0;
                            }
                        }
                    }
                }

                .sub-img {
                    position: absolute;
                    left: -25%;
                    // width: 23vw;
                    bottom: 10%;
                    width: 50%;
                    max-width: 400px;

                    @media screen and (max-width: 991px) {
                        & {
                            left: 1%;
                            bottom: 10%;
                            width: 45%;
                        }
                    }
                }
            }
        }
    }

    .staff-sec-wrp {
        background-image: url('../../assets/images/staff_assets/cloud4.png'), url('../../assets/images/staff_assets/staffMainBG.png');
        background-size: 15%, 100% 100%;
        background-repeat: no-repeat;
        background-position: right 99%, center;
        padding: 150px 0 130px;
        margin-bottom: 25px;

        @media screen and (max-width: 1400px) {
            & {
                background-size: 15%, cover;
            }
        }

        @media screen and (max-width: 991px) {
            & {
                background-image: url('../../assets/images/staff_assets/cloud4.png'), url('../../assets/images/staff_assets/staffMainBGMob.png');
                background-size: 15%, 100% 100%;
                padding-bottom: 0;
                padding-top: 100px;
            }
        }

        .clouds-float-wrp.f-wrp {
            position: absolute;
            left: 0;
            height: 100%;
            width: 100%;
            top: 0;

            span {
                width: 100%;
                display: block;
                position: absolute;
                overflow: hidden;

                &:nth-child(1) {
                    top: 20%;

                    img {
                        position: relative;
                        width: 200px;
                        animation: cloudAnimation 15s linear infinite;
                    }
                }

                &:nth-child(2) {
                    top: 40%;

                    img {
                        position: relative;
                        width: 200px;
                        animation: cloudAnimation 20s linear infinite;
                    }
                }

                &:nth-child(3) {
                    top: 72%;

                    img {
                        position: relative;
                        width: 150px;
                        animation: cloudAnimation 25s linear infinite;
                    }
                }
            }

            @keyframes cloudAnimation {
                0% {
                    right: 10%;
                }

                100% {
                    right: -110%;
                }
            }
        }

        .lady-img {
            position: absolute;
            right: 35px;
            bottom: 10px;
            width: 145px;

            @media screen and (max-width: 991px) {
                & {
                    position: relative;
                    right: 35px;
                    display: block;
                    height: auto;
                    bottom: 2px;
                    float: right;
                }
            }
        }

        .staff-sec-container {
            display: flex;
            align-items: flex-end;
            flex-wrap: wrap;

            @media screen and (max-width: 991px) {
                & {
                    flex-direction: column;
                    align-items: center;
                    padding-top: 200px;
                }
            }

            .img-sec-wrp {
                flex: 1;

                span {
                    max-width: 500px;
                    width: 100%;
                    display: block;
                    margin: 0 auto;

                    @media screen and (max-width: 991px) {
                        & {
                            max-width: 500px;
                            display: block;
                            margin: 0 auto;
                            width: 100%;
                            height: 450px;

                            img {
                                object-fit: contain;
                                width: 100%;
                                height: 100%;
                                object-position: center;
                            }
                        }
                    }
                }
            }

            .con-sec-wrp {
                padding-bottom: 5%;
                padding-left: 25px;
                max-width: 650px;
                width: 100%;

                @media screen and (max-width: 1400px) {
                    & {
                        flex: 1;
                    }
                }

                @media screen and (max-width: 575px) {
                    & {
                        padding-left: 0px;
                    }
                }

                .staff-con-img {
                    display: flex;
                    max-width: 385px;
                    position: relative;
                    animation: angelAnimation 5s linear infinite;

                    .baloon-img {
                        position: relative;
                        top: -50px;
                        left: 25px;
                    }

                    .angel2-img {
                        padding-top: 45px;
                    }

                    @keyframes angelAnimation {
                        0% {
                            top: 0;
                        }

                        50% {
                            top: 20px;
                        }

                        100% {
                            top: 0;
                        }
                    }

                    @media screen and (max-width: 991px) {
                        & {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                        }
                    }
                }

                h1 {
                    color: #FFF500;
                    font-family: 'Andika', sans-serif;
                    margin: 0;
                }

                p {
                    color: #0F0A01;

                    b {
                        color: #FFF500;
                    }

                    &:nth-of-type(2) {
                        width: calc(100% - 70px);

                        @media screen and (max-width: 575px) {
                            & {
                                width: 100%;
                            }
                        }
                    }
                }

                .pln-btn {
                    display: flex;
                    align-items: center;
                    color: #FF7940;
                    text-decoration: underline
                }
            }
        }
    }

    .press-sec-wrp {
        .press-header {
            text-align: center;
            margin: 30px 0 60px;

            .press-icon {
                width: 65px;
                margin: 0 auto;
                display: block;
                margin-bottom: 10px;
            }

            h1,
            p {
                margin: 0;
                color: #fff;
            }

            h1 {
                font-family: 'Andika', sans-serif;
            }
        }

        .press-content {
            padding-bottom: 100px;

            .press-content-slider {
                display: none;
            }

            @media screen and (max-width: 991px) {
                & {
                    padding-bottom: 50px;
                }
            }

            @media screen and (max-width: 767px) {
                & .row {
                    display: none;
                }

                .press-content-slider {
                    display: block;
                }
            }

            .row>div:nth-child(odd) {
                a.f-wrp {
                    float: left;
                }
            }

            .row>div:nth-child(even) {
                a.f-wrp {
                    float: right;
                }
            }

            a {
                max-width: 500px;

                .basic-news-blk {
                    .press-basic {
                        display: flex;
                        align-items: center;
                        color: #fff;

                        img {
                            width: 20px;
                            margin-right: 10px;
                        }

                        margin-bottom: 10px;
                    }

                    .press-title {
                        margin: 0;
                        color: #FF7940;
                        text-decoration: underline;
                        background: transparent;
                        border: none;
                        font-size: 18.72px;
                        padding: 0;
                        cursor: pointer;
                    }

                    p {
                        color: #fff;
                        margin-bottom: 5px;
                    }

                    .date {
                        color: #B4AC9C;
                        font-size: 13px;
                    }
                }

                .press-img {
                    display: table;
                    // height: 255px;
                    width: 100%;
                    border-radius: 15px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }
        }

        .crp-block-wrp {
            padding-top: 100px;

            @media screen and (max-width: 991px) {
                & {
                    padding-top: 30px;
                }
            }

            .crp-header {
                display: flex;
                align-items: center;
                justify-content: center;

                @media screen and (max-width: 991px) {
                    & {
                        span {
                            display: none;
                        }
                    }
                }

                h1 {
                    margin: 0;
                    font-family: 'Andika', sans-serif;
                    color: #fff;
                    font-weight: 700;
                    font-size: 42px;
                    white-space: nowrap;
                    padding-left: 20px;
                    padding-right: 30px;
                    position: relative;

                    @media screen and (max-width: 991px) {
                        & {
                            white-space: break-spaces;
                            text-align: center;
                        }
                    }

                    @media screen and (max-width: 767px) {
                        & {
                            font-size: 36px;
                        }
                    }

                    &::after {
                        content: '';
                        width: calc(100% - 50px);
                        height: 50px;
                        position: absolute;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        background-image: url('./../../assets/images/CPR/h1Aftr.png');
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                        top: 100%;
                    }
                }
            }

            .crp-con-blk {
                padding-top: 25px;
                background-image: url('../../assets/images/CPR/girls.png');
                background-position: right bottom;
                background-size: 510px;
                background-repeat: no-repeat;
                padding-bottom: 55px;

                @media screen and (max-width: 1200px) {
                    & {
                        background-size: 43%;
                    }
                }

                @media screen and (max-width: 991px) {
                    & {
                        background-image: none;
                    }
                }

                h1 {
                    display: flex;
                    align-items: center;
                    color: #fff;
                    font-family: 'Andika', sans-serif;
                    font-size: 42px;
                    position: relative;

                    @media screen and (max-width: 991px) {
                        & {
                            align-items: flex-end;
                        }
                    }

                    @media screen and (max-width: 767px) {
                        & {
                            display: block;
                            width: 100%;
                            font-size: 38px;
                        }
                    }

                    img {
                        width: 10px;
                        margin-left: 10px;

                        @media screen and (max-width: 991px) {
                            & {
                                display: none;
                            }
                        }
                    }

                    .slide-txt {
                        font-family: 'Architects Daughter', cursive;
                        color: #FBE09A;
                        position: relative;
                        right: -95px;
                        transform: rotate(-9.578deg);

                        @media screen and (max-width: 1110px) {
                            & {
                                right: -10px;
                            }
                        }

                        @media screen and (max-width: 991px) {
                            & {
                                right: 35px;
                            }
                        }

                        @media screen and (max-width: 767px) {
                            & {
                                position: relative;
                                right: 0;
                                float: right;
                            }
                        }
                    }
                }

                &>h1::before,
                &>h1::after {
                    background-size: contain;
                    background-repeat: no-repeat;
                    position: absolute;

                    @media screen and (max-width: 991px) {
                        & {
                            display: none;
                        }
                    }
                }

                &>h1::before {
                    content: '';
                    background-image: url('../../assets/images/CPR/head-befr.png');
                    width: 80px;
                    height: 40px;
                    top: -30px;
                    left: 10px;
                }

                &>h1::after {
                    content: '';
                    background-image: url('../../assets/images/CPR/head-aftr.png');
                    width: 40px;
                    height: 40px;
                    bottom: -10px;
                    left: -32px;
                }

                p {
                    color: #fff;
                    max-width: 680px;
                    margin: 0;
                }

                .rate-box.f-wrp {
                    display: flex;
                    align-items: center;

                    h2 {
                        color: #fff;
                        font-size: 32px;
                        max-width: 185px;
                        margin-right: 60px;

                        @media screen and (max-width: 767px) {
                            & {
                                max-width: 100%;
                                margin-right: 0;
                                font-size: 36px;
                                font-weight: 400;
                            }
                        }
                    }

                    .rate {
                        h1 {
                            color: #0F0A01;
                            font-family: 'Architects Daughter', cursive;
                            display: block;
                            background-image: url('../../assets/images/CPR/chalk.png');
                            padding-left: 35px;
                            padding-right: 35px;
                            background-position: center;
                            background-size: contain;
                            background-repeat: no-repeat;
                            font-size: 50px;
                            margin: 0;

                            @media screen and (max-width: 767px) {
                                & {
                                    font-size: 30vw;
                                }
                            }
                        }

                        h5 {
                            color: #fff;
                            font-family: 'Architects Daughter', cursive;
                            margin: 0;
                            text-align: center;
                            font-size: 22px;
                            font-weight: 400;

                            span {
                                display: block;
                                font-size: 14px;
                            }
                        }
                    }

                    @media screen and (max-width: 767px) {
                        & {
                            flex-direction: column;
                        }
                    }
                }

                .into-box {
                    display: block;
                    float: left;
                    max-width: 625px;
                    text-align: right;

                    @media screen and (max-width: 767px) {
                        & {
                            float: right;
                        }
                    }

                    h1 {
                        font-family: 'Architects Daughter', cursive;
                        margin: 0;
                        display: block;
                        text-transform: uppercase;
                        color: #FFB69A;

                        @media screen and (max-width: 767px) {
                            & {
                                font-size: 24px;
                            }
                        }
                    }

                    p {
                        text-transform: lowercase;
                        font-size: 28px;
                        color: #FFF4EE;

                        @media screen and (max-width: 767px) {
                            & {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }

    .contct-sec-wrp {
        background-image: url('../../assets/images/contact/cntactBgGreen.png');
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-top: 250px;

        @media screen and (max-width: 991px) {
            & {
                background-image: url('../../assets/images/contact/cntactBgGreenMob.png');
                margin-top: 50px;
            }
        }

        .contact-sec-container {
            display: flex;
            width: 100%;
            padding: 20px 40px;
            float: none;
            margin: 0 auto;
            margin-top: 200px;
            margin-bottom: 175px;
            background-image: url('../../assets/images/contact/cntactBgWht.png');
            background-position: center;
            background-size: 100% 100%;
            background-repeat: no-repeat;

            &::before {
                content: '';
                background-image: url('../../assets/images/contact/child.png');
                position: absolute;
                left: 20%;
                top: -80%;
                width: 180px;
                height: 375px;
                background-size: contain;
                background-repeat: no-repeat;

                @media screen and (max-width: 991px) {
                    & {
                        display: none;
                    }
                }
            }

            &::after {
                content: '';
                background-image: url('../../assets/images/contact/Child_illu.png');
                position: absolute;
                right: 0;
                bottom: -80px;
                width: 190px;
                height: 400px;
                background-size: contain;
                background-repeat: no-repeat;

                @media screen and (max-width: 991px) {
                    & {
                        display: none;
                    }
                }
            }

            @media screen and (max-width: 991px) {
                & {
                    margin-top: 25px;
                    margin-bottom: 25px;
                    padding: 20px 0px;
                }
            }

            .contct-img-sec {
                width: 300px;
                margin-left: 50px;
                margin-right: 50px;

                @media screen and (max-width: 1200px) {
                    & {
                        margin-left: 0px;
                        margin-right: 0px;
                        width: 235px;
                    }
                }

                @media screen and (max-width: 991px) {
                    & {
                        display: none;
                    }
                }

                .press-icon {
                    width: 100%;
                    height: 100%;
                    display: block;

                    img {
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }

            .contct-con-sec {
                width: 100%;

                @media screen and (max-width: 1200px) {
                    & {
                        padding-top: 50px;
                        padding-bottom: 50px;
                    }
                }

                .flex-box {
                    display: flex;
                    align-items: center;

                    .conct-main-con {
                        width: 425px;

                        @media screen and (max-width: 1200px) {
                            & {
                                width: 100%;
                            }
                        }

                        h1 {
                            margin: 0;
                            color: #FFAE00;
                            font-weight: 700;
                            font-family: 'Andika', sans-serif;

                            @media screen and (max-width: 991px) {
                                & {
                                    text-align: center;
                                }
                            }
                        }

                        p {
                            font-size: 14px;
                            width: 350px;

                            @media screen and (max-width: 991px) {
                                & {
                                    text-align: center;
                                    width: 100%;
                                }
                            }
                        }

                        ul {
                            li {
                                display: flex;
                                align-items: center;

                                &:not(:last-child) {
                                    margin-bottom: 15px;
                                }

                                span {
                                    width: 20px;
                                    height: 20px;
                                    display: block;

                                    svg {
                                        width: 16px;
                                        height: 16px;
                                    }
                                }

                                p {
                                    margin: 0;
                                    font-size: 14px;
                                    color: #0F0A01;
                                    font-weight: 500;
                                    width: max-content;

                                }

                                div {
                                    display: flex;
                                    position: relative;

                                    &:not(:last-child) {
                                        padding-right: 5px;

                                        &::after {
                                            content: '';
                                            width: 1px;
                                            height: 100%;
                                            background: #0F0A01;
                                            position: absolute;
                                            right: 0;
                                            top: 0;
                                        }
                                    }
                                }
                            }

                            @media screen and (max-width: 991px) {
                                & {
                                    padding-top: 50px;
                                }
                            }
                        }
                    }

                    .wall-painting {
                        position: relative;

                        @media screen and (max-width: 991px) {
                            & {
                                display: none;
                            }
                        }

                        .wall-paint {
                            width: 330px;
                            display: block;

                            @media screen and (max-width: 1200px) {
                                & {
                                    width: 225px;
                                }
                            }
                        }
                    }
                }

                .social-list {
                    display: flex;
                    padding-top: 10px;
                    padding-bottom: 25px;

                    li {
                        margin-right: 10px;

                        @media screen and (max-width: 991px) {
                            &:not(:last-child) {
                                margin-bottom: 10px;
                            }
                        }

                        @media screen and (max-width: 767px) {
                            & {
                                margin-right: 0px;
                            }
                        }
                    }

                    a {
                        display: flex;
                        align-items: center;
                        height: 50px;
                        padding: 0px 25px;
                        border-radius: 50px;
                        border: 1px solid #548A12;

                        @media screen and (max-width: 767px) {
                            & {
                                justify-content: center;
                            }
                        }

                        &:not(:last-child) {
                            margin-right: 20px;
                        }

                        span {
                            width: 20px;
                            display: block;
                            margin-right: 7px;
                        }

                        &.fb {
                            color: #1877F2;
                        }

                        &.whtsap {
                            color: #257D28;
                        }

                        &.insta {
                            color: #FF3D55;
                        }

                        p {
                            font-weight: 700;
                            margin: 0;
                        }
                    }

                    @media screen and (max-width: 991px) {
                        & {
                            padding-bottom: 0;
                            flex-wrap: wrap;
                            margin-top: 35px;
                            justify-content: center;
                        }
                    }

                    @media screen and (max-width: 767px) {
                        & {
                            flex-direction: column;
                        }
                    }
                }
            }
        }
    }

    .video-sec-wrp {
        // min-height: 770px;
        margin: 125px 0;

        .max-width-wrp {
            display: table;
            margin: 0 auto;
            position: relative;
            width: 100%;
            height: 100%;
            max-width: 1500px;
        }

        .video-sec-bg {
            // position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            img {
                height: 100%;
                object-position: center;
                object-fit: contain;
            }
        }

        .video-btn-blk {
            text-align: center;
            max-width: 350px;
            margin: 0 auto;
            position: absolute;
            right: 20%;
            bottom: 185px;

            @media screen and (max-width: 767px) {
                & {
                    bottom: 0;
                    right: 0;
                    left: 0;
                }
            }

            .video-icon {
                width: 70px;
                display: block;
                margin: 0 auto;
            }

            .blk-btn {
                background-color: #FF7940;
                color: #FFF4EE;
                display: inline-block;
                padding: 0 75px;
                height: 50px;
                line-height: 50px;
                border-radius: 50px;
                font-weight: 700;
            }
        }
    }

    .enroll-sec-wrp {
        background-image: url('../../assets/images/enrollment-bg.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 745px;

        @media screen and (max-width: 991px) {
            & {
                height: 100%;
                background-size: cover;
            }
        }

        .max-width-wrp {
            display: table;
            margin: 0 auto;
            position: relative;
            width: 100%;
            height: 100%;
            max-width: 1500px;
            height: 745px;

            @media screen and (max-width: 991px) {
                & {
                    height: 100%;
                }
            }

            &::after {
                content: '';
                background-image: url('../../assets/images/enrollmentBGImg.png');
                width: calc(50% + 60px);
                height: calc(100% - 15px);
                display: block;
                position: absolute;
                left: 0;
                top: 20px;
                background-size: auto 100%;
                background-position: right bottom;
                background-repeat: no-repeat;

                @media screen and (max-width: 991px) {
                    & {
                        display: none;
                    }
                }
            }
        }

        .castle {
            width: 255px;
            display: block;
            position: absolute;
            right: 0;
            top: -100px;

            @media screen and (max-width: 991px) {
                & {
                    display: none;
                }
            }

            img {
                position: relative;
                animation: angelAnimation 5s linear infinite;
            }

            @keyframes angelAnimation {
                0% {
                    top: 0;
                }

                50% {
                    top: 20px;
                }

                100% {
                    top: 0;
                }
            }
        }

        .enroll-sec-continer {
            max-width: 500px;
            float: right;
            padding-top: 85px;
            display: flex;
            flex-direction: column;
            height: 100%;
            z-index: 9;

            * {
                color: #fff;
            }

            h1 {
                font-family: 'Andika', sans-serif;

                @media screen and (max-width: 991px) {
                    & {
                        text-align: center;
                    }
                }
            }

            P {
                font-size: 14px;
            }

            span {
                font-size: 12px;
                word-break: break-all;
            }

            .blk-btn {
                border-radius: 50px;
                background: #FF7940;
                display: flex;
                height: 50px;
                padding: 5px 37px;
                justify-content: center;
                align-items: center;
                margin-top: 70px;
            }

            @media screen and (max-width: 991px) {
                & {
                    margin: 0 auto;
                    float: none;
                    padding-bottom: 125px;
                }
            }
        }
    }

    .payment-sec-wrp {
        background-image: url('../../assets/images/paymentBGImg.png'), url('../../assets/images/paymentBG.png');
        background-position: right 40px, center;
        background-repeat: no-repeat;
        background-size: 48%, 100% 100%;
        min-height: 600px;
        margin-top: 150px;
        overflow: hidden;

        @media screen and (max-width: 991px) {
            & .container {
                max-width: 100%;
            }

            & {
                background-size: 48%, cover;
                background-position: right center, center;
            }
        }

        @media screen and (max-width: 767px) {
            & {
                background-size: 0, cover;
            }

            & .container {
                padding-left: 0;
                padding-right: 0;
            }
        }

        @media (max-width: 575px) {
            margin-top: 75px;
        }

        .payment-img-sec {
            display: none;
            position: relative;

            @media screen and (max-width: 767px) {
                & {
                    display: block;
                }

                &::after {
                    content: '';
                    background-image: url('../../assets/images/Decoration.png');
                    background-position: right top;
                    background-size: contain;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 20px;
                    height: 100%;
                    background-repeat: no-repeat;
                }
            }
        }

        &::after {
            content: '';
            background-image: url('../../assets/images/Decoration.png');
            background-position: right top;
            background-size: contain;
            position: absolute;
            right: 0;
            top: 0;
            width: 20px;
            height: 100%;

            @media screen and (max-width: 767px) {
                & {
                    display: none;
                }
            }
        }

        .payment-sec-container {
            display: block;
            float: left;
            padding-top: 125px;
            padding-bottom: 125px;

            @media screen and (max-width: 1200px) {
                & {
                    width: 50%;
                }
            }

            @media screen and (max-width: 991px) {
                width: 49%;
            }

            @media screen and (max-width: 767px) {
                & {
                    padding-top: 0px;
                    padding-bottom: 50px;
                    width: 100%;
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }

            .payment-sec-con {
                max-width: 500px;

                @media screen and (max-width: 991px) {
                    max-width: 100%;
                }

                * {
                    color: #0F0A01;
                }

                h1 {
                    color: #2C89DD;
                    font-family: 'Andika', sans-serif;
                    font-size: 42px;
                    font-weight: 700;
                }

                p {
                    span {
                        font-size: 12px;
                        display: block;
                    }
                }

                .blk-btn {
                    display: block;
                    background: #2A9D8F;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    color: #fff;
                    font-weight: 700;
                    border-radius: 50px;
                    margin-top: 30px;
                }
            }
        }
    }

    .tuition-sec-wrp {

        .max-width-wrp {
            display: table;
            margin: 0 auto;
            position: relative;
            width: 100%;
            height: 100%;
            max-width: 1500px;
        }

        .tuition-header {
            * {
                color: #FFF4EE;
            }

            padding-top: 200px;
            text-align: center;
            padding-bottom: 30px;

            h1 {
                margin: 0;
                font-family: "Andika", sans-serif;
                position: relative;
                z-index: 9;
            }

            p {
                margin: 0;
                position: relative;
                z-index: 9;
            }

            .tution-head-img {
                position: absolute;
                left: 0;
                top: 0;
                width: 50%;
                height: 325px;

                img {
                    height: 100%;
                    object-fit: contain;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }

                .tution-img1 {
                    z-index: 99;
                    width: 34%;
                }

                .tution-img2 {
                    z-index: 9;
                    width: 80%;
                    left: 25%;
                    top: -20%;
                }

                .tution-img3 {
                    width: 70%;
                    height: 100%;
                    object-position: left;
                    object-fit: contain;
                }

                @media screen and (max-width: 767px) {
                    & {
                        width: 85%;
                    }
                }
            }
        }

        .clouds-float-wrp.f-wrp {
            position: absolute;
            left: 0;
            height: 100%;
            width: 100%;
            top: 0;

            span {
                width: 100%;
                display: block;
                position: absolute;
                overflow: hidden;

                &:nth-child(1) {
                    top: 35%;

                    img {
                        position: relative;
                        width: 70px;
                        animation: cloudAnimation 15s linear infinite;
                    }
                }

                &:nth-child(2) {
                    top: 45%;

                    img {
                        position: relative;
                        width: 75px;
                        animation: cloudAnimation 20s linear infinite;
                    }
                }

                &:nth-child(3) {
                    top: 72%;

                    img {
                        position: relative;
                        width: 50px;
                        animation: cloudAnimation 25s linear infinite;
                    }
                }

                &:nth-child(4) {
                    top: 95%;

                    img {
                        position: relative;
                        width: 100px;
                        animation: cloudAnimation 25s linear infinite;
                    }
                }
            }

            @keyframes cloudAnimation {
                0% {
                    right: 10%;
                }

                100% {
                    right: -110%;
                }
            }
        }

        .tuition-fee-structure {
            * {
                color: #F5FFFD;
            }

            @media screen and (max-width: 767px) {
                & {
                    .row>div {
                        margin-bottom: 25px;
                    }
                }
            }

            .fee-sec-wrp {
                display: flex;
                flex-direction: column;
                height: 100%;
                max-width: 400px;
                margin: 0 auto;
                float: none;

                .fee-sec-img {
                    .fee-sec-icon {
                        position: relative;
                        left: 0;
                        top: 50px;
                        display: block;
                        width: 100%;
                        height: 100%;
                        right: 0;
                        margin: 0 auto;
                        z-index: 9;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            object-position: center;
                            max-width: 225px;
                            margin: 0 auto;
                        }
                    }

                    @media screen and (max-width: 767px) {
                        & {
                            display: none;
                        }
                    }
                }
            }

            .each-fee-sec {
                background: #FF7940;
                border-radius: 15px;
                padding: 40px 20px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                h3 {
                    font-family: "Andika", sans-serif;
                    text-align: center;
                    font-weight: 700;
                    font-size: 22px;
                }

                .trip-grid {
                    display: flex;
                    padding-bottom: 25px;

                    div {
                        flex: 1;
                        margin-right: 5px;
                    }

                    div:last-child {
                        max-width: 33.33%;
                        margin-right: 0px;
                    }

                    div:nth-child(1) {
                        border-top: 5px solid #B7FF5F;

                        b {
                            color: #B7FF5F;
                        }
                    }

                    div:nth-child(2) {
                        border-top: 5px solid #81D817;

                        b {
                            color: #81D817;
                        }
                    }

                    div:last-child {
                        border-top: 5px solid #257D28;

                        b {
                            color: #257D28;
                        }
                    }

                    span {
                        b {
                            display: block;
                        }
                    }
                }

                .pay-table {
                    table {
                        width: 100%;

                        tr {
                            td:last-child {
                                text-align: right;
                            }

                            td {
                                .price {
                                    background-color: #257D28;
                                    padding: 5px 15px;
                                    display: inline-block;
                                    border-radius: 5px;
                                    margin-top: 10px;
                                }
                            }

                            &:first-child {
                                td {
                                    .price {
                                        margin-top: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .tuition-info-txt {
                padding: 25px 20px;
                max-width: max-content;
                border-radius: 16px;
                background: #E94F1B;
                margin-top: 30px;

                h2 {
                    font-weight: 700;
                    margin: 0;
                    font-size: 24px;
                    font-family: "Andika", sans-serif;
                }

                @media screen and (max-width: 991px) {
                    & {
                        width: 100%;
                        max-width: 100%;
                        text-align: center;
                    }
                }
            }

        }


    }






}

.sample-btn {
    display: inline-block;
    padding: 6px 20px;
    background: #fff;
    min-width: 120px;
    border-radius: 6px;
    color: #000;

    &:hover {
        background: #fff;
    }
}


.facility-home {
    background-color: var(--parkBGOne);

    .home-main-wrp {
        background-color: var(--parkBGOne);
        ;
    }
}

.parking-page-main-wrapper {
    .parking-banner-con {
        display: flex;
        // background-color: var(--parkBGOne);
        background-image: url('../../assets/images/parkingPage/bannerBG.png');
        background-size: 100% 100%;
        background-position: bottom;
        background-repeat: no-repeat;
        padding-bottom: 50px;
        align-items: flex-end;
        margin-top: -40px;
        z-index: 9;

        @media screen and (max-width: 850px) {
            & {
                flex-direction: column;
                background-size: contain;
            }
        }

        .parking-banner-img {
            flex: 1;

            span {
                display: block;
                width: 90%;
                // height: 70%;
                // position: absolute;
                // left: 0;
                // bottom: 50px;
                margin: 0 auto;

                img {
                    height: 100%;
                    object-fit: contain;
                    object-position: left;
                }

                @media screen and (max-width: 850px) {
                    & {
                        position: relative;
                        margin: 0 auto;
                        width: 65%;
                        display: none;
                    }
                }
            }
        }

        .parkingBanner-con {
            flex: 0.8;
            z-index: 9;
            height: 100%;
            display: flex;
            align-self: center;

            .parkingBanner-sec-con {
                max-width: 375px;
                text-align: center;
                margin: 0 auto;

                @media screen and (max-width: 850px) {
                    & {
                        padding-top: 20px;
                        padding-bottom: 30px;
                    }
                }
            }

            span {
                display: block;
                width: 185px;
                margin: 0 auto;
            }

            h1 {
                color: var(--parkTextOne);
                margin-bottom: 0;
                font-family: var(--RedHatDisplay);
                font-size: 34px;
                font-style: normal;
                font-weight: 700;
                letter-spacing: 3px;
            }

            p {
                color: var(--parkTextTwo);
                margin-top: 10px;
                font-size: 20px;
            }

            .sample-btn {
                border-radius: 4px;
                background: var(--parkBG);
                color: var(--White);
                min-width: 185px;
                font-size: 18px;
            }
        }
    }

    .parking-slider-wrp {
        padding-top: 55px;
        padding-bottom: 25px;
        background: var(--parkBGOne);

        @media screen and (min-width: 425px) and (max-width: 575px) {
            & {
                .col-lg-3.col-md-4.col-sm-6.col-xs-12 {
                    width: 50%;
                }

                .each-parking-items {
                    padding: 20px 10px !important;
                }
            }
        }

        .each-parking-items {
            max-width: 200px;
            padding: 40px 20px;
            border-radius: 20px;
            border: 1px solid #B191BA;
            background: var(--White);
            box-shadow: 0px 4px 4px 0px #730a9f3d;
            margin: 0 auto;
            float: none;
            margin-top: 10px;
            margin-bottom: 20px;
            text-align: center;

            // height: 100%;
            span {
                display: block;
                margin: 0 auto;
                width: 100%;
                height: 65px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            p {
                color: var(--Black);
                font-size: 14px;
                font-weight: 700;
                min-height: 45px;
                margin-bottom: 0;

                b {
                    color: var(--parkingTextThree);
                }
            }
        }

        .swiper-button-prev::after,
        .swiper-button-next::after {
            font-size: 17px;
            color: var(--White);
        }

        .swiper-button-prev,
        .swiper-button-next {
            bottom: 0px;
            left: 0;
            right: 0;
            margin: 0 auto;
            border-radius: 4px;
            background: linear-gradient(93deg, #A31995 21.6%, #A202E4 93.71%);
            width: 40px;
            height: 40px;
            top: unset;
        }

        .swiper-button-prev {
            left: -65px;
        }

        .swiper-button-next {
            right: -65px;
        }
    }

    .user-manual-wrp {
        background: var(--White);
        padding: 75px 0;

        @media screen and (max-width: 767px) {
            & {
                padding-top: 30px;
            }
        }

        .manual-header {
            h1 {
                color: var(--parkTextFour);
                margin: 0;
                font-family: var(--RedHatDisplay);
                font-style: normal;
                font-weight: 700;
                letter-spacing: 3px;
            }

            p {
                color: var(--parkTextFive);
                font-family: var(--Roboto);
                max-width: 215px;
            }

            .video-btn-wrp {
                p {
                    max-width: 100%;
                    display: flex;
                    align-items: center;
                    color: var(--parkTextFive);
                    font-family: var(--RedHatDisplay);
                    font-weight: 500;

                    svg {
                        width: 40px;
                        height: 40px;
                        padding-right: 7px;
                    }
                }

                .video-button-list {
                    display: flex;
                    gap: 20px;

                    @media screen and (max-width: 520px) {
                        & {
                            flex-direction: column;
                        }
                    }

                    li {
                        .video-button {
                            padding: 10px 50px;
                            border-radius: 50px;
                            color: var(--White);
                            display: flex;
                            align-items: center;
                            gap: 7px;

                            @media screen and (max-width: 520px) {
                                & {
                                    justify-content: center;
                                }
                            }

                            &.provider-hub {
                                background: var(--parkTextFour);
                            }

                            &.parent-hub {
                                background: var(--parkingTextThree);
                            }
                        }
                    }
                }
            }
        }

        .accordian-wrapper {
            padding-top: 50px;

            .accordian-sec-wrp {
                &>p {
                    margin-top: 0;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }

                .each-accordian-sec {
                    border: 2px solid var(--parkTextFour);
                    margin-bottom: 15px;
                    border-radius: 15px;

                    .MuiAccordionSummary-content {
                        span {
                            margin-right: 10px;

                            svg {
                                width: 20px;
                                height: 20px;
                            }
                        }

                        h4 {
                            margin: 0;
                            color: var(--parkTextFive);
                            font-weight: 500;
                        }

                        p {
                            margin: 0;
                            font-size: 12px;
                            color: var(--parkTextFive);
                            font-weight: 500;
                        }
                    }

                    .MuiAccordionSummary-expandIconWrapper {
                        svg {
                            path {
                                stroke: var(--parkTextFive);
                            }
                        }
                    }

                    .MuiCollapse-root {
                        ul {
                            li {
                                display: flex;
                                align-items: center;
                                gap: 5px;
                                font-size: 14px;
                                color: var(--parkTextFive);
                                font-weight: 500;
                                margin-bottom: 7px;

                                span {
                                    line-height: 1;
                                    width: 20px;
                                    height: 20px;

                                    svg {
                                        width: inherit;
                                        height: inherit;

                                        path {
                                            fill: var(--parkColorSix);
                                        }
                                    }

                                    &.parentIcon {
                                        svg {
                                            path {
                                                fill: none;
                                                stroke: var(--parkColorSix);
                                                stroke-width: 1.7px;
                                            }
                                        }
                                    }

                                    &.PalmIcon,
                                    &.PaymentsIcon,
                                    &.EmployeeIcon {
                                        svg {
                                            path {
                                                fill: none;
                                                stroke: var(--parkColorSix);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                padding-right: 30px;

                @media screen and (max-width: 991px) {
                    & {
                        padding-right: 0;
                        padding-bottom: 30px;
                    }
                }
            }

            .video-wrp {
                padding-left: 30px;

                @media screen and (max-width: 991px) {
                    & {
                        padding-left: 0;
                        padding-top: 30px;
                    }
                }

                span {
                    border-radius: 14px;
                    overflow: hidden;
                    display: block;
                    height: 100%;
                    width: 100%;
                }

                &::before {
                    content: '';
                    width: 5px;
                    height: 100%;
                    position: absolute;
                    left: -17.5px;
                    top: 0;
                    border-radius: 50px;
                    background: var(--parkLine);

                    @media screen and (max-width: 991px) {
                        & {
                            width: 100%;
                            height: 5px;
                            left: 0;
                        }
                    }
                }
            }
        }
    }

    .parking-foot {
        background: var(--White);

        p {
            text-align: right;
            font-size: 14px;
        }
    }
}

.evolve-page-main-wrapper {
    .evolve-banner-con {
        // height: calc(100vh - 48px);

        .evolve-banner-img {
            span {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;

                @media screen and (max-width: 1024px) {
                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-color: #2E0032;
                        display: block;
                        opacity: 0.4;
                    }
                }

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }

        .evolveBanner-con {
            position: relative;
            z-index: 1;
            min-height: calc(100vh - 48px);
            padding-top: 35px;
            padding-bottom: 35px;
            display: flex;
            align-items: center;
            padding-left: 75px;

            @media screen and (max-width: 767px) {
                & {
                    padding-left: 15px;
                    padding-right: 15px;
                    justify-content: center;
                    text-align: center;
                }
            }

            .evolveBanner-sec-con {
                max-width: 465px;

                p {
                    color: var(--White);
                    margin: 35px 0 20px;
                    font-size: 20px;
                    font-weight: 500;

                    @media screen and (max-width: 500px) {
                        & {
                            font-size: 16px;
                        }
                    }

                }

                span {
                    display: block;
                    padding-right: 30px;
                }

                .sample-btn {
                    border-radius: 50px;
                    color: var(--parkTextFour);
                    font-size: 16px;
                    font-family: var(--RedHatDisplay);
                    font-weight: 500;
                    padding: 10px 35px;
                }
            }
        }
    }


    .evolve-scrolling-sec {
        background: var(--scrollBG);
        color: var(--White);
        padding: 12px 0;
    }
}

.pp-platform-home {

    .main-head-wrp,
    .home-main-wrp {
        background-color: var(--White) !important;
    }

    .pp-platform-banner-con {
        min-height: calc(100vh + 150px);

        &::after {
            content: '';
            width: 75%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-image: url('../../assets/images/ppPlatform/Logo.png');
            background-size: contain;
            background-repeat: no-repeat;

            @media screen and (max-width: 767px) {
                & {
                    width: 100%;
                    background-position: center;
                }
            }
        }

        .pp-platform-banner-img {
            span {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: bottom;
                }
            }
        }

        .pp-platformBanner-con {
            position: relative;
            z-index: 1;
            min-height: calc(100vh + 0px);
            display: flex;
            align-items: center;
            padding: 20px 0;

            @media screen and (max-width: 991px) {
                & {
                    flex-direction: column-reverse;
                    min-height: calc(100vh + 100px);
                    padding-bottom: 120px;
                }
            }

            @media screen and (max-width: 767px) {
                & {
                    padding-bottom: 160px;
                }
            }

            .pp-platformBanner-sec-con {
                flex: 1;
                max-width: 50%;
                padding-right: 75px;

                @media screen and (max-width: 991px) {
                    & {
                        max-width: 100%;
                        padding-right: 0px;
                    }
                }

                p {
                    color: var(--White);
                    margin: 35px 0 20px;
                    font-size: 16px;
                    font-weight: 400;

                    @media screen and (max-width: 767px) {
                        & {
                            font-size: 14px;
                        }
                    }
                }

                h1 {
                    color: var(--White);
                    margin: 0;
                    font-family: var(--RedHatDisplay);
                    font-style: normal;
                    font-weight: 700;
                    letter-spacing: 3px;
                    font-size: 42px;

                    @media screen and (max-width: 767px) {
                        & {
                            font-size: 38px;
                        }
                    }
                }

                h3 {
                    color: var(--White);
                    font-weight: 800;
                    line-height: normal;
                    letter-spacing: 4px;
                    margin-top: 0;
                    font-size: 20px;

                    @media screen and (max-width: 767px) {
                        & {
                            font-size: 16px;
                        }
                    }

                    b {
                        color: var(--ppColor1);
                    }
                }

                p {
                    font-size: 20px;

                    @media screen and (max-width: 767px) {
                        & {
                            font-size: 14px;
                        }
                    }

                    span {
                        display: inline-block;
                        padding: 0;
                        color: var(--ppColor2);
                        font-weight: 700;
                    }

                    &.title-text {
                        margin: 0;
                        font-size: 18px;
                        font-weight: 400;

                        @media screen and (max-width: 767px) {
                            & {
                                font-size: 16px;
                            }
                        }
                    }
                }

                span {
                    display: block;
                    padding-right: 30px;
                }

                @media screen and (max-width: 470px) {
                    & {
                        .banner-btn-wrp {
                            display: flex;
                            flex-direction: column;
                            padding-top: 20px;
                        }
                    }
                }

                .sample-btn {
                    border-radius: 50px;
                    color: var(--parkTextFour);
                    font-size: 16px;
                    font-family: var(--RedHatDisplay);
                    font-weight: 500;
                    padding: 10px 35px;

                    @media screen and (max-width: 470px) {
                        & {
                            text-align: center;
                        }
                    }

                    &.pln-btn {
                        border: 2px solid var(--White);
                        background: rgba(255, 255, 255, 0.1);
                        color: var(--White);
                        font-family: var(--RedHatDisplay);
                        margin-left: 20px;

                        @media screen and (max-width: 470px) {
                            & {
                                margin-left: 0;
                                margin-top: 20px;
                            }
                        }
                    }
                }
            }

            .pp-platformBanner-sec-img {
                flex: 1;
                // height: 100%;

                span {
                    height: 100%;
                    display: block;
                    width: 100%;

                    @media screen and (max-width: 991px) {
                        & {
                            max-width: 300px;
                        }
                    }

                    img {
                        object-fit: contain;
                        object-position: center;
                        height: 100%;
                    }
                }
            }
        }

        .scroll-arrow-btn {
            margin: 0 auto;
            display: table;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 30px;
            z-index: 9;

            span {
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    width: 45px;
                    height: 45px;
                }
            }
        }
    }

    .parking-slider-wrp {
        padding-top: 55px;
        padding-bottom: 75px;
        background: var(--White);

        @media screen and (min-width: 375px) and (max-width: 575px) {
            & {
                .col-lg-3.col-md-4.col-sm-6.col-xs-12 {
                    width: 50%;
                }

                .each-parking-items {
                    padding: 20px 10px !important;
                }
            }
        }

        .benefits-wrp-header {
            h1 {
                font-size: 32px;
                margin-top: 0;
                font-weight: 700;
                margin-bottom: 30px;
                max-width: 600px;

                span {
                    font-weight: 700;
                    color: var(--ppColor3);
                }

                b {
                    color: var(--ppColor4);
                }
            }
        }

        .each-parking-items {
            max-width: 200px;
            padding: 40px 20px;
            border-radius: 20px;
            border: 1px solid #B191BA;
            background: var(--White);
            box-shadow: 0px 4px 4px 0px #730a9f3d;
            margin: 0 auto;
            float: none;
            margin-top: 10px;
            margin-bottom: 20px;
            text-align: center;

            // height: 100%;
            span {
                display: block;
                margin: 0 auto;
                width: 100%;
                height: 65px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            p {
                color: var(--Black);
                font-size: 14px;
                font-weight: 700;
                min-height: 45px;
                margin-bottom: 0;

                b {
                    color: var(--parkingTextThree);
                }
            }
        }
    }

    .video-sec-wrapper {
        background-image: url('../../assets/images/ppPlatform/videoBG.png');
        background-position: top;
        padding-top: 95px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        // margin-bottom: 100px;

        .watch-video-wrp {
            top: -30px;

            .row {
                align-items: flex-end;

                @media screen and (max-width: 991px) {
                    & {
                        flex-direction: column-reverse;
                    }
                }
            }

            .watch-vid-box {
                padding-bottom: 40px;

                @media screen and (max-width: 991px) {
                    & {
                        padding-top: 40px;
                    }
                }

                h3,
                p {
                    color: var(--White);
                }

                h3 {
                    font-family: var(--RedHatDisplay);
                    font-weight: 700;
                    letter-spacing: 6px;
                    margin-top: 0;
                    font-size: 26px;
                    margin-bottom: 0;
                }

                p {
                    margin: 15px 0;
                    font-weight: 300;
                    font-size: 14px;
                }

                .video-button {
                    border-radius: 50px;
                    color: var(--parkTextFour);
                    font-size: 16px;
                    font-family: var(--RedHatDisplay);
                    font-weight: 500;
                    padding: 10px 35px;
                    display: flex;
                    width: max-content;
                    gap: 10px;
                    background: var(--White);
                }
            }
        }

        .gallery-tab-wrapper {
            padding-top: 100px;
            padding-bottom: 35px;
            padding-left: 15px;
            padding-right: 15px;

            @media screen and (min-width: 1200px) {
                & {
                    max-width: 1920px;
                    margin: 0 auto;
                    float: none;
                }
            }

            @media screen and (max-width: 767px) {
                & {
                    padding-top: 35px;
                }
            }

            .gallery-tab-header {

                h1,
                p {
                    text-align: center;
                }

                h1 {
                    color: var(--White);
                    font-family: var(--RedHatDisplay);
                    font-weight: 700;
                    letter-spacing: 6px;
                    margin-top: 0;
                    font-size: 26px;
                }

                p {
                    color: var(--White);
                    font-weight: 300;
                    margin-top: 0;
                    font-size: 14px;
                }
            }

            .tab-sec-wrpapper {
                padding-top: 50px;

                .MuiTabs-scroller.MuiTabs-fixed {
                    @media screen and (max-width: 991px) {
                        & {
                            border: none;
                        }
                    }

                    .MuiTabs-flexContainer {
                        justify-content: center;
                        gap: 40px;
                        margin-bottom: 20px;

                        @media screen and (max-width: 470px) {
                            & {
                                gap: 10px;
                            }
                        }

                        @media screen and (max-width: 420px) {
                            & {
                                justify-content: flex-start;
                            }
                        }

                        button {
                            border-radius: 50px;
                            border: 2px solid var(--White);
                            background: rgba(255, 255, 255, 0.10);
                            color: var(--White);
                            font-family: var(--RedHatDisplay);
                            font-weight: 500;
                            padding: 10px 35px;
                        }

                        button.Mui-selected {
                            border: 2px solid #A31995;
                            background: #FFF;
                            color: var(--parkingTextThree);
                        }
                    }

                }

                .MuiTabs-indicator {
                    background: transparent;
                }

                .tab-body {
                    background: transparent;

                    @media screen and (max-width: 767px) {
                        & {
                            padding-top: 30px;
                        }

                        .MuiTabPanel-root {
                            padding: 0;
                        }
                    }
                }

            }
        }


    }

    .feature-main-wrapper {
        padding-top: 100px;

        @media screen and (max-width: 767px) {
            & {
                padding-top: 60px;
            }
        }

        .feature-detail-block {
            display: flex;
            flex-direction: column;
            margin: 0 auto;
            float: none;

            .each-feature-sec {
                display: flex;
                margin-bottom: 20px;
                gap: 65px;

                @media screen and (max-width: 991px) {
                    & {
                        gap: 0;
                    }
                }

                @media screen and (max-width: 767px) {
                    & {
                        flex-direction: column;
                    }
                }

                .feature-detail-sec {
                    padding: 60px 0;
                    padding-left: 40px;
                    // background: linear-gradient(90deg, #134870 0%, rgba(29, 29, 27, 0.46) 100%);
                    color: #000000;
                    min-height: 320px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    flex: 0.7;

                    @media screen and (max-width: 767px) {
                        & {
                            padding-left: 0px;
                        }
                    }

                    @media screen and (max-width: 500px) {
                        & {
                            padding: 30px 0;
                        }
                    }

                    .feature-btn {
                        border-radius: 50px;
                        color: var(--White);
                        font-size: 14px;
                        font-family: var(--RedHatDisplay);
                        font-weight: 500;
                        padding: 10px 50px;
                        margin-top: 30px;
                        display: flex;
                        width: max-content;
                        gap: 10px;
                        background: var(--ppColorBG);
                    }

                    h1 {
                        font-family: var(--RedHatDisplay);
                        font-weight: 700;
                        letter-spacing: 6px;
                        margin-top: 0;
                        font-size: 26px;
                        color: var(--parkTextFour);
                    }

                    p {
                        font-size: 14px;
                        font-family: var(--Roboto);
                        font-weight: 300;
                        margin-top: 0;
                        color: var(--parkTextFive);
                    }
                }

                .feature-img-sec {
                    flex: 1.3;

                    span {
                        display: block;
                        width: 100%;
                        height: 100%;
                        // position: relative;
                        position: absolute;
                        left: 0;
                        top: 0;
                        overflow: hidden;

                        @media screen and (max-width: 767px) {
                            & {
                                position: relative;
                                max-width: 350px;
                                margin: 0 auto;
                            }
                        }

                        img {
                            // position: absolute;
                            // left: 0;
                            // top: 0;
                            object-fit: contain;
                            object-position: center;
                            height: 100%;
                        }
                    }
                }

                &:nth-child(even) {
                    flex-direction: row-reverse;

                    @media screen and (max-width: 767px) {
                        & {
                            flex-direction: column;
                        }
                    }

                    .feature-detail-sec {
                        padding-left: 0;
                        padding-right: 40px;

                        @media screen and (max-width: 767px) {
                            & {
                                padding-right: 0px;
                            }
                        }
                    }
                }
            }
        }
    }

    .user-manual-wrp {
        background: var(--White);
        padding: 75px 0;

        @media screen and (max-width: 767px) {
            & {
                padding-top: 30px;
            }
        }

        .manual-header {
            h1 {
                color: var(--parkTextFour);
                margin: 0;
                font-family: var(--RedHatDisplay);
                font-style: normal;
                font-weight: 700;
                letter-spacing: 3px;
            }

            p {
                color: var(--parkTextFive);
                font-family: var(--Roboto);
                max-width: 215px;
            }

            .video-btn-wrp {
                p {
                    max-width: 100%;
                    display: flex;
                    align-items: center;
                    color: var(--parkTextFive);
                    font-family: var(--RedHatDisplay);
                    font-weight: 500;

                    svg {
                        width: 40px;
                        height: 40px;
                        padding-right: 7px;
                    }
                }

                .video-button-list {
                    display: flex;
                    gap: 20px;

                    @media screen and (max-width: 520px) {
                        & {
                            flex-direction: column;
                        }
                    }

                    li {
                        .video-button {
                            padding: 10px 50px;
                            border-radius: 50px;
                            color: var(--White);
                            display: flex;
                            align-items: center;
                            gap: 7px;

                            @media screen and (max-width: 520px) {
                                & {
                                    justify-content: center;
                                }
                            }

                            &.provider-hub {
                                background: var(--parkTextFour);
                            }

                            &.parent-hub {
                                background: var(--parkingTextThree);
                            }
                        }
                    }
                }
            }
        }

        .accordian-wrapper {
            padding-top: 50px;

            .accordian-sec-wrp {
                &>p {
                    margin-top: 0;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }

                .each-accordian-sec {
                    border: 2px solid var(--parkTextFour);
                    margin-bottom: 15px;
                    border-radius: 15px;

                    .MuiAccordionSummary-content {
                        span {
                            margin-right: 10px;

                            svg {
                                width: 20px;
                                height: 20px;
                            }
                        }

                        h4 {
                            margin: 0;
                            color: var(--parkTextFive);
                            font-weight: 500;
                        }

                        p {
                            margin: 0;
                            font-size: 12px;
                            color: var(--parkTextFive);
                            font-weight: 500;
                        }
                    }

                    .MuiAccordionSummary-expandIconWrapper {
                        svg {
                            path {
                                stroke: var(--parkTextFive);
                            }
                        }
                    }

                    .MuiCollapse-root {
                        ul {
                            li {
                                display: flex;
                                align-items: center;
                                gap: 5px;
                                font-size: 14px;
                                color: var(--parkTextFive);
                                font-weight: 500;
                                margin-bottom: 7px;

                                span {
                                    line-height: 1;
                                    width: 20px;
                                    height: 20px;

                                    svg {
                                        width: inherit;
                                        height: inherit;

                                        path {
                                            fill: var(--parkColorSix);
                                        }
                                    }

                                    &.parentIcon {
                                        svg {
                                            path {
                                                fill: none;
                                                stroke: var(--parkColorSix);
                                                stroke-width: 1.7px;
                                            }
                                        }
                                    }

                                    &.PalmIcon,
                                    &.PaymentsIcon,
                                    &.EmployeeIcon {
                                        svg {
                                            path {
                                                fill: none;
                                                stroke: var(--parkColorSix);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                padding-right: 30px;

                @media screen and (max-width: 991px) {
                    & {
                        padding-right: 0;
                        padding-bottom: 30px;
                    }
                }
            }

            .video-wrp {
                padding-left: 30px;

                @media screen and (max-width: 991px) {
                    & {
                        padding-left: 0;
                        padding-top: 30px;
                    }
                }

                span {
                    border-radius: 14px;
                    overflow: hidden;
                    display: block;
                    height: 100%;
                    width: 100%;
                }

                &::before {
                    content: '';
                    width: 5px;
                    height: 100%;
                    position: absolute;
                    left: -17.5px;
                    top: 0;
                    border-radius: 50px;
                    background: var(--parkLine);

                    @media screen and (max-width: 991px) {
                        & {
                            width: 100%;
                            height: 5px;
                            left: 0;
                        }
                    }
                }
            }
        }
    }

    .efficiency-popup {
        .efficiency-popup-con {
            background-color: #fff;
            max-width: 650px;
            margin: 0 auto;
            float: none;
            margin-top: 20px;
            border-radius: 14px;

            button {
                float: right;

                svg {
                    path {
                        fill: #8032A1;
                    }
                }
            }
        }
    }

    .plans-section-wrp {
        padding-bottom: 50px;

        .plans-header-wrp {
            text-align: center;

            h1 {
                color: var(--parkTextFour);
                margin: 0;
                font-family: var(--RedHatDisplay);
                font-style: normal;
                font-weight: 700;
                letter-spacing: 3px;

            }

            p {
                color: var(--parkTextFive);
                font-family: var(--Roboto);
                margin-bottom: 0;
            }
        }

        .plans-content-wrp {
            padding-top: 70px;

            @media screen and (max-width: 991px) {
                & {
                    .row {
                        &>div {
                            padding-bottom: 30px;
                        }
                    }
                }
            }

            .each-plan-box {
                border-radius: 24px;
                border: 0.5px solid var(--parkTextFour);
                background: var(--White);
                box-shadow: 0px 4px 4px 0px var(--ppShadow);
                padding: 25px;
                max-width: 300px;
                margin: 0 auto;
                float: none;
                display: flex;
                transform: scale(1);
                transition: 0.5s all;
                flex-direction: column;
                height: 100%;
                justify-content: space-between;



                .plans-img-sec {
                    h1 {
                        margin: 0;
                        color: var(--parkTextFour);
                        font-family: var(--RedHatDisplay);
                        font-style: normal;
                        font-weight: 700;
                        letter-spacing: 3px;
                        line-height: 1.3;
                        text-align: center;
                        font-size: 26px;
                        white-space: pre-line;
                    }

                    span {
                        padding-top: 10px;
                        padding-bottom: 10px;
                        display: block;
                    }
                }

                .plans-sub-wrp {
                    padding-bottom: 10px;

                    h3 {
                        font-weight: 300;
                        margin: 0;
                        text-align: center;
                        color: var(--parkingTextThree);

                        b {
                            display: block;
                        }
                    }
                }

                .plans-description-list {
                    ul {
                        li {
                            font-size: 15px;
                            color: var(--parkingTextThree);
                        }
                    }
                }

                .plans-benefit-sec {
                    padding-top: 20px;

                    h4 {
                        margin: 0;
                        text-align: center;
                        color: var(--parkTextFour);
                        font-weight: 600;
                    }

                    p {
                        text-align: center;
                        font-size: 13px;
                        background: var(--textBG);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }

                    .plans-btn {
                        padding: 10px;
                        background: var(--ppColorBG);
                        display: block;
                        text-align: center;
                        color: var(--White);
                        font-family: var(--RedHatDisplay);
                        border-radius: 4px;
                    }
                }

                &:hover {
                    transform: scale(1.01);
                    transition: 0.5s all;
                    background: linear-gradient(171deg, #8D1797 2%, rgba(138, 9, 86, 0.72) 93.07%);

                    * {
                        color: var(--White) !important;
                    }

                    .plans-benefit-sec {
                        p {
                            background: none !important;
                            color: var(--White);
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: var(--White);
                        }

                        .plans-btn {
                            background: var(--White);
                            color: var(--ppColor5) !important;
                        }
                    }

                }
            }
        }





    }

    .promotion-main-wrp {
        padding-top: 140px;
        background-image: url(../../assets/images/ppPlatform/promoBG.png);
        background-position: bottom;
        background-size: 100% calc(100% - 145px);
        background-repeat: no-repeat;

        &::after {
            content: '';
            position: absolute;
            width: 190px;
            height: 115px;
            right: 0;
            top: 140px;
            background-image: url(../../assets/images/ppPlatform/promoBG1.png);
            display: block;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        &::before {
            content: '';
            position: absolute;
            width: 130px;
            height: 115px;
            left: 0;
            bottom: 0px;
            background-image: url(../../assets/images/ppPlatform/promoBG2.png);
            display: block;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        .promotion-con-box {
            display: flex;
            padding-top: 50px;
            padding-bottom: 50px;

            @media screen and (max-width: 991px) {
                & {
                    flex-direction: column;
                }
            }

            .promotion-intro-sec {
                max-width: 580px;
                margin-top: -155px;

                @media screen and (max-width: 991px) {
                    & {
                        max-width: 100%;
                        display: flex;
                        align-items: flex-end;
                    }
                }

                @media screen and (max-width: 574px) {
                    flex-direction: column;
                }

                @media screen and (max-width: 574px) {
                    .intro-text {
                        margin: 0 auto;
                        padding-bottom: 40px;
                        padding-top: 20px;
                    }
                }

                span {
                    width: 365px;
                    display: block;
                    position: relative;
                    top: -25px;

                    @media screen and (max-width: 574px) {
                        margin: 0 auto;
                        width: 100%;
                        max-width: 300px;
                    }
                }

                h4 {
                    text-align: center;
                    margin: 0;
                    color: var(--ppColor6);
                    font-size: 28px;
                    font-weight: 700;
                    font-family: var(--RedHatDisplay);
                    letter-spacing: 2px;
                }

                p {
                    text-align: center;
                    color: var(--White);
                    font-weight: 700;
                    font-size: 22px;
                    max-width: 200px;
                    margin: 0 auto;

                    b {
                        color: var(--ppColor1);
                    }
                }
            }

            .promotion-con-wrp {
                padding-left: 50px;

                @media screen and (max-width: 767px) {
                    padding-left: 0;
                }

                h2 {
                    margin: 0;
                    color: var(--White);
                    font-family: var(--RedHatDisplay);
                    font-style: normal;
                    font-weight: 700;
                    letter-spacing: 3px;
                    line-height: 1.3;
                    font-size: 26px;
                    white-space: pre-line;
                }

                p {
                    text-align: left;
                    white-space: pre-line;
                    color: var(--White);
                }

                .quote-box {
                    display: flex;
                    gap: 24px;
                    padding-top: 20px;

                    .each-quate-box {
                        padding: 35px 30px;
                        border-radius: 24px;
                        // border: 0.5px solid var(--parkTextFive);
                        background: rgba(255, 255, 255, 0.15);

                        * {
                            color: var(--White);
                        }

                        span {
                            display: block;
                            width: 75px;
                        }

                        p {
                            font-weight: 300;
                            font-size: 14px;
                        }
                    }

                    @media screen and (max-width: 575px) {
                        & {
                            flex-direction: column;
                        }
                    }
                }
            }
        }
    }

    .testimonial-main-wrp {
        padding: 70px 0 50px;
        margin-top: 20px;
        background-image: url(../../assets/images/ppPlatform/Bgbott.png);
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;

        .testimonial-header-wrp {
            h1 {
                color: var(--parkTextFour);
                margin: 0;
                font-family: var(--RedHatDisplay);
                font-style: normal;
                font-weight: 700;
                letter-spacing: 3px;
                text-align: center;
            }
        }

        .testimonial-con-wrp {
            padding-bottom: 90px;
            padding-top: 50px;

            .padding-class {
                padding: 8px;
            }

            .each-testimonial-items {
                border-radius: 24px;
                background: var(--White);
                box-shadow: 0px 4px 4px 0px rgba(78, 5, 84, 0.50);
                padding: 24px;

                .rating {
                    display: flex;

                    span {
                        font-family: var(--RedHatDisplay);
                        letter-spacing: 2px;
                        color: var(--Black);
                    }

                    img {
                        display: block;
                        width: 25px;
                        height: 25px;
                    }

                    &+span {
                        display: block;
                        width: 35px;
                        height: 35px;
                    }
                }

                .auth-sec {
                    display: flex;
                    gap: 20px;

                    span {
                        width: 75px;
                        height: 75px;
                        display: block;
                    }

                    h2 {
                        font-size: 16px;
                        font-weight: 700;
                        margin: 0;

                        p {
                            margin: 0;
                            font-size: 13px;
                        }
                    }
                }
            }
        }

        .ppp-footer-box {
            max-width: 500px;
            margin: 0 auto;
            float: none;
            padding: 80px 20px;
            background-image: url(../../assets/images/ppPlatform/footBG.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            display: table;
            border-radius: 50px;

            span {
                display: block;
                width: 120px;
                height: 120px;
                margin: 0 auto;
            }

            h1 {
                font-size: 32px;
                color: var(--White);
                font-family: var(--RedHatDisplay);
                letter-spacing: 2px;
                font-weight: 700;
                text-align: center;
                line-height: 2;
            }

            button {
                display: block;
                padding: 15px 50px;
                text-align: center;
                background: rgba(255, 255, 255, 0.15);
                border: 3px solid var(--White);
                color: var(--White);
                border-radius: 50px;
                margin: 0 auto;
                margin-top: 40px;
            }
        }

        .copy-text {
            padding-top: 50px;

            p {
                text-align: right;
                color: #101010;
                font-size: 12px;
            }
        }
    }


    .ppContact-popup {
        max-width: calc(100% - 20px);
        margin: 0 auto;

        .pp-contact-head {
            display: flex;
            justify-content: space-between;
            height: 50px;
            margin-bottom: 30px;
            align-items: center;

            p {
                font-size: 24px;
                font-weight: 700;
                font-family: var(--Baloo);
                color: var(--parkTextFour);
                margin-top: 0;
                margin-bottom: 0px;
            }

            button {
                padding: 0;
                min-width: 0;
                min-height: 0;
                max-height: max-content;
                height: max-content;

                svg {
                    path {
                        fill: var(--Black);
                    }
                }
            }
        }

        .pp-contact-form {
            max-width: 550px;
            margin: 0 auto;
            float: none;
            background: #fff;
            padding: 30px;
            margin-top: 50px;
            border-radius: 15px;

            label.MuiInputLabel-formControl {
                font-size: 13px;
            }

            .MuiInputBase-root.MuiOutlinedInput-root {
                width: 100%;

            }

            .MuiOutlinedInput-notched {
                border-color: #98948A;
            }
        }

        .MuiFormControl-root.MuiTextField-root .MuiOutlinedInput-notchedOutline,
        .MuiFormControl-root .MuiOutlinedInput-notchedOutline {
            border-color: #98948A;
        }

        .pp-contact-frm {
            .login-btn {
                background: var(--parkingTextThree);
                border-radius: 50px;
            }

            .custom-textfield {
                textarea {
                    color: #6C6C6C;
                }
            }

            .app-text-input-container {
                .MuiFormControl-root {
                    .MuiInputBase-formControl {
                        input:-internal-autofill-selected {
                            background-color: #fff !important;
                        }

                        input:-webkit-autofill,
                        input:-webkit-autofill:hover,
                        input:-webkit-autofill:focus,
                        textarea:-webkit-autofill,
                        textarea:-webkit-autofill:hover,
                        textarea:-webkit-autofill:focus,
                        select:-webkit-autofill,
                        select:-webkit-autofill:hover,
                        select:-webkit-autofill:focus {
                            -webkit-text-fill-color: #6C6C6C !important;
                            caret-color: #6C6C6C !important;

                            .light-bg & {
                                -webkit-text-fill-color: #6C6C6C !important;
                                caret-color: #6C6C6C !important;
                                -webkit-box-shadow: 0 0 0 300px #6C6C6C inset !important;
                            }


                        }
                    }
                }
            }
        }

    }
}

.evolve-home {
    .ppContact-popup {
        max-width: calc(100% - 20px);
        margin: 0 auto;

        .pp-contact-head {
            display: flex;
            justify-content: space-between;
            height: 50px;
            margin-bottom: 30px;
            align-items: center;

            p {
                font-size: 24px;
                font-weight: 700;
                color: var(--parkTextFour);
                margin-top: 0;
                margin-bottom: 0px;

                @media screen and (max-width: 500px) {
                    & {
                        font-size: 20px;
                    }
                }
            }

            button {
                padding: 0;
                min-width: 0;
                min-height: 0;
                max-height: max-content;
                height: max-content;

                svg {
                    path {
                        fill: var(--Black);
                    }
                }
            }
        }

        .pp-contact-form {
            max-width: 550px;
            margin: 0 auto;
            float: none;
            background: #fff;
            padding: 30px;
            margin-top: 50px;
            border-radius: 15px;

            label.MuiInputLabel-formControl {
                font-size: 13px;
            }

            .MuiInputBase-root.MuiOutlinedInput-root {
                width: 100%;

            }

            .MuiOutlinedInput-notched {
                border-color: #98948A;
            }

            textarea {
                color: #6C6C6C;
            }

            .app-text-input-container {
                .MuiFormControl-root {
                    .MuiInputBase-formControl {

                        input:-internal-autofill-selected {
                            background-color: #fff !important;
                        }

                        input:-webkit-autofill,
                        input:-webkit-autofill:hover,
                        input:-webkit-autofill:focus,
                        textarea:-webkit-autofill,
                        textarea:-webkit-autofill:hover,
                        textarea:-webkit-autofill:focus,
                        select:-webkit-autofill,
                        select:-webkit-autofill:hover,
                        select:-webkit-autofill:focus {
                            -webkit-text-fill-color: #6C6C6C !important;
                            caret-color: #6C6C6C !important;

                            .light-bg & {
                                -webkit-text-fill-color: #6C6C6C !important;
                                caret-color: #6C6C6C !important;
                                -webkit-box-shadow: 0 0 0 300px #6C6C6C inset !important;
                            }


                        }
                    }
                }
            }
        }

        .MuiFormControl-root.MuiTextField-root .MuiOutlinedInput-notchedOutline,
        .MuiFormControl-root .MuiOutlinedInput-notchedOutline {
            border-color: #98948A;
        }

        .pp-contact-frm {
            .login-btn {
                background: var(--parkingTextThree);
                border-radius: 50px;
            }
        }
    }

    .sample-btn {
        transition: 0.5s all;
        transform: scale(1);
        line-height: 1.23;

        &:hover {
            transition: 0.5s all;
            transform: scale(1.05);
        }
    }

    .need-a-btn {
        background: linear-gradient(340.11deg, #FF6D55 7.71%, #ED3384 39.83%, #14448B 83.2%);
        color: var(--White) !important;
        font-family: var(--RedHatDisplay);
        line-height: 1.23;
        margin-left: 20px;
        transition: 0.5s all;
        transform: scale(1);

        @media screen and (max-width: 768px) {
            & {
                display: block;
                margin: 0 auto;
                margin-top: 15px;
            }
        }

        &:hover {
            background: #FFC444;
            transition: 0.5s all;
            transform: scale(1.05);
            color: var(--parkTextFive) !important;
        }
    }
}

.privacyPolicy-main-wrp {
    .termsAndCondition-wrp {
        background-image: url(../../assets/images/ppPlatform/Bgbott.png);
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;

        .banner-sec-wrp {
            padding: 70px 0 50px;
        }

        .parking-banner-con {
            display: flex;
            flex-direction: column;
            float: none;
            margin: 0 auto;
            max-width: 767px;
            padding: 50px 0 100px;
            text-align: center;

            h1 {
                font-family: var(--RedHatDisplay);
                font-weight: 700;
                letter-spacing: 3px;
                margin: 0;
                font-size: 28px;
                color: var(--parkTextFour);
                padding-bottom: 5px;
            }

            p {
                margin: 0;
            }
        }
    }

    .tab-sec-wrapper {
        display: flex;
        float: none;
        justify-content: center;
        margin: 0 auto;
        max-width: 600px;

        .tab-sec-wrp {
            .tab-body {
                background-color: transparent;
                padding: 0;
                border-radius: 0;

                .MuiTabPanel-root {
                    padding: 0;
                }
            }
        }

        .tab-header {
            display: none;
        }

        .each-accordian-sec {
            .MuiAccordionSummary-content {
                align-items: center;
                margin-top: 35px;
                margin-bottom: 20px;

                h4 {
                    color: var(--parkTextFour);
                    font-family: var(--RedHatDisplay);
                    font-weight: 600;
                    margin: 0;
                    padding-left: 10px;
                }

                span {
                    line-height: 0;
                }

                svg {
                    path {
                        fill: #933587;
                    }
                }
            }
        }



        .MuiAccordionDetails-root {
            h4 {
                margin: 10px 0;
                font-weight: 500;
                color: #113059;
            }

            ul {
                padding-left: 20px;
                padding-top: 5px;
                padding-bottom: 5px;
            }

            ul,
            ul li {
                list-style: disc;
                list-style-position: outside;
                font-size: 14px;
            }

            div {
                font-size: 14px;
            }
        }
    }

    .parking-foot {
        padding-top: 75px;
        padding-bottom: 20px;

        p {
            color: #101010;
            font-size: 12px;
            text-align: right;
        }
    }
}

.contact-link-wrapper {
    min-height: 100vh;
    // background-image: url(../../assets/images/ppPlatform/contactBG.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .bannerBG {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        filter: blur(8px);
        -webkit-filter: blur(8px);

        img {
            height: 100%;
        }
    }
}

.contactForm-link {
    .ppContact-popup.evolve-contactLink-box {
        & {
            max-width: calc(100% - 20px);
            margin: 0 auto;

            .pp-contact-head {
                display: flex;
                justify-content: space-between;
                height: 50px;
                margin-bottom: 30px;
                align-items: center;

                p {
                    font-size: 24px;
                    font-weight: 700;
                    color: var(--parkTextFour);
                    margin-top: 0;
                    margin-bottom: 0px;

                    @media screen and (max-width: 500px) {
                        & {
                            font-size: 20px;
                        }
                    }
                }

                button {
                    padding: 0;
                    min-width: 0;
                    min-height: 0;
                    max-height: max-content;
                    height: max-content;

                    svg {
                        path {
                            fill: var(--Black);
                        }
                    }
                }
            }

            .pp-contact-form {
                max-width: 550px;
                margin: 0 auto;
                float: none;
                background: #fff;
                padding: 30px;
                margin-top: 50px;
                border-radius: 15px;

                label.MuiInputLabel-formControl {
                    font-size: 13px;
                }

                .MuiInputBase-root.MuiOutlinedInput-root {
                    width: 100%;

                }

                .MuiOutlinedInput-notched {
                    border-color: #98948A;
                }

                textarea {
                    color: #6C6C6C;
                }

                .app-text-input-container {
                    .MuiFormControl-root {
                        .MuiInputBase-formControl {

                            input:-internal-autofill-selected {
                                background-color: #fff !important;
                            }

                            input:-webkit-autofill,
                            input:-webkit-autofill:hover,
                            input:-webkit-autofill:focus,
                            textarea:-webkit-autofill,
                            textarea:-webkit-autofill:hover,
                            textarea:-webkit-autofill:focus,
                            select:-webkit-autofill,
                            select:-webkit-autofill:hover,
                            select:-webkit-autofill:focus {
                                -webkit-text-fill-color: #6C6C6C !important;
                                caret-color: #6C6C6C !important;

                                .light-bg & {
                                    -webkit-text-fill-color: #6C6C6C !important;
                                    caret-color: #6C6C6C !important;
                                    -webkit-box-shadow: 0 0 0 300px #6C6C6C inset !important;
                                }


                            }
                        }
                    }
                }
            }

            .MuiFormControl-root.MuiTextField-root .MuiOutlinedInput-notchedOutline,
            .MuiFormControl-root .MuiOutlinedInput-notchedOutline {
                border-color: #98948A;
            }

            .pp-contact-frm {
                .login-btn {
                    background: var(--parkingTextThree);
                    border-radius: 50px;
                }
            }
        }
    }

    // #NotiflixNotifyWrap {
    //     display: none !important;
    // }
}