.Francisca-Services-wrp {
    .each-service-sec {
        display: block;
        text-align: center;
        max-width: 240px;
        width: 240px;
        padding-bottom: 35px;
        margin: 0 auto;
        float: none;

        .service-icon {
            width: 75px;
            display: block;
            margin: 0 auto;

            svg {
                width: 75px;
                height: 75px;
            }
        }

        .service-con-sec.f-wrp {
            width: 240px;
            height: 240px;
            background: #FFF0EC;
            border-radius: 100%;
            padding: 30px;

            h2 {
                margin-bottom: 0;
                font-size: 20px;
                font-family: var(--Baloo);
                font-weight: 700;
                text-transform: capitalize;
            }

            p {
                margin-bottom: 0;
                font-size: 14px;
            }
        }

        &.food-program {
            .service-icon {
                svg {
                    circle {
                        fill: var(--Green1C);
                    }
                    path{
                        fill: var(--Green1C);
                    }
                }
            }

            .service-con-sec {
                background: var(--GreenLight);

                h2 {
                    color: var(--Green1C);
                }

                p {
                    color: var(--Text36);
                }
            }
        }

        &.Medical-aid {
            .service-icon {
                svg {
                    circle {
                        fill: var(--Amaranth);
                    }
                }
            }

            .service-con-sec {
                background: var(--lytColor1);

                h2 {
                    color: var(--Amaranth);
                }
    
                p {
                    color: var(--Text36);
                }
            } 
        }

        &.playground {
            .service-icon {
                svg {
                    circle {
                        fill: var(--Cobalt);
                    }
                }
            }

            .service-con-sec {
                background: var(--lytColor2);

                h2 {
                    color: var(--Cobalt);
                }
    
                p {
                    color: var(--Text36);
                }
            }
        }

        &.subsidizedCare {
            .service-icon {
                svg {
                    circle {
                        fill: var(--Monza);
                    }
                }
            }

            .service-con-sec {
                background: var(--lytColor3);

                h2 {
                    color: var(--Monza);
                }
    
                p {
                    color: var(--Text36);
                }
            }           
        }        
    }

    .swiper-button-prev::after,
    .swiper-button-next::after {
        content: '';
        width: 25px;
        height: 50px;
        display: block;
        object-fit: contain;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0;
        transition: 0.5s all;
    }

    &:hover {

        .swiper-button-prev::after,
        .swiper-button-next::after {
            opacity: 1;
            transition: 0.5s all;
        }
    }

    .swiper-button-prev::after {
        background-image: url('../images/icons/slideLeft.svg');
    }

    .swiper-button-next::after {
        background-image: url('../images/icons/slideRight.svg');
    }
}