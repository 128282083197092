
.gallery-section-wrp.argentina {
    max-width: 90%;
    float: none !important;
    margin: 15px auto;
    .swiper.swiper-initialized{
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 50px;
    }
    .swiper-pagination-bullet-active{
        background: var(--Orange);
        width: var(--swiper-pagination-bullet-width,var(--swiper-pagination-bullet-size,12px));
        border-radius: 15px;
    }
    .swiper-button-prev::after,
    .swiper-button-next::after {
        content: '';
        width: 25px;
        height: 50px;
        display: block;
        object-fit: contain;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0;
        transition: 0.5s all;
    }

    &:hover {
        .swiper-button-prev::after,
        .swiper-button-next::after {
            opacity: 1;
            transition: 0.5s all;
        }
    }

    .swiper-button-prev::after {
        background-image: url('../images/icons/slideLeft.svg');
    }

    .swiper-button-next::after {
        background-image: url('../images/icons/slideRight.svg');
    }
}