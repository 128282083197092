.clock-in-daily{
    .info-head{
        color: #4C483E;
    }
    h1{
        color: #FF7940;
        font-weight: 700;
    }
    button.login-btn{
        background: #FF3D00;
        display: flex;
        align-items: center;
        font-size: 18px;
        svg{
            font-size: 24px;
        }
        &:hover{
            background: #626262 !important;   
        }
    }
    .clockIn-child-list{
        .MuiBox-root{
            .MuiFormControl-root{
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 0;
                // margin-top: 0;
                .MuiInputBase-formControl.MuiInputBase-root{
                    font-size: 18px;
                }
            }
        }
        
    }
    .MuiInputBase-root.MuiOutlinedInput-root {
        font-size: 18px;
    }
}