.notificationBell{
    .notification-header{
        display: flex;
        justify-content: space-between;
        padding: 5px 0px 5px 15px;
        align-items: center;
    }
    .notification-list{
        background: #0F0A01;
        padding: 10px;
        li{
            background: #211e1d;
            padding: 10px;
            border-radius: 20px;
            &:not(:last-child){
                margin-bottom: 10px;
            }
            span{
                font-size: 15px;
            }
            p{
                font-size: 12px;
            }
            *{
                color: #ffffff;
            }
        }
    }
    @media only screen and (min-width: 600px) {
        // .MuiPaper-root.MuiPaper-elevation,
        .notification-list{
            min-width: 375px !important;
            max-width: 375px !important;
            width: 100% !important;
        }
    }
    @media only screen and (max-width: 599px) {
        // .MuiPaper-root.MuiPaper-elevation,
        .notification-list{
            min-width: 100% !important;
            max-width: 100% !important;
            width: 100% !important;
        }
    }
}
.header-notification{    
    button{
        svg{
            path{
                color: #FF7940;
            }
        }
    }
}
.parentNotificationBell{
    .notification-list{
        background: #0F0A01;
        li{
            background: #211e1d;
            *{
                color: #ffffff;
            }
        }
    }
}

.providerNotificationBell{
    .notification-list{
        background: #919eab14;
        li{
            background: #ffffff;
            *{
                color: #0F0A01;
            }
        }
    }
}

.notification-btn{
    .MuiButtonBase-root.MuiIconButton-root{
        padding: 3px 0px 3px 3px;
    }
}