.tutorial-popup {
    display: inline-block;
    height: 25px;
    padding-left: 10px;

    .video-play {
        background-color: transparent;
        box-shadow: none;
        border: none;
        padding: 0;
        margin: 0;
        display: inline;
        height: 25px;

        svg {
            height: 25px;
            width: 25px;

            path {
                fill: var(--White);
            }
        }
    }
}

#video-tutorial {
    .MuiPaper-root.MuiPaper-elevation {
        width: 100%;
        height: auto;
        max-width: 100%;
        background-color: transparent;
        box-shadow: none;
        position: unset;
        overflow: unset;
        padding: 0px;
        margin-left: 15px;
        margin-right: 15px;
        display: flex;
        justify-content: center;

        .close-btn {
            position: absolute;
            right: 20px;
            top: 20px;
            border: none;
            background: transparent;
            cursor: pointer;
            padding: 0;

        }

        .video-main-wrapper {
            height: 100%;
        }

        .video-wrapper {
            // height: max-content;
            float: none;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            // min-height: 340px;
            min-height: 350px;
            @media only screen and (min-width: 991px) {
                &{
                    height: 100%;
                    width: 90%;
                    min-height: 475px;
                }
            }
            @media only screen and (max-width: 600px) {
                &{
                    min-height: 300px;
                }
            }
        }
    }
}