.project-main-footer{
    background-color: #0f0a01;
}

.copy-foot{
    padding: 20px 0;
    *{
        color: #FFF4EE;
        font-size: 12px;
        margin: 0;
    }
    .float-icon {
        display: block;
        width: 65px;
        position: fixed;
        right: 50px;
        z-index: 9999999999;
        bottom: 65px;
        &:hover{
            animation: rotateAnimation 25s linear infinite;
        }
    }
    @keyframes rotateAnimation {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(3600deg);
        }
    }
    .row{
        align-items: center;
    }
}
.social-list.f-wrp ul {
    display: flex;
    padding-left: 25px;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 0;
}

.social-list.f-wrp ul img {
    width: 20px;
    display: block;
}

@media (max-width: 575px) {
    .project-main-footer{
        background: #ffbe00;
    }
    .copy-foot{
        padding-top: 25px;
    }
    .copy-txt p{
        text-align: center;
    }
    .social-list.f-wrp{
        padding: 25px 0;
    }
    .social-list.f-wrp ul{
        justify-content: center;
    }

}