.access-denied-screen {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;

    svg {
        width: 150px;
        height: 150px;
    }
}