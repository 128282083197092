.TemplatesOne-Services-wrp {
    display: flex;
    width: 100%;
    max-width: 990px;
    margin: 0 auto;

    .each-service-sec {
        display: block;
        text-align: center;
        max-width: 225px;
        width: 225px;
        padding-bottom: 35px;
        margin: 0 auto;
        float: none;

        .service-icon {
            width: 75px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            height: 75px;
            border-radius: 100%;
            padding: 20px;
            margin-bottom: 10px;
            line-height: 0;
            span{
                line-height: 0;
            }
            svg {
                width: 100%;
                height: 100%;
                path{
                    width: 35px;
                    height: 35px;
                }
                circle {
                    fill-opacity: 1;
                    display: none;
                }
            }
        }

        .service-con-sec.f-wrp {
            width: 225px;
            height: 225px;
            border-radius: 100%;
            padding: 30px;

            h2 {
                margin-bottom: 0;
                font-family: var(--Baloo);
                font-weight: 700;
                text-transform: capitalize;
                font-size: 22px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                line-clamp: 1;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            p {
                margin-bottom: 0;
                font-size: 14px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 4;
                line-clamp: 4;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .swiper-wrapper {
        .swiper-slide {
            &.service1 {
                .each-service-sec {
                    .service-icon {
                        background-color: var(--serviceBg1);
                        --iconColor: var(--serviceClr1);
                        // svg {
                        //     circle {
                        //         fill: var(--serviceBg1);
                        //     }

                        //     path {
                        //         fill: var(--serviceClr1);
                        //     }
                        // }
                    }

                    .service-con-sec {
                        background: var(--serviceBg1);

                        h2 {
                            color: var(--serviceClr1);
                        }

                        p {
                            color: var(--Text36);
                        }
                    }
                }
            }

            &.service2 {
                .each-service-sec {
                    .service-icon {
                        background-color: var(--serviceBg2);
                        --iconColor: var(--serviceClr2);
                    }

                    .service-con-sec {
                        background: var(--serviceBg2);

                        h2 {
                            color: var(--serviceClr2);
                        }

                        p {
                            color: var(--Text36);
                        }
                    }
                }
            }

            &.service3 {
                .each-service-sec {
                    .service-icon {
                        background-color: var(--serviceBg3);
                        --iconColor: var(--serviceClr3);
                       
                    }

                    .service-con-sec {
                        background: var(--serviceBg3);

                        h2 {
                            color: var(--serviceClr3);
                        }

                        p {
                            color: var(--Text36);
                        }
                    }
                }
            }

            &.service4 {
                .each-service-sec {
                    .service-icon {
                        background-color: var(--serviceBg4);
                        --iconColor: var(--serviceClr4);
                    }

                    .service-con-sec {
                        background: var(--serviceBg4);

                        h2 {
                            color: var(--serviceClr4);
                        }

                        p {
                            color: var(--Text36);
                        }
                    }
                }
            }
        }
    }

    .swiper {

        .swiper-button-prev,
        .swiper-button-next {
            padding: 0;
            background: transparent;
            border: none;
            opacity: 0;
            transition: 0.5s all;

            svg {
                path {
                    fill: var(--primary);
                }
            }

        }

        &:hover {

            .swiper-button-prev,
            .swiper-button-next {
                opacity: 1;
                transition: 0.5s all;
            }
        }
    }


    .swiper-button-prev::after,
    .swiper-button-next::after {
        display: none;
    }

    &:hover {

        .swiper-button-prev::after,
        .swiper-button-next::after {
            opacity: 1;
            transition: 0.5s all;
        }
    }

    .swiper-button-prev::after {
        background-image: url('../images/icons/slideLeft.svg');
    }

    .swiper-button-next::after {
        background-image: url('../images/icons/slideRight.svg');
    }
}