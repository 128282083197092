.nap-slider-wrp {
    width: calc(100% + 24px);

    .form_time_slider {
        padding-left: 24px;
        padding-right: 24px;

        @media only screen and (max-width: 1200px) {
            & {
                padding-right: 0px;
            }
        }

        @media only screen and (max-width: 400px) {
            & {
                padding-left: 0;
                width: calc(100% + 24px);
            }
        }
    }

    .swiper-button-next,
    .swiper-rtl .swiper-button-prev {
        right: var(--swiper-navigation-sides-offset, 0);
        left: auto;
    }

    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
        left: var(--swiper-navigation-sides-offset, 0px);
        right: auto;
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
        background-size: contain;
        background-repeat: no-repeat;
        font-size: 0;
        width: 20px;
        height: 27px;
    }

    .swiper-button-next:after {
        background-image: url('../../../assets/images/icons/sliderRight.png');
    }

    .swiper-button-prev:after {
        background-image: url('../../../assets/images/icons/sliderLeft.png');
    }

    .swiper-button-next.swiper-button-disabled,
    .swiper-button-prev.swiper-button-disabled {
        opacity: 0;
    }

    .time-list-wrp {
        @media only screen and (max-width: 1200px) {
            & {
                table {
                    td {
                        width: auto !important;

                        .app-text-input-container {
                            .MuiFormControl-root .MuiInputBase-formControl input {
                                padding: 5px 3px;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }

        @media only screen and (max-width: 991px) {
            & {
                table {
                    width: 100%;
                }
            }
        }
    }
}

.time-list-wrp {
    .MuiButtonBase-root.MuiCheckbox-root {
        svg {
            width: 30px;
            height: 30px;
        }
    }

    input {
        padding-left: 10px;
    }

    .MuiInputBase-adornedEnd {
        padding-right: 10px;

        @media only screen and (max-width: 500px) {
            & {
                padding-right: 5px;
            }
        }

        .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
            .MuiTypography-root {
                font-size: 11px;

                @media only screen and (max-width: 500px) {
                    & {
                        font-size: 8px;
                    }
                }
            }
        }
    }

    th {
        text-align: left;
        color: #5D5848;
    }
}

.add-list-time.f-wrp {
    display: flex;
    align-items: flex-end;

    .btn-wrp {
        .fill-btn {
            background: #FF3D00;
            color: #fff;
            padding: 6px 30px;
            font-size: 22px;
            margin-left: 20px;
            margin-bottom: 4px;
            border-radius: 4px;
            border: none;
            cursor: pointer;
        }
    }
}

.pln-btn-style {
    display: flex;

    .login-btn {
        background: transparent;
        border-radius: 50px;
        border: 2px solid #FF7940;
        color: #FF7940;
        white-space: nowrap;
    }
}