.argentinaTemplate {
    .service-section-wrp {
        display: flex;
        flex-wrap: wrap;
        padding-top: 50px;
        .each-service-sec {
            display: flex;
            padding-bottom: 35px;
            color: var(--Text82);
            width: 50%;
            @media only screen and (max-width: 650px) {
                & {
                    width: 100%;
                }
            }
            .service-icon {
                width: 50px;
                display: block;
                height: 50px;
            }
            .service-con-sec{
                max-width: calc(100% - 50px);
                padding-left: 25px;
            }
            h3 {
                margin-bottom: 0;
                font-family: var(--Wingdings);
                margin-top: 0;
                color: var(--GreenB4);
            }

            p {
                margin-bottom: 0;
                margin-top: 5px;
                font-size: 14px;
                color: var(--Text82);
            }
        }


    }
}