.parent-portal {
    background: #211e1d;
    min-height: 100vh;
    max-width: 100vw;

    .portal-main-structure {
        min-height: 100vh;
        max-width: 1920px;
        margin: 0 auto;

        .mob-drawer-btn {
            display: none;
        }

        .closeBackBtn {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: #0000008a;
            display: none;

            @media only screen and (max-width: 600px) {
                display: block;
            }
        }

        .app-portal-header {
            background: #211e1d;
            box-shadow: none;

            .MuiToolbar-root.MuiToolbar-regular {
                min-height: auto;
                padding-left: 0;
                padding-right: 0;
                padding-bottom: 24px;
                justify-content: space-between;
            }

            .MuiTypography-root {
                color: #FF7940;
                font-family: 'Andika', sans-serif;
                display: flex;
                align-items: center;
            }

            @media only screen and (min-width: 600px) {
                .mobile-menu-sec {
                    display: none;
                }
            }

            @media only screen and (max-width: 600px) {
                .mob-drawer-btn {
                    display: block;
                    min-height: auto;
                }

                .parent-drawer {
                    display: none;
                }

            }
        }

        .panel-main-content {
            .grid-sec-wrp {
                border-radius: 24px;
                background: #0F0A01;
                padding: 0px 24px 24px 0px;
                width: 100%;
                margin: 0;
            }
        }

        @media only screen and (max-width: 991px) {
            .MuiDrawer-root.MuiDrawer-docked.parent-drawer {
                position: absolute;
                left: 0;
                top: 0;
                z-index: 9;
                height: 100%;
            }
        }

        @media only screen and (max-width: 600px) {
            .MuiDrawer-root.MuiDrawer-docked.parent-drawer {
                z-index: 9991;
            }
        }
    }

    .parent-drawer {
        &>.MuiDrawer-paper {
            background: #0F0A01;
            justify-content: space-between;

            &::-webkit-scrollbar {
                width: 0px;
            }

            &::-webkit-scrollbar-track {
                background: #0F0A01;
            }

            &::-webkit-scrollbar-thumb {
                background: #FF7940;
                border-radius: 50px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #FF7940;
            }

            .MuiDivider-fullWidth {
                width: calc(100% - 30px);
                margin: 0 auto;
                border-color: #FFF4EE;
            }
        }

        .logo-sec-wrp {
            min-height: 120px;
            transition: 0.5s all;

            .logo-img {
                display: block;
                img{
                    width: 90%;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    margin: 0 auto;
                }
            }
        }

        ul {
            * {
                color: #B4AC9C;
                transition: 0.5s all;
                white-space: initial;
            }

            li {

                .listButton {
                    padding: 15px 20px;
                    position: relative;

                    .MobCloseBtn {
                        display: none;

                        @media only screen and (max-width: 991px) {
                            & {
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 100%;
                                height: 100%;
                                display: block;
                            }
                        }
                    }

                    .MuiListItemIcon-root {
                        span {
                            display: flex;

                            svg {
                                path {
                                    fill: #B4AC9C;
                                }

                                rect {
                                    fill: #FFFFFF4D;
                                }
                            }
                        }
                    }

                    &.logout {
                        span {
                            font-weight: 700;

                            svg {
                                path {
                                    fill: none;
                                    stroke: #B4AC9C;
                                }
                            }
                        }
                    }

                    &:hover {
                        * {
                            color: #FFF4EE;
                            transition: 0.5s all;
                        }

                        .MuiListItemIcon-root {
                            span {
                                svg {
                                    path {
                                        fill: #FF7940;
                                    }

                                    rect {
                                        fill: #FFFFFF4D;
                                    }
                                }
                            }
                        }

                        &.logout {
                            span {
                                svg {
                                    path {
                                        fill: none;
                                        stroke: #FF7940;
                                    }
                                }
                            }
                        }
                    }
                }

                &:hover,
                &.active {
                    * {
                        color: #FFF4EE;
                        transition: 0.5s all;
                    }

                    .listButton {
                        background-color: rgba(145, 158, 171, 0.08);
                    }

                    .MuiListItemIcon-root {
                        span {
                            svg {
                                path {
                                    fill: #FF7940;
                                }

                                rect {
                                    fill: #FFFFFF4D;
                                }
                            }
                        }
                    }

                    &.logout {
                        span {
                            svg {
                                path {
                                    fill: none;
                                    stroke: #FF7940;
                                }
                            }
                        }
                    }

                    &.tutorials {
                        span {
                            svg {
                                path {
                                    fill: #FF7940 !important;
                                }

                                circle {
                                    stroke: #FF7940 !important;
                                }
                            }
                        }
                    }
                }

                &::after {
                    content: "";
                    width: 6px;
                    height: calc(100% - 10px);
                    position: absolute;
                    top: 5px;
                    right: 0;
                    background: #FF7940;
                    border-radius: 5px 0 0 5px;
                    opacity: 0;
                    transition: 0.5s all;
                }

                &:hover:after,
                &.active:after {
                    opacity: 1;
                    transition: 0.5s all;
                }

                .listButton {


                    &.message,
                    &.payment,
                    &.inbox {
                        .MuiListItemIcon-root {
                            span {
                                svg {
                                    path {
                                        fill: none !important;
                                        stroke: #B4AC9C;
                                    }

                                    rect {
                                        fill: #B4AC9C !important;
                                    }
                                }
                            }
                        }

                        &:hover {
                            .MuiListItemIcon-root {
                                span {
                                    svg {
                                        path {
                                            fill: none !important;
                                            stroke: #FF7940 !important;
                                        }

                                        rect {
                                            fill: #FFFFFF4D !important;
                                        }
                                    }
                                }
                            }

                        }
                    }

                    &.tutorials {
                        .MuiListItemIcon-root {
                            span {
                                svg {
                                    path {
                                        fill: #B4AC9C !important;
                                    }

                                    circle {
                                        stroke: #B4AC9C !important;
                                    }
                                }
                            }
                        }

                        &:hover {
                            .MuiListItemIcon-root {
                                span {
                                    svg {
                                        path {
                                            fill: #FF7940 !important;
                                        }

                                        circle {
                                            stroke: #FF7940 !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.active{
                    .listButton.tutorials {
                        .MuiListItemIcon-root {
                            span {
                                svg {
                                    path {
                                        fill: #FF7940 !important;
                                    }
    
                                    circle {
                                        stroke: #FF7940 !important;
                                    }
                                }
                            }
                        }
                        
                    }
                }
            }

        }

        &.MuiDrawer-docked {
            .logo-sec-wrp {
                min-height: 60px;
                transition: 0.5s all;
            }
        }

        @media only screen and (max-width: 900px) {
            & {
                &.drawer-close {
                    &+.closeBackBtn {
                        display: none;
                    }
                }

                &.drawer-open {
                    &+.closeBackBtn {
                        display: block;
                        z-index: 999;
                    }
                }
            }
        }
    }

    .panel-main-body {
        width: calc(100% - 240px);

        @media only screen and (min-width: 600px) and (max-width: 991px) {
            margin-left: 65px;
        }

        @media only screen and (max-width: 600px) {
            padding: 0 15px 24px;

            .panel-main-header {
                margin-bottom: 20px;
                position: sticky;
                top: 0;
                z-index: 9;

                .MuiToolbar-root.MuiToolbar-gutters {
                    padding: 15px 24px;
                    padding-left: 50px;
                    position: relative;
                    left: -15px;
                    background: #0F0A01;
                    width: calc(100% + 30px);

                    .MuiTypography-root {
                        width: 100%;
                        text-align: center;
                    }

                    .left-sec {
                        position: absolute;
                        left: 0;
                        top: unset;
                    }
                }
            }
        }
    }


    .tab-sec-wrp.pay-table {
        * {
            color: #FFF4EE;
        }

        .MuiDataGrid-root.MuiDataGrid-withBorderColor {
            border-color: transparent;
        }

        .app-grid-tool-bar {
            display: none;
        }

        .MuiDataGrid-columnHeaders,
        .MuiDataGrid-footerContainer {
            border-color: transparent;
        }
    }

    .tab-body {
        * {
            color: #FFF4EE;
        }

        .MuiDataGrid-root.MuiDataGrid-withBorderColor {
            border-color: transparent;
        }

        .app-grid-tool-bar {
            display: none;
        }

        .MuiDataGrid-columnHeaders,
        .MuiDataGrid-footerContainer {
            border-color: transparent;
        }
    }

    .app-table {
        .MuiDataGrid-columnHeaders {
            background: #FFFFFF1A;
            max-height: 55px !important;
            min-height: 55px !important;
            border-radius: 10px;
            margin-bottom: 20px;
        }

        .MuiDataGrid-columnHeadersInner {
            .MuiDataGrid-columnHeader:nth-child(1) {
                padding-left: 20px;
            }
        }

        .MuiDataGrid-virtualScrollerRenderZone {
            .MuiDataGrid-row {
                .MuiDataGrid-cell:nth-child(1) {
                    padding-left: 20px;
                }
            }
        }

        .MuiDataGrid-virtualScroller {
            &::-webkit-scrollbar {
                height: 3px;
            }

            &::-webkit-scrollbar-track {
                background: #fff;
            }

            &::-webkit-scrollbar-thumb {
                background: #B4AC9C;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #B4AC9C;
            }
        }
    }

    @media only screen and (max-width: 991px) {
        .tab-sec-wrp {
            & {
                padding: 5px;
                background: #0F0A01;
            }

            .tab-header {
                .MuiTabs-scroller {
                    border-color: #98948A;
                    background-color: #0F0A01;
                }

                button {
                    &.Mui-selected {
                        color: #FF7940;
                    }
                }

                .MuiTabs-indicator {
                    background-color: #FF7940;
                }
            }
        }
    }

}