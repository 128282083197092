.franciscaTemplate {
    &.project-main-footer {
        background: var(--White);

        .logo-wrp {
            img {
                width: 75px;
            }
        }
    }

    .copy-foot {
        padding: 10px 0;

        p {
            color: var(--Glaucous);
            font-size: 14px;
            margin: 0;
        }

        .row {
            align-items: center;
        }

        .dev-blk {

            p,
            span {
                color: var(--Glaucous);
            }
        }
    }

    .social-list.f-wrp ul {
        display: flex;
        padding-left: 25px;
        gap: 10px;
        flex-wrap: wrap;
        justify-content: flex-end;
        padding: 0;

        li {
            span {
                svg {
                    circle {
                        stroke: var(--Green1C);
                        fill: var(--GreenLight);
                    }

                    path {
                        stroke: var(--Green1C);
                    }
                }
            }
        }

        li * {
            font-size: 30px;
        }
    }

    .social-list.f-wrp ul img {
        width: 45px;
        display: block;
    }

    @media (max-width: 575px) {
        .copy-foot {
            padding-top: 25px;
        }

        .copy-txt p {
            text-align: center;
        }

        .social-list.f-wrp {
            padding: 25px 0;
        }

        .social-list.f-wrp ul {
            justify-content: center;
        }
    }
}