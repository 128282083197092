.TemplatesTwoHeader {
    background-color: var(--White);
    // position: absolute;
    top: 0;
    left: 0;
    z-index: 9;

    .main-header {
        display: flex;
        justify-content: space-between;
        padding: 5px 0;

        .main-logo {
            display: block;
            height: 60px;
            float: left;
            img{
                object-fit: contain;
                object-position: center;
                width: 100%;
                height: 100%;
            }
        }
    }

    .burger-prof-sec {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .language-box {
            button {
                svg {
                    width: 20px;
                    display: block;

                    path {
                        stroke: var(--petrol);
                    }
                }
            }

            &+.f-wrp {
                display: flex;
                background: var(--primary);
                margin-left: 40px;
                padding: 7px 15px;
                align-items: center;
                border-radius: 50px;

                .burger-icon {
                    margin-left: 0;
                    margin-right: 0;

                    svg {
                        path {
                            stroke: var(--White);
                        }
                    }
                    
                }
                .burger-icon > svg:nth-child(1){
                    path {
                        stroke: var(--White);
                        fill: var(--White);
                    }
                    rect{
                        fill: var(--friendlyLightBG)
                    }
                }
                .burger-icon > svg:nth-child(2){
                    path {
                        fill: var(--White);
                    }
    
                    rect {
                        fill: var(--White);
                        fill-opacity: 0.3;
                    }
                }

                .profile-sec-box {
                    svg {
                        path {
                            fill: var(--White);
                        }

                        rect {
                            fill: var(--White);
                            fill-opacity: 0.3;
                        }
                    }
                }
            }
        }

        .profile-sec-box {
            a {
                svg {
                    width: 23px;
                    display: block;
                    height: 100%;
                    path {
                        fill: var(--White);
                    }
    
                    rect {
                        fill: var(--White);
                        fill-opacity: 0.3;
                    }
                }
            }
        }
    }
}
.TemplateTwo {
    #fade-menu{
        .MuiMenu-paper{
            .MuiMenu-list{
                li{
                    a{
                        button{
                            .menu-icon{
                                & > svg:nth-child(1){
                                    path {
                                        stroke: var(--primary);
                                        fill: var(--primary);
                                    }
                                    rect{
                                        fill: var(--primary);
                                        fill-opacity: 0.3;
                                    }
                                }
                                & > svg:nth-child(2){
                                    path {
                                        fill: var(--primary);
                                    }
                    
                                    rect {
                                        fill: var(--primary);
                                        fill-opacity: 0.3;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}