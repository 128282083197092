.privacy-sec-wrp{
    background: #0F0A01;
    border-radius: 24px;
    padding: 24px;
    *{
        color: #fff;
    }
    h3{
        color: #FF7940;
        font-weight: 700;
        font-size: 18px;
        display: flex;
        align-items: center;
        margin: 0;
        margin-bottom: 10px;
        svg{
            margin-right: 7px;
        }
        .color-cls {
            path{
                stroke: #FF7940;
            }
        }
    }
    p{
        font-size: 14px;
        margin: 0;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        svg{
            margin-right: 7px;
        }
    }
    ul{
        padding-left: 20px;
        list-style-type: disc;
        li{
            list-style-type: disc;
            font-size: 12px;
            margin-bottom: 10px;
            display: list-item;
        }
    }
    .privacy-con-wrp{
        padding-top: 55px;
    }
}