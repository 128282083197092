.TemplatesOne-approach-wrp {
    .row {
        &>div {
            margin-bottom: 30px;
        }
        @media only screen and (min-width: 992px) {
            & {
                justify-content: center;
            }
        }
    }

    .each-approach-sec {
        display: flex;
        flex-direction: column;
        height: 100%;

        .approach-con-sec {
            padding: 0;
            background: var(--primaryLightShade);
            // border: 2px solid var(--primaryLightShade);
            border-radius: 12px 12px 0 0;
            margin: 0 auto;
            float: none;
            margin-bottom: 0px;
            max-width: 325px;
            height: 100%;

            .fee-sec-icon {
                width: 100%;
                height: 150px;
                display: block;
                border-radius: 12px;
                overflow: hidden;
                margin: 0 auto;
                border: none;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }

            h2 {
                text-align: center;
                font-family: var(--Baloo);
                font-weight: 700;
                margin: 0;
                margin-top: 5px;
                color: var(--Petrol);
            }

            p {
                text-align: center;
                color: var(--Text36);
                margin: 0;
                margin-top: 5px;
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        .approach-basic-sec {
            padding: 25px;
            background: var(--primaryLightShade);
            border-radius: 0 0 12px 12px;
            max-width: 325px;
            margin: 0 auto;
            float: none;

            .trip-grid {
                display: flex;

                div {
                    flex: 1;
                    margin-right: 5px;

                    span {
                        color: var(--Text36);
                    }
                }

                div:last-child {
                    max-width: 33.33%;
                    margin-right: 0px;
                }
                div:nth-child(1) {
                    border-top: 5px solid var(--ageColor);

                    b {
                        color: var(--ageColor);
                    }
                }

                div:nth-child(2) {
                    border-top: 5px solid var(--daysColor);

                    b {
                        color: var(--daysColor);
                    }
                }

                div:last-child {
                    border-top: 5px solid var(--hoursColor);

                    b {
                        color: var(--hoursColor);
                    }
                }

                span {
                    b {
                        display: block;
                    }
                }
            }
        }
    }
}