.parents-main-wrp {
    padding: 0;

    .table-sec-wrp {
        padding: 24px;
        background-color: #fff;
        border-radius: 24px;

        .MuiDataGrid-root {
            border-color: transparent;
        }

    }

    .child-list-wrp {
        margin-bottom: 24px;
        @media only screen and (max-width: 600px) {
            &{
                margin-top: 0 !important;
            }
        }
        .panel-main-header {
            header {
                .MuiToolbar-root.MuiToolbar-regular {
                    padding-bottom: 0;
                    @media only screen and (max-width: 600px) {
                        &{
                            padding-bottom: 5px;
                            padding-top: 15px;
                        }
                    }
                }
            }

            &+span {
                color: #0F0A01;
                font-family: 'Andika', sans-serif;
            }
        }
        button.MuiButtonBase-root[aria-label='Go Back'] {
            font-size: 15px;
            padding: 0;
            margin-bottom: 20px;
            color: #0F0A01;

            svg {
                width: 16px;
                height: 12px;
            }
        }
    }
}

.panel-table-sec {
    .app-grid-tool-bar {
        justify-content: space-between;
        flex-wrap: nowrap;
        .MuiFormControl-root{
            max-width: 200px;
        }
    }
    .MuiDataGrid-root.MuiDataGrid-autoHeight{
        border-color: transparent;
    }
}
.PC-Table{
    .app-table {
        @media only screen and (max-width: 600px) {
            &{
                height: 0;
                overflow: hidden;
            }
        }
        @media only screen and (min-width: 601px) {
            &{
                display: flex;
            }
        }
    }
    
}
.mob-table-wrp {
    &.f-wrp{
        display: none;
    }
    .empty-placeholder.f-wrp {
        height: calc(100vh - 225px);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        .placeholder-con{
            img{
                width: 100%;
                max-width: 250px;
                margin: 0 auto;
            }
            h4{
                margin-bottom: 0;
                font-weight: 700;
                color: #FF7940;
            }
            p{
                color: #7C8B9D;
                margin-bottom: 0;
                font-weight: 400;
                font-size: 14px;
            }
        }
    }
    
    @media only screen and (max-width: 600px) {
        &.f-wrp{
            display: block;
        }
    }
    &>ul {
        &>li {
            display: flex;
            align-items: center;
            position: relative;

            &:not(:last-child) {
                border-bottom: 1px solid #98948A;
            }

            .each-sec-box {
                padding: 10px;
                width: 100%;

                h4 {
                    margin: 0;
                    font-weight: 700;
                    font-size: 15px;
                }

                span {
                    font-size: 13px;
                }
                p{
                    margin: 0;
                    font-size: 13px;
                }
                .flex-wrp{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }
                .status{
                    height: 25px;
                    font-size: 12px;
                    width: max-content;
                }
                &:nth-child(1) {
                    width: max-content;
                }

                &:nth-child(3) {
                    width: max-content;
                }

                .btn-wrp {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    position: relative;
                    z-index: 1;
                    button {
                        padding: 0;
                        background: transparent;
                        border: none;
                        min-width: 2px;
                        cursor: pointer;
                        &.formIcon{
                            svg{
                                path{
                                    fill: #FF7940;
                                }
                            }
                        }
                    }
                }
                .status-with-btn{
                    position: relative;
                    z-index: 1;
                    button.MuiIconButton-root{
                        position: absolute;
                        left: 0;
                        top: 0;
                        padding: 0;
                    }
                }
                .profile-img-sec{
                    span{
                        width: 50px;
                        height: 50px;
                        display: block;
                        border-radius: 100%;
                    }
                }
            }
            .full-li-btn{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: block;
            }
        }
    }

    .completed,
    .active {
        min-width: 80px;
        // height: 30px;
        border-radius: 6px;
        display: inline-block;
        text-align: center;
        line-height: 1.8;

        svg {
            width: 17px;
            height: 17px;
            margin-right: 2px;
        }
    }

    .completed {
        color: #FFBA0A !important;
        background: #FFF7E2 !important;
    }

    .active {
        background: #EDF7ED !important;
        color: #1FAF38 !important;
    }


    &.childView {
        ul {
            li {
                .each-sec-box {
                    &:nth-child(1) {
                        border-right: 1px solid #98948A;
                        margin-bottom: 10px;
                        padding-top: 0;
                        padding-bottom: 0;
                        text-align: center;

                        h4 {
                            color: #0F0A01;
                            &:nth-child(1) {
                                font-weight: 900;
                            }
                            &:nth-child(2) {
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }
    }
}