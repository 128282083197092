
.ParentDetail-popup {
    max-width: calc(100% - 20px);
    margin: 0 auto;

    .pp-contact-head {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        align-items: center;
        

        p {
            font-size: 20px;
            font-weight: 700;
            font-family: var(--Andika);
            color: #FF7940;
            margin-top: 0;
            margin-bottom: 0px;

            @media screen and (max-width: 500px) {
                & {
                    font-size: 18px;
                }
            }
        }

        button {
            padding: 0;
            min-width: 0;
            min-height: 0;
            max-height: max-content;
            height: max-content;

            svg {
                path {
                    fill: var(--Black);
                }
            }
        }
    }

    .pp-contact-form {
        max-width: 550px;
        margin: 0 auto;
        float: none;
        background: #fff;
        padding: 30px;
        margin-top: 50px;
        border-radius: 15px;
        @media screen and (max-width: 500px) {
            &{
                padding: 20px;
            }
        }

        label.MuiInputLabel-formControl {
            font-size: 13px;
        }

        .MuiInputBase-root.MuiOutlinedInput-root {
            width: 100%;

        }

        .MuiOutlinedInput-notched {
            border-color: #98948A;
        }


        .app-text-input-container {
            .MuiFormControl-root {
                .MuiInputBase-formControl {
                    input:-internal-autofill-selected {
                        background-color: #fff !important;
                    }
        
                    input:-webkit-autofill,
                    input:-webkit-autofill:hover,
                    input:-webkit-autofill:focus,
                    textarea:-webkit-autofill,
                    textarea:-webkit-autofill:hover,
                    textarea:-webkit-autofill:focus,
                    select:-webkit-autofill,
                    select:-webkit-autofill:hover,
                    select:-webkit-autofill:focus {
                        -webkit-text-fill-color: #6C6C6C !important;
                        caret-color: #6C6C6C !important;
        
                        .light-bg &{
                            -webkit-text-fill-color: #6C6C6C !important;
                            caret-color: #6C6C6C !important;
                            -webkit-box-shadow: 0 0 0 300px #6C6C6C inset !important;
                        }
        
        
                    }
                }
            }
        }

        .phoneWithCode-box{
            display: flex;
            .MuiBox-root{
                .MuiFormControl-root{
                    margin: 0;
                    #label-selectCountryCode{
                        transform: translate(14px, 12px) scale(1);
                        &.MuiInputLabel-shrink{
                            transform: translate(14px, -9px) scale(0.75);
                        }
                    }
                    .MuiInputBase-root{
                        height: 45px;
                        font-size: 12px;
                        width: 130px;
                        @media screen and (max-width: 600px) {
                            &{
                                width: 110px;
                            }
                        }
                    }
                }
            }
            .app-text-input-container {
                width: 100%;
            }
        }

    }

    .MuiFormControl-root.MuiTextField-root .MuiOutlinedInput-notchedOutline,
    .MuiFormControl-root .MuiOutlinedInput-notchedOutline {
        border-color: #98948A;
    }

    .pp-contact-frm {
        .login-btn {
            background: var(--parkingTextThree);
            border-radius: 50px;
        }
    }
}