
.main-head-wrp{
    background-color: #0f0a01;
    // position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    .burger-prof-sec{
        display: flex;
        justify-content: flex-end;
        height: 60px;   
        align-items: center;
        .language-box{
            a{
                svg{
                    width: 20px;
                    display: block;
                }
            }
        }
        .profile-sec-box{
            a{
                svg{
                    width: 23px;
                    display: block;
                    height: 100%;
                }
            }
        }
    }
}