.argentina-classes-wrp {
    .row {
        padding-bottom: 30px;
    }

    .each-classes-sec {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        @media only screen and (max-width: 570px) {
            & {
                flex-direction: column;
            }
        }
        span {
            flex: 1;
            display: block;
            @media only screen and (max-width: 570px) {
                & {
                    flex: none;
                    width: 100%;
                }
            }
            svg {
                width: 40px;
                height: 40px;
            }
        }

        .class-basic-sec {
            flex: 1;
            padding: 15px;
            @media only screen and (max-width: 570px) {
                & {
                    flex: none;
                    width: 100%;
                }
            }
            p {
                padding: 0;
                color: var(--Text82);
                margin-bottom: 15px;
            }

            h1 {
                color: var(--GreenB4);
                margin: 0;
            }

            .price {
                color: var(--OrangeF6);
                font-size: 12px;
                b {
                    font-size: 16px
                }
            }
        }

        p {
            margin: 0;
            padding-left: 15px;
            text-align: left;
            color: var(--Text82);
            max-width: calc(100% - 40px);
        }
    }

    .class-detail-sec {

        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;

        p {
            padding-left: 10px;
            margin: 0;
            margin-bottom: 5px;
            margin-top: 5px;
            color: var(--Text82);
        }

        b {
            margin-bottom: 10px;
            color: var(--Text82);
        }
    }

}