.TemplatesOne-teachers-wrp {
    .row {
        &>div {
            margin-bottom: 30px;
        }

        @media only screen and (min-width: 992px) {
            & {
                justify-content: center;
            }
        }
    }

    .each-teachers-sec {
        .teachers-img-sec {
            width: 100%;
            height: 100%;
            display: block;
            margin: 0 auto;
            max-width: 230px;
            float: none;

            .fee-sec-icon {
                width: 100%;
                height: 400px;
                display: block;
                border-radius: 100%;
                overflow: hidden;
                max-width: 230px;
                margin: 0 auto;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                }
            }

            .title-box {
                max-width: 120px;
                height: 120px;
                border-radius: 100%;
                position: absolute;
                right: -20px;
                bottom: -20px;
                padding: 5px;
                background: var(--white);
                background: var(--primary);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                overflow: hidden;

                .f-wrp {
                    width: 107px;
                    height: 107px;
                    background: var(--white);
                    border-radius: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 12px;
                }

                h2 {
                    font-family: var(--Baloo);
                    color: var(--Text36);
                    font-weight: 700;
                    text-align: center;
                    font-size: 16px;
                    margin: 0;
                    line-height: 1.15;
                }

                p {
                    margin: 0;
                    text-align: center;
                    margin-top: 2px;
                }
            }
        }

        .teachers-con-sec {
            max-width: 325px;
            margin: 0 auto;
            float: none;
            padding-top: 35px;
            max-width: 300px;
        }
    }

    .single-box {
        .each-teachers-sec {
            display: flex;
            align-items: center;
            @media only screen and (max-width: 768px) {
                & {
                   flex-direction: column;
                }
            }
        }
        .teachers-con-sec{
            max-width: 100%;
            padding-top: 0;
            padding-left: 35px;
            @media only screen and (max-width: 768px) {
                & {
                    padding-left: 0;
                    text-align: center;
                }
            }
        }
    }
}