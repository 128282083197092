.our-mission-wrp.francisca {
    @media only screen and (max-width: 650px) {
        &>.row {
            flex-direction: column-reverse;
        }
    }

    .each-program-sec {
        padding: 30px 40px;
        border-radius: 24px;
        background: var(--GreenLight);
        max-width: 530px;

        @media only screen and (max-width: 650px) {
            & {
                padding: 30px 30px;
                margin-top: 35px;
            }
        }

        ul {
            li {
                display: flex;
                align-items: center;

                &:not(:last-child) {
                    margin-bottom: 20px;
                }

                span {
                    width: 50px;
                    height: 50px;
                    display: block;

                    svg {
                        width: 50px;
                        height: 50px;
                    }
                }

                p {
                    margin: 0;
                    padding-left: 15px;
                    text-align: left;
                    color: var(--Text36);
                    font-size: 14px;
                    max-width: 230px;
                }

                h3 {
                    margin: 0;
                    padding-left: 15px;
                    color: var(--Green1C);
                    font-weight: 700;
                }
            }
        }

        &.orange {
            span {
                svg {
                    circle {
                        fill: var(--Orange);
                    }
                }
            }
        }

        &.green {
            span {
                svg {
                    circle {
                        fill: var(--Green19);
                    }
                }
            }
        }

        &.yellow {
            span {
                svg {
                    circle {
                        fill: var(--Yellow);
                    }
                }
            }
        }

        &.turquoise {
            span {
                svg {
                    circle {
                        fill: var(--Turquoise);
                    }
                }
            }
        }
    }

    .each-mission-sec {
        li {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        h1 {
            color: var(--Glaucous);
            font-weight: 700;
            font-family: var(--Baloo);
            margin-top: 0;
        }

        p {
            font-size: 15px;
        }
    }
}