.service-sec-wrp.templateTwo {
    .content-sec-mob {
        max-width: 1990px;
    }

    .each-service-sec {
        display: block;
        text-align: center;
        max-width: 225px;
        width: 225px;

        padding-bottom: 35px;
        margin: 0 auto;
        float: none;
        color: var(--White);

        .service-icon {
            width: 75px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            height: 75px;
            border-radius: 100%;
            padding: 20px;
            margin-bottom: 10px;
            line-height: 0;
            position: relative;

            span {
                line-height: 0;

                &.iconBG {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }
            }

            svg {
                width: 100%;
                height: 100%;

                path {
                    width: 35px;
                    height: 35px;
                }

                circle {
                    fill-opacity: 1;
                    display: none;
                }
            }
        }

        h3 {
            margin-bottom: 0;
            font-family: var(--Walter);
        }

        p {
            margin-bottom: 0;
            font-size: 14px;
        }
    }

    .swiper-wrapper {
        .swiper-slide {
            &.service1 {
                .each-service-sec {
                    .service-icon {
                        span {
                            --svgBG: var(--serviceClr1);

                            &.iconBG {
                                &+span {
                                    position: relative;
                                    z-index: 1;
                                    --iconColor: var(--White);
                                }
                            }
                        }

                    }
                }
            }

            &.service2 {
                .each-service-sec {
                    .service-icon {
                        span {
                            --svgBG: var(--serviceClr2);

                            &.iconBG {
                                &+span {
                                    position: relative;
                                    z-index: 1;
                                    --iconColor: var(--White);
                                }
                            }
                        }

                    }
                }
            }

            &.service3 {
                .each-service-sec {
                    .service-icon {
                        span {
                            --svgBG: var(--serviceClr3);

                            &.iconBG {
                                &+span {
                                    position: relative;
                                    z-index: 1;
                                    --iconColor: var(--White);
                                }
                            }
                        }

                    }
                }
            }
            &.service4 {
                .each-service-sec {
                    .service-icon {
                        span {
                            --svgBG: var(--serviceClr4);

                            &.iconBG {
                                &+span {
                                    position: relative;
                                    z-index: 1;
                                    --iconColor: var(--White);
                                }
                            }
                        }

                    }
                }
            }
            &.service5 {
                .each-service-sec {
                    .service-icon {
                        span {
                            --svgBG: var(--serviceClr5);

                            &.iconBG {
                                &+span {
                                    position: relative;
                                    z-index: 1;
                                    --iconColor: var(--White);
                                }
                            }
                        }

                    }
                }
            }
            &.service6 {
                .each-service-sec {
                    .service-icon {
                        span {
                            --svgBG: var(--serviceClr6);

                            &.iconBG {
                                &+span {
                                    position: relative;
                                    z-index: 1;
                                    --iconColor: var(--White);
                                }
                            }
                        }

                    }
                }
            }

        }
    }

    &::after {
        content: '';
        width: 100%;
        height: calc(100% - 60px);
        display: block;
        background: var(--primary);
        position: absolute;
        left: 0;
        bottom: 0;
    }

    .swiper-button-prev::after,
    .swiper-button-next::after {
        content: '';
        width: 25px;
        height: 50px;
        display: block;
        object-fit: contain;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0;
        transition: 0.5s all;
    }

    &:hover {

        .swiper-button-prev::after,
        .swiper-button-next::after {
            opacity: 1;
            transition: 0.5s all;
        }
    }



    .swiper {

        .swiper-button-prev,
        .swiper-button-next {
            padding: 0;
            background: transparent;
            border: none;
            opacity: 0;
            transition: 0.5s all;

            svg {
                path {
                    fill: var(--primary);
                }
            }

        }

        &:hover {

            .swiper-button-prev,
            .swiper-button-next {
                opacity: 1;
                transition: 0.5s all;
            }
        }
    }


    .swiper-button-prev::after,
    .swiper-button-next::after {
        display: none;
    }

    &:hover {

        .swiper-button-prev::after,
        .swiper-button-next::after {
            opacity: 1;
            transition: 0.5s all;
        }
    }

    .swiper-button-prev::after {
        background-image: url('../images/icons/slideLeft.svg');
    }

    .swiper-button-next::after {
        background-image: url('../images/icons/slideRight.svg');
    }
}