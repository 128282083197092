.light-bg {
    .tutorial-sec-wrp {
        &.provider-tutorial-wrp{
            background: var(--White);
            border-radius: 24px;
            padding: 24px;
            .maxWidth_767 {
                max-width: 767px;
                margin: 0 auto;
                float: none;
                display: flex;
                flex-direction: column;
            }
        
            .user-manual-wrp {
        
        
                .manual-header {
                    h1 {
                        color: #0F0A01;
                        margin: 0;
                        font-family: var(--RedHatDisplay);
                        font-style: normal;
                        font-weight: 700;
                        letter-spacing: 3px;
                    }
        
                    p {
                        color: #0F0A01;
                        font-family: var(--Roboto);
                        font-weight: 300;
                    }
        
                    .video-btn-wrp {
                        p {
                            max-width: 100%;
                            display: flex;
                            align-items: center;
                            color: #0F0A01;
                            font-family: var(--RedHatDisplay);
                            font-weight: 500;
        
                            svg {
                                width: 40px;
                                height: 40px;
                                padding-right: 7px;
        
                                path {
                                    fill: #FF3D00;
                                }
                            }
                        }
        
                        .video-button-list {
                            display: flex;
                            gap: 20px;
        
                            @media screen and (max-width: 520px) {
                                & {
                                    flex-direction: column;
                                }
                            }
        
                            li {
                                .video-button {
                                    padding: 10px 50px;
                                    border-radius: 50px;
                                    color: var(--White);
                                    display: flex;
                                    align-items: center;
                                    gap: 7px;
        
                                    @media screen and (max-width: 520px) {
                                        & {
                                            justify-content: center;
                                        }
                                    }
        
                                    &.provider-hub {
                                        background: #FF3D00;
                                    }
        
                                    &.parent-hub {
                                        background: #FF7940;
                                    }
                                }
                            }
                        }
                    }
                }
        
                .accordian-wrapper {
                    padding-top: 50px;
        
                    .accordian-sec-wrp {
                        &>p {
                            margin-top: 0;
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            color: #0F0A01;
        
                            svg {
                                path {
                                    fill: #FF3D00;
                                }
                            }
                        }
        
                        .each-accordian-sec {
                            border: 2px solid #98948A;
                            margin-bottom: 15px;
                            border-radius: 15px;
                            background-color: transparent;
        
                            &::before {
                                content: '';
                                opacity: 0;
                            }
        
                            .MuiAccordionSummary-content {
                                span {
                                    margin-right: 10px;
        
                                    svg {
                                        width: 20px;
                                        height: 20px;
        
                                        path {
                                            fill: #FF3D00;
                                            stroke: none;
                                        }
                                    }
        
                                    &.stokeSvg {
                                        svg {
                                            width: 20px;
                                            height: 20px;
        
                                            path {
                                                fill: none !important;
                                                stroke: #FF3D00;
                                            }
                                        }
                                    }
                                }
        
                                h4 {
                                    margin: 0;
                                    color: #0F0A01;
                                    font-weight: 500;
                                }
        
                                p {
                                    margin: 0;
                                    font-size: 12px;
                                    color: #0F0A01;
                                    font-weight: 400;
                                }
                            }
        
                            .MuiAccordionSummary-expandIconWrapper {
                                width: 30px;
                                height: 30px;
        
                                svg {
                                    width: 30px;
                                    height: 30px;
                                    padding-right: 7px;
                                    padding-bottom: 5px;
        
                                    path {
                                        stroke: #FF7940;
                                    }
                                }
                            }
        
                            .MuiCollapse-root {
                                ul {
                                    padding-left: 30px;
        
                                    li {
                                        display: flex;
                                        align-items: center;
                                        gap: 5px;
                                        font-size: 14px;
                                        color: #0F0A01;
                                        font-weight: 500;
                                        margin-bottom: 7px;
        
                                        a {
                                            display: flex;
                                            gap: 10px;
                                            align-items: center;
                                            color: #0F0A01;
                                        }
        
                                        span {
                                            line-height: 1;
                                            width: 20px;
                                            height: 20px;
                                            display: inline-block;
        
                                            svg {
                                                width: inherit;
                                                height: inherit;
        
                                                path {
                                                    fill: #FF7940;
                                                }
                                            }
        
                                            &.parentIcon {
                                                svg {
                                                    path {
                                                        fill: none;
                                                        stroke: #FF7940;
                                                        stroke-width: 1.7px;
                                                    }
                                                }
                                            }
        
                                            &.PalmIcon,
                                            &.PaymentsIcon,
                                            &.EmployeeIcon {
                                                svg {
                                                    path {
                                                        fill: none;
                                                        stroke: #FF7940;
                                                    }
                                                }
                                            }
                                        }
        
                                    }
                                }
                            }
                        }
        
                        padding-right: 30px;
        
                        @media screen and (max-width: 991px) {
                            & {
                                padding-right: 0;
                                padding-bottom: 30px;
                            }
                        }
                    }
        
                    .video-wrp {
                        padding-left: 30px;
        
                        @media screen and (max-width: 991px) {
                            & {
                                padding-left: 0;
                                padding-top: 30px;
                            }
                        }
        
                        span {
                            border-radius: 14px;
                            overflow: hidden;
                            display: block;
                            height: 100%;
                            width: 100%;
                        }
        
                        &::before {
                            content: '';
                            width: 5px;
                            height: 100%;
                            position: absolute;
                            left: -17.5px;
                            top: 0;
                            border-radius: 50px;
                            background: var(--parkLine);
        
                            @media screen and (max-width: 991px) {
                                & {
                                    width: 100%;
                                    height: 5px;
                                    left: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.dark-bg {
    .tutorial-sec-wrp {
        &.provider-tutorial-wrp{
            background: var(--drkBg_2);
        }
    }
}