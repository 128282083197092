.Francisca-teachers-wrp {
    max-width: 991px;
    margin: 0 auto;
    float: none !important;

    .row {
        &>div {
            margin-bottom: 30px;
        }

        &>div:nth-child(odd) {
            .each-teachers-sec {
                .teachers-img-sec {
                    left: 45px;
                    z-index: 2;

                    @media only screen and (max-width: 1200px) {
                        & {
                            left: 0px;
                        }
                    }
                }
            }

        }

        &>div:nth-child(even) {
            .each-teachers-sec {
                flex-direction: row-reverse;

                .teachers-img-sec {
                    right: 45px;
                    z-index: 2;

                    @media only screen and (max-width: 1200px) {
                        & {
                            right: 0px;
                        }
                    }
                }
            }
        }
    }

    .each-teachers-sec {
        display: flex;
        flex-wrap: wrap;

        .teachers-img-sec {
            width: 100%;
            height: 100%;
            display: block;
            margin: 0 auto;
            max-width: 230px;
            float: none;

            .fee-sec-icon {
                width: 100%;
                height: 400px;
                display: block;
                border-radius: 100%;
                overflow: hidden;
                max-width: 230px;
                margin: 0 auto;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                }
            }

            .title-box {
                max-width: 120px;
                height: 120px;
                border-radius: 100%;
                position: absolute;
                right: 0px;
                left: 0px;
                margin: 0 auto;
                bottom: -20px;
                padding: 5px;
                background: var(--White);
                border: 7px solid var(--Amaranth);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                overflow: hidden;

                h2 {
                    font-family: var(--Baloo);
                    color: var(--Glaucous);
                    font-weight: 700;
                    text-align: center;
                    font-size: 16px;
                    margin: 0;
                    line-height: 1.15;
                }

                p {
                    margin: 0;
                    text-align: center;
                    margin-top: 2px;
                    color: var(--Text36);
                }
            }
        }

        .teachers-con-sec {
            margin: 0 auto;
            float: none;
            max-width: 725px;
            padding-left: 120px;
            padding-right: 120px;
            border-radius: 200px;

            @media only screen and (max-width: 767px) {
                & {
                    padding-left: 20px;
                    padding-right: 20px;
                    border-radius: 25px;
                    background: var(--lytColor4Hex);
                }
            }

            &::before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 250px;
                border-radius: 500% 0 0 500%;
                background: var(--lytColor4Hex);
                @media only screen and (max-width: 767px) {
                    & {
                       display: none;
                    }
                }
            }

            &::after {
                content: '';
                display: block;
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                width: 250px;
                border-radius: 0 500% 500% 0;
                background: var(--lytColor4Hex);
                @media only screen and (max-width: 767px) {
                    & {
                       display: none;
                    }
                }
            }

            .teachers-con-box {
                background: var(--lytColor4Hex);
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: relative;
                z-index: 1;
                padding-top: 30px;
                padding-bottom: 50px;
                height: 100%;
                @media only screen and (max-width: 767px) {
                    & {
                        background: none;
                    }
                }

                ul {
                    li {
                        font-size: 14px;
                    }
                }

                p {
                    font-size: 14px;
                    white-space: pre-line;
                }
            }

            ul {
                padding-left: 40px;
                padding-right: 15px;
                list-style: disc;
                list-style-position: outside;

                li {
                    list-style: disc;
                    list-style-position: outside;
                    color: var(--Text36);
                }
            }
        }
    }
}