.inbox-main-wrp {
    padding: 0;

    .inbox-con-wrp {
        background: #fff;
        border-radius: 24px;
        padding: 24px;
        @media only screen and (max-width: 600px) {
            padding: 10px;
            .mob-table-wrp > ul > li{
                overflow: hidden;
                .each-sec-box span{
                    white-space: nowrap;
                    width: calc(100% - 40px);
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            } 
        }
        // .MuiDataGrid-columnHeaders {
        //     display: none;
        // }

        // .MuiDataGrid-row:nth-child(even) {
        //     background-color: #F5F0E7;
        // }

        .MuiDataGrid-row {
            .MuiDataGrid-cell:first-child {
                .MuiDataGrid-cellContent {
                    font-weight: 700;
                }
            }
        }
    }

}

.inbox-view-wrp {
    background-color: #fff;
    padding: 24px;
    border-radius: 24px;
    @media only screen and (max-width: 600px) {
        padding: 15px;
        border-radius: 15px;
    }
    .prof-sec {
        display: flex;
        margin-top: 25px;

        .prof-img {
            width: 75px;

            img {
                border-radius: 100%;
            }
        }

        .prof-basic {
            // padding-left: 20px;

            h4 {
                margin: 0;
                font-weight: 700;
            }

            p {
                margin: 0;
                font-weight: 400;
                font-size: 14px;

                b {
                    font-weight: 700;
                }
            }
        }
    }

    .inbox-content {
        border-top: 1px solid #98947A;
        border-bottom: 1px solid #98947A;
        margin: 25px 0;
    }
}

.back-btn-wrp {
    button {
        font-size: 13px;
    }

    svg {
        width: 10px;
        height: 14px;
        margin-right: 4px;
    }
}

.modal-popup {
    .MuiDialog-container {
        .MuiPaper-root {
            max-width: 1020px;
            width: 100%;
        }
    }
}