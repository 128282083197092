.rafaelaTemplates {
    .testimonial-sec-wrp {
        margin: 15px auto;
        max-width: 1070px;
        display: block;

        .each-testimonial-items {
            max-width: 767px;
            text-align: center;
            margin: 0 auto;
            float: none;
            padding-bottom: 25px;

            h2 {
                color: var(--Blue);
                font-family: var(--Walter);

                span {
                    color: var(--Text00);
                    font-size: 14px;
                    img{
                        display: inline-block;
                        width: 26px;
                        position: relative;
                        top: 5px;
                        margin-right: 5px;
                    }
                }
            }
        }

        .swiper-pagination-bullet-active {
            background: var(--RedED);
            width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 12px));
            border-radius: 15px;
        }

        .swiper-button-prev,
        .swiper-button-next {
            width: 130px;

            @media only screen and (max-width: 991px) {
                & {
                    width: 30px;
                }
            }
        }

        .swiper-button-prev::after,
        .swiper-button-next::after {
            content: '';
            width: 100%;
            height: 100%;
            display: block;
            object-fit: contain;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            opacity: 1;
            transition: 0.5s all;

            @media only screen and (max-width: 991px) {
                & {
                    display: none;
                }
            }
        }

        &:hover {

            .swiper-button-prev::after,
            .swiper-button-next::after {
                opacity: 1;
                transition: 0.5s all;
            }
        }

        .swiper-button-prev::after {
            background-image: url('../images/icons/testLeft.svg');

            @media only screen and (max-width: 991px) {
                & {
                    background-image: url('../images/icons/slideLeftOrg.svg');
                }
            }
        }

        .swiper-button-next::after {
            background-image: url('../images/icons/testRight.svg');

            @media only screen and (max-width: 991px) {
                & {
                    background-image: url('../images/icons/slideRightOrg.svg');
                }
            }
        }

    }
}