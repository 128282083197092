.ppPlatformSlider {
  .gallery-image {
    max-height: calc(100vh - 50px);
    height: auto;
    display: flex;
    align-items: center;

    img {
      object-fit: contain;
      height: 100%;
      object-position: center;
      border-radius: 20px;
    }
  }

  .swiper.swiper-initialized {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;

    @media screen and (max-width: 767px) {
      & {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }

  .swiper-pagination-bullet-active {
    background: var(--Orange);
    width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 12px));
    border-radius: 15px;
  }

  .swiper-slide.swiper-slide-prev,
  .swiper-slide,
  .swiper-slide.swiper-slide-next {
    transform: scale(0.8) translate(-200px, 0);
    filter: blur(5px);
    transition: 1s all ease;
  }

  .swiper-slide.swiper-slide-prev {
    transform: scale(0.8) translate(200px, 0);
  }

  .swiper-slide.swiper-slide-next {
    transform: scale(0.8) translate(-200px, 0);
  }

  .swiper-slide.swiper-slide-active {
    transition: 1s all ease;
    filter: blur(0px);
    transform: scale(1);
    z-index: 9;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 50px;
    height: 50px;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    content: '';
    width: 50px;
    height: 50px;
    display: block;
    object-fit: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 1;
    transition: 0.5s all;

    @media screen and (max-width: 500px) {
      & {
        width: 35px;
        height: 35px;
      }
    }
  }

  &:hover {

    .swiper-button-prev::after,
    .swiper-button-next::after {
      opacity: 1;
      transition: 0.5s all;
    }
  }

  .swiper-button-prev::after {
    background-image: url('../../../assets/images/ppPlatform/slideLeft.png');
  }

  .swiper-button-next::after {
    background-image: url('../../../assets/images/ppPlatform/slideRight.png');
  }

}