.Kathleen-program-wrp {
    .each-program-sec {
        ul {
            li {
                display: flex;
                align-items: center;
            margin-bottom: 15px;
                span{
                    width: 40px;
                    height: 40px;
                    display: block;
                    svg{
                        width: 40px;
                        height: 40px;
                    }
                }
                p {
                    margin: 0;
                    padding-left: 15px;
                    text-align: left;
                }
            }
        }
        &.orange{
            span{
                svg {
                    circle {
                        fill: var(--Orange);
                    }
                }
            }
        }
        &.green{
            span{
                svg {
                    circle {
                        fill: var(--Green19);
                    }
                }
            }
        }
        &.yellow {
            span{
                svg {
                    circle {
                        fill: var(--Yellow);
                    }
                }
            }
        }
        &.turquoise {
            span{
                svg {
                    circle {
                        fill: var(--Turquoise);
                    }
                }
            }
        }
    }
}