.kathleenTemplates.main-head-wrp {
    background-color: var(--White);
    // position: absolute;
    top: 0;
    left: 0;
    z-index: 9;

    .main-header {
        display: flex;
        justify-content: space-between;
        padding: 5px 0;

        .main-logo {
            display: block;
            width: 60px;
            float: left;
        }
    }

    .burger-prof-sec {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .language-box {
            a {
                svg {
                    width: 20px;
                    display: block;

                    path {
                        stroke: var(--Purple);
                    }
                }
            }

            &+.f-wrp {
                display: flex;
                background: #FE5D37;
                margin-left: 40px;
                padding: 7px 15px;
                align-items: center;
                border-radius: 50px;

                .burger-icon {
                    margin-left: 0;
                    margin-right: 0;

                    svg {
                        path {
                            stroke: var(--White);
                        }
                    }
                }

                .profile-sec-box {
                    svg {
                        path {
                            fill: var(--White);
                        }

                        rect {
                            fill: var(--White);
                            fill-opacity: 0.3;
                        }
                    }
                }
            }
        }

        .profile-sec-box {
            a {
                svg {
                    width: 23px;
                    display: block;
                    height: 100%;
                }
            }
        }
    }
}