.TemplatesTwo {

    .sqr-btn {
        display: inline-block;
        padding: 10px 40px;
        margin-top: 30px;
        border-radius: 8px;
    }

    .bandBtn {
        display: block;
        padding: 10px 40px;
        margin: 0 auto;
        border-radius: 8px;
        background-color: var(--white);
        color: var(--galleryBandBG);
    }

    .main-banner-wrp {
        .bannerBG {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            min-height: 100vh;

            @media only screen and (max-width: 650px) {
                & {
                    min-height: calc(100vh - 50px);
                    &::after{
                        content: '';
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        background-color: rgba(0, 0, 0, 0.35);
                    }
                }
            }

            span {
                display: block;
                width: 100%;
                height: 100%;

                img {
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                }

            }

            @media only screen and (min-width: 650px) {
                & {
                    .mobBG {
                        display: none;
                    }
                }
            }

            @media only screen and (max-width: 650px) {
                & {
                    .pcBG {
                        display: none;
                    }
                }
            }

            &+div {
                z-index: 9;
            }
        }

       

        .banner-align {
            display: flex;
            min-height: 100vh;
            padding-top: 10px;
            padding-bottom: 10px;
            width: 100%;
            align-items: center;

            @media only screen and (max-width: 650px) {
                & {
                    height: calc(100vh - 50px);
                    align-items: centers;
                    padding-bottom: 25px;
                }
            }

            .banner-con-sec {
                max-width: 665px;
                @media only screen and (max-width: 650px) {
                    & {
                        height: calc(100vh - 50px);
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;
                    }
                }
                .banner-con-box {
                    display: flex;
                    border-radius: 8px;
                    background: rgba(255, 255, 255, 0.65);
                    padding: 25px 30px;
                    padding-left: 20px;

                    span {
                        display: block;
                        max-width: 140px;
                        flex: 1;
                        min-width: 140px;
                        width: 140px;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            object-position: center;
                        }

                    }

                    @media only screen and (min-width: 650px) {
                        & {
                            .mob-icon {
                                display: none;
                            }
                        }
                    }

                    @media only screen and (max-width: 650px) {
                        & {
                            .pc-icon {
                                display: none;
                            }

                            .mob-icon {
                                max-width: 160px;
                                width: 100%;
                                margin: 0 auto;
                                @media only screen and (max-width: 650px) {
                                    & {
                                        padding-bottom: 30px;
                                    }
                                }
                            }
                        }
                    }

                    .banner-con {
                        flex: 2;
                        padding-left: 20px;
                        @media only screen and (max-width: 650px) {
                            & {
                                padding-left: 0;
                            }
                        }

                        h1 {
                            margin: 0;
                            color: var(--Orange);
                            font-family: var(--Baloo);
                            font-weight: 700;
                            font-size: 40px;
                            line-height: 1;
                        }

                        p {
                            margin-top: 10px;
                            margin-bottom: 0;
                            color: #616279;
                        }

                        @media only screen and (max-width: 650px) {
                            & {
                                .pc-icon {
                                    display: none;
                                }

                                h1 {
                                    color: var(--White);
                                    font-size: 32px;
                                    text-align: center;

                                    br {
                                        display: none;
                                    }
                                }

                                p {
                                    display: none;
                                }
                            }
                        }
                    }

                    @media only screen and (max-width: 650px) {
                        & {
                            background: none;
                            flex-direction: column;
                            padding: 20px;
                        }
                    }
                }

                .sqr-btn {
                    background: var(--Purple, #57509E);
                    color: var(--White, #FFFFFF);

                    @media only screen and (max-width: 650px) {
                        & {
                            width: 100%;
                        }
                        &:not(:last-child) {
                            margin-right: 0px;
                        }
                    }
                }
            }

        }

    }

    .service-slider-sec {
        padding: 50px 0;
    }

    .about-section-wrp {
        .about-grid-wrp.f-wrp {
            display: flex;
            flex-wrap: wrap;
            gap: 100px;
            align-items: center;

            .about-con-sec,
            .about-img-sec {
                flex: 1;
            }

            .about-con-sec {
                text-align: left;

                h1 {
                    display: flex;
                    color: var(--primary);
                    font-family: var(--Walter);
                    align-items: center;
                    line-height: 1;
                    justify-content: center;

                    img {
                        height: 25px;
                        object-fit: contain;
                        object-position: left;
                        padding-left: 15px;
                        width: auto;
                    }
                }

                p {
                    color: var(--Text99);
                }

                span {
                    font-family: var(--Cedarville);
                    color: var(--primary);
                    font-size: 25px;
                    text-align: center;
                    display: block;
                }
            }

            @media only screen and (max-width: 1200px) {
                & {
                    gap: 30px;
                }
            }

            @media only screen and (max-width: 991px) {
                & {
                    flex-direction: column;
                }
            }
        }
    }

    .contact-blk-wrp {

        .contact-sec-blk {
            display: flex;
            max-width: 1920px;
            margin: 0 auto;
            float: none;

            @media only screen and (max-width: 767px) {
                & {
                    flex-direction: column;
                }
            }

            .contact-con {
                flex: 2;
                background: var(--contactContentBG);

                &>.f-wrp {
                    max-width: 600px;
                    padding: 30px 0 60px;
                    margin: 0 auto;
                    float: none;
                    text-align: center;
                    color: var(--White);
                }

                h1 {
                    margin-bottom: 10px;
                }

                h4 {
                    margin-top: 0;
                }

                h1,
                h4 {
                    font-family: var(--Walter);
                }

                p {
                    max-width: 550px;
                    margin: 0 auto;
                    margin-bottom: 25px;
                    padding: 0 15px;
                }

                ul {
                    display: grid;
                    grid-template-columns: repeat(auto-fill, 200px);
                    justify-content: center;

                    @media only screen and (max-width: 1200px) {
                        & {
                            grid-template-columns: repeat(auto-fill, 300px);
                        }
                    }

                    li {
                        padding-left: 15px;
                        padding-right: 15px;

                        .each-contact-block {
                            margin-bottom: 15px;

                            .contact-icon {
                                width: 75px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                margin: 0 auto;
                                height: 75px;
                                border-radius: 100%;
                                padding: 20px;
                                margin-bottom: 10px;
                                line-height: 0;
                                position: relative;

                                .iconBG {
                                    width: 100%;
                                    height: 100%;
                                    position: absolute;
                                    left: 0;
                                    top: 0;

                                    svg {
                                        width: 100%;
                                        height: 100%;
                                    }

                                    &+svg {
                                        width: 35px;
                                        height: 35px;
                                        position: relative;
                                        z-index: 1;

                                    }
                                }
                            }


                            &.map {
                                .contact-icon {
                                    .iconBG {
                                        --svgBG: var(--contactIconBG1);

                                        &+svg {
                                            --contactIconClr: var(--white);
                                        }
                                    }
                                }
                            }

                            &.mail {
                                .contact-icon {
                                    .iconBG {
                                        --svgBG: var(--contactIconBG2);

                                        &+svg {
                                            --contactIconClr: var(--white);
                                        }
                                    }
                                }
                            }

                            &.phone {
                                .contact-icon {
                                    .iconBG {
                                        --svgBG: var(--contactIconBG3);

                                        &+svg {
                                            --contactIconClr: var(--white);
                                        }
                                    }
                                }
                            }

                            p,
                            a {
                                margin-top: 10px;
                                margin-bottom: 0;
                                padding: 0;
                                display: inline-block;
                                color: var(--white);
                            }
                        }
                    }
                }

                .sqr-btn {
                    background: var(--contactUsBtnClr);
                    color: var(--White);
                }
            }

            .contact-img {
                flex: 1;

                span {
                    position: relative;
                    width: 100%;
                    display: block;
                    height: 100%;

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: bottom;

                        @media only screen and (max-width: 767px) {
                            & {
                                position: relative;
                            }
                        }
                    }
                }
            }
        }
    }

    .program-sec-wrp {
        .program-sec-header {
            text-align: center;

            h1 {
                font-family: var(--Walter);
                color: var(--primary);
                margin-bottom: 15px;
                margin-top: 0;
            }

            p {
                margin: 0;
                color: var(--Text99);
            }
        }

        .program-con-wrp {
            background: var(--programBlkBG);

            .program-grid {
                display: grid;
                grid-template-columns: repeat(auto-fill, 320px);
                justify-content: center;
                padding-top: 50px;
                padding-bottom: 10px;

                @media only screen and (max-width: 1200px) {
                    & {
                        grid-template-columns: repeat(auto-fill, 300px);
                    }
                }

                .each-program-sec {
                    margin-bottom: 25px;
                    text-align: center;
                    color: var(--White);

                    .program-icon {
                        width: 75px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 0 auto;
                        height: 75px;
                        border-radius: 100%;
                        padding: 20px;
                        margin-bottom: 10px;
                        line-height: 0;
                        position: relative;

                        .iconBG {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            left: 0;
                            top: 0;

                            svg {
                                width: 100%;
                                height: 100%;
                                --svgBG: var(--white);
                            }

                            &+span svg {
                                width: 35px;
                                height: 35px;
                                position: relative;
                                z-index: 1;
                                --svgBG: var(--programIconClr);
                                --iconColor: var(--programIconClr);
                            }
                        }
                    }

                    h3 {
                        font-family: var(--Walter);
                    }

                    p {
                        margin-top: 10px;
                        margin-bottom: 0;
                        padding: 0;

                        i {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }



    .learning-sec-wrp {
        .learning-sec-header {
            text-align: center;
            margin-bottom: 40px;

            h1 {
                color: var(--primary);
                font-family: var(--Walter);
            }

            p {
                color: var(--petrol);
            }
        }

        .learning-con-wrp {
            .learning-grid {
                .row {
                    &>div {
                        margin-bottom: 40px;
                    }
                }

                @media only screen and (max-width: 991px) {
                    & {
                        .hide {
                            display: none;
                        }
                    }
                }
            }

            .each-learning-sec {
                height: 100%;

                .learning-img {
                    display: block;
                    width: 100%;
                    height: 200px;
                    position: relative;

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                    }

                }

                .learing-header {
                    padding: 15px;
                    background: #eee;
                    height: 75px;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    z-index: 1;

                    h3 {
                        margin: 0;
                        font-size: 22px;
                        line-height: 1.2;
                        color: #fff;
                        white-space: pre-wrap;
                    }
                }

                .learing-content {
                    padding: 15px;
                    background: var(--learningContentBG);
                    height: calc(100% - 200px);

                    .learning-icon {
                        float: right;
                        width: 75px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 0 auto;
                        height: 75px;
                        border-radius: 100%;
                        padding: 20px;
                        margin-bottom: 10px;
                        z-index: 9;
                        position: relative;
                        margin-top: -55px;
                        flex-direction: column;
                        line-height: 1.1;

                        .iconBG {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            left: 0;
                            top: 0;

                            svg {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        h3,
                        p {
                            margin: 0;
                            color: var(--white);
                            font-family: var(--Roboto);
                            position: relative;
                            z-index: 1;
                            padding-right: 0px;
                        }

                        h3 {
                            font-weight: 700;
                        }

                        p {
                            font-size: 13px;
                        }
                    }

                    * {
                        color: var(--White);
                    }

                    h4,
                    h3 {
                        margin: 0;
                        padding-right: 80px;
                    }

                    h4 {
                        font-family: var(--Roboto);
                        padding-bottom: 5px;
                        font-size: 13px;
                    }

                    h3 {
                        margin: 0;
                        font-size: 22px;
                        font-family: var(--Walter);
                    }

                    ul {
                        li {
                            margin-bottom: 10px;
                        }
                    }

                    .learning-grid-sec {
                        padding: 5px 0 0;
                        max-width: 325px;
                        margin: 0 auto;
                        float: none;

                        .trip-grid {
                            display: flex;

                            div {
                                flex: 1;
                                margin-right: 5px;

                                span {
                                    color: var(--white);
                                    font-weight: 300;
                                    font-size: 13px;

                                    b {
                                        font-weight: 600;
                                        font-size: 14px;
                                    }
                                }
                            }

                            div:last-child {
                                max-width: 33.33%;
                                margin-right: 0px;
                            }

                            div:nth-child(1) {
                                border-top: 5px solid transparent;

                                b {
                                    color: var(--white);
                                }
                            }

                            div:nth-child(2) {
                                border-top: 5px solid transparent;

                                b {
                                    color: var(--white);
                                }
                            }

                            div:last-child {
                                border-top: 5px solid transparent;

                                b {
                                    color: var(--white);
                                }
                            }

                            span {
                                b {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }

            .each-class-items {
                &:nth-child(6n+1) {
                    .each-learning-sec {
                        .learing-content {
                            .learning-icon {
                                .iconBG {
                                    --svgBG: var(--classIconBG1);
                                }
                            }
                        }
                    }
                }

                &:nth-child(6n+2) {
                    .each-learning-sec {
                        .learing-content {
                            .learning-icon {
                                .iconBG {
                                    --svgBG: var(--classIconBG2);
                                }
                            }
                        }
                    }
                }

                &:nth-child(6n+3) {
                    .each-learning-sec {
                        .learing-content {
                            .learning-icon {
                                .iconBG {
                                    --svgBG: var(--classIconBG3);
                                }
                            }
                        }
                    }
                }

                &:nth-child(6n+4) {
                    .each-learning-sec {
                        .learing-content {
                            .learning-icon {
                                .iconBG {
                                    --svgBG: var(--classIconBG4);
                                }
                            }
                        }
                    }
                }

                &:nth-child(6n+5) {
                    .each-learning-sec {
                        .learing-content {
                            .learning-icon {
                                .iconBG {
                                    --svgBG: var(--classIconBG5);
                                }
                            }
                        }
                    }
                }

                &:nth-child(6n+6) {
                    .each-learning-sec {
                        .learing-content {
                            .learning-icon {
                                .iconBG {
                                    --svgBG: var(--classIconBG6);
                                }
                            }
                        }
                    }
                }
            }









        }
    }


    .activities-section-wrp {
        .activities-grid-wrp.f-wrp {
            display: flex;
            flex-wrap: wrap;
            gap: 100px;
            align-items: center;

            .activities-con-sec,
            .activities-img-sec {
                flex: 1;
            }

            .activities-con-sec {
                text-align: center;

                h1 {
                    color: var(--primary);
                    font-family: var(--Walter);
                    text-align: left;
                }

                p {
                    color: var(--Text99);
                    text-align: left;
                }

                span {
                    font-family: var(--Cedarville);
                    color: var(--primary);
                    font-size: 25px;
                }

                ul {
                    column-count: 2;

                    @media only screen and (max-width: 600px) {
                        & {
                            column-count: 1;
                            padding-left: 10%;
                        }
                    }

                    li {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        width: fit-content;
                        padding-bottom: 15px;

                        .activities-icon {
                            width: 40px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin: 0 auto;
                            height: 40px;
                            border-radius: 100%;
                            padding: 10px;
                            line-height: 0;
                            position: relative;
                            flex-shrink: 0;

                            .iconBG {
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                left: 0;
                                top: 0;

                                svg {
                                    width: 100%;
                                    height: 100%;
                                    --svgBG: var(--activitiesIconBG1);
                                }

                                &+span {
                                    height: 100%;
                                    overflow: hidden;
                                    width: 100%;

                                    svg {
                                        width: 100%;
                                        height: 100%;
                                        position: relative;
                                        z-index: 1;
                                        --svgBG: var(--white);
                                        --iconColor: var(--white);
                                    }
                                }
                            }
                        }

                        p {
                            margin: 0;
                            padding-left: 15px;
                            text-align: left !important;

                            @media only screen and (max-width: 600px) {
                                & {
                                    text-align: left !important;
                                }
                            }
                        }

                        &:nth-child(8n+1) {
                            .activities-icon {
                                .iconBG {
                                    svg {
                                        --svgBG: var(--activitiesIconBG1);
                                    }
                                }
                            }
                        }

                        &:nth-child(8n+2) {
                            .activities-icon {
                                .iconBG {
                                    svg {
                                        --svgBG: var(--activitiesIconBG2);
                                    }
                                }
                            }
                        }

                        &:nth-child(8n+3) {
                            .activities-icon {
                                .iconBG {
                                    svg {
                                        --svgBG: var(--activitiesIconBG3);
                                    }
                                }
                            }
                        }

                        &:nth-child(8n+4) {
                            .activities-icon {
                                .iconBG {
                                    svg {
                                        --svgBG: var(--activitiesIconBG4);
                                    }
                                }
                            }
                        }

                        &:nth-child(8n+5) {
                            .activities-icon {
                                .iconBG {
                                    svg {
                                        --svgBG: var(--activitiesIconBG5);
                                    }
                                }
                            }
                        }

                        &:nth-child(8n+6) {
                            .activities-icon {
                                .iconBG {
                                    svg {
                                        --svgBG: var(--activitiesIconBG6);
                                    }
                                }
                            }
                        }

                        &:nth-child(8n+7) {
                            .activities-icon {
                                .iconBG {
                                    svg {
                                        --svgBG: var(--activitiesIconBG7);
                                    }
                                }
                            }
                        }

                        &:nth-child(8n+8) {
                            .activities-icon {
                                .iconBG {
                                    svg {
                                        --svgBG: var(--activitiesIconBG8);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @media only screen and (max-width: 1200px) {
                & {
                    gap: 30px;
                }
            }

            @media only screen and (max-width: 991px) {
                & {
                    flex-direction: column-reverse;

                    h1 {
                        text-align: center !important;
                    }

                    p {
                        text-align: center !important;
                    }
                }
            }
        }
    }

    .gallery-section-wrp {
        .gallery-sec-header {
            text-align: center;
            background: var(--galleryHeadBG);

            h1 {
                font-family: var(--Walter);
                color: var(--White);
            }
        }
    }

    .theme-band {
        background: var(--primary);
    }

    .theme-band.gallery-band {
        background: var(--galleryBandBG);
    }

    .theme-band.footer-band {
        background: var(--footerBandBG);
    }

    .orangeBand {
        background: var(--primary);
    }

    .about-founder-wrp {
        .row {
            &>div {
                margin-bottom: 30px;
            }

            @media only screen and (min-width: 992px) {
                & {
                    justify-content: center;
                }
            }
        }

        .founder-sec-header {
            text-align: center;
            background: var(--primary);
            color: var(--White);
            padding-bottom: 100px;

            h1 {
                font-family: var(--Walter);
                margin: 0;
                padding-bottom: 15px;
            }

            p {
                font-size: 14px;
                text-align: center;
                max-width: 991px;
                margin: 0 auto;
                padding-bottom: 15px;
            }
        }

        .founder-con-wrp {
            display: flex;

            @media only screen and (max-width: 767px) {
                & {
                    flex-direction: column;
                }
            }

            .founder-img {
                flex: 1;
                padding-right: 140px;

                @media only screen and (min-width: 1200px) {
                    & {
                        padding-right: 95px;
                    }
                }

                @media only screen and (min-width: 992px) {
                    & {
                        padding-right: 50px;
                    }
                }

                @media only screen and (min-width: 576px) {
                    & {
                        padding-right: 25px;
                    }
                }

                @media only screen and (max-width: 767px) {
                    & {
                        padding-right: 0px;
                        margin-top: -165px !important;
                    }
                }

                h2 {
                    font-family: var(--Walter);
                    color: var(--primary);
                    text-align: center;
                    margin-bottom: 0;
                }

                p {
                    text-align: center;
                    margin-top: 0;
                }
            }

            .founder-con-blk {
                flex: 2;
            }
        }

        .founder-con-sec {
            .founder-con-wrp {
                p {
                    color: var(--petrol);

                    @media only screen and (max-width: 767px) {
                        & {
                            text-align: center;
                        }
                    }
                }

                .mob-para {
                    display: none;

                    @media only screen and (max-width: 767px) {
                        & {
                            display: block;
                        }
                    }
                }
            }
        }



        .founder-con-sec.founder2 {
            margin-top: -85px;

            .each-founder-sec {
                max-width: 250px;
                text-align: center;
                margin: 0 auto;
                float: none;

                @media only screen and (max-width: 767px) {
                    & {
                        margin: 0 auto;
                        float: none;
                    }
                }

                .founder-icon {
                    width: 150px;
                    height: 150px;
                    display: block;
                    margin: 0 auto;
                    border-radius: 100%;
                    overflow: hidden;
                    border: 5px solid var(--teacherBorderClr);
                    padding: 0;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }

                h3 {
                    color: var(--primary);
                    font-family: var(--Walter);
                    margin-bottom: 0;
                }

                span {
                    color: var(--secondary);
                    font-size: 15px;
                    padding: 0px 0 10px;
                    display: block;
                    text-align: center;
                    text-transform: capitalize;
                }

                p {
                    color: var(--Text99);
                    margin: 0;
                    font-size: 14px;
                }

            }
        }


        .founder-con-wrp {
            display: flex;

            h1 {
                font-family: var(--Walter);
                color: var(--White);
            }

            @media only screen and (max-width: 767px) {
                & {
                    flex-direction: column;
                }
            }

            .founder-img {
                flex: 1;
                padding-right: 140px;

                @media only screen and (min-width: 1200px) {
                    & {
                        padding-right: 95px;
                    }
                }

                @media only screen and (min-width: 992px) {
                    & {
                        padding-right: 50px;
                    }
                }

                @media only screen and (min-width: 576px) {
                    & {
                        padding-right: 25px;
                    }
                }

                @media only screen and (max-width: 767px) {
                    & {
                        padding-right: 0px;
                        margin-top: 0px !important;
                        max-width: 200px;
                        display: block;
                    }
                }


                h2 {
                    font-family: var(--Walter);
                    color: var(--primary);
                    text-align: center;
                    margin-bottom: 0;
                }

                p {
                    text-align: center;
                    margin-top: 0;
                }
            }

            .founder-con-blk {
                flex: 2;

                @media only screen and (max-width: 767px) {
                    & {
                        text-align: center;
                    }
                }
            }
        }

        .founder-con-sec {
            .founder-con-wrp {
                p {
                    color: var(--White);

                    @media only screen and (max-width: 767px) {
                        & {
                            text-align: center;
                        }
                    }
                }

                .mob-para {
                    display: none;

                    @media only screen and (max-width: 767px) {
                        & {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .testimonial-section-wrp {
        .testimonial-sec-header {
            h1 {
                font-family: var(--Walter);
                color: var(--primary);
                text-align: center;
            }
        }
    }

    .scrolling-sec {
        background-color: var(--primary);
        padding: 15px;

        * {
            color: var(--White);
            text-transform: uppercase;
            letter-spacing: 1.68px;
        }
    }

    .pay-optiopn-wrp {
        background: var(--footerBG);
        padding: 25px 0 50px;

        &.marginTop75 {
            margin-top: 75px;

            @media only screen and (max-width: 768px) {
                & {
                    margin-top: 0;
                }
            }
        }

        .row {
            align-items: center;
        }

        .payment-content-sec {
            max-width: 475px;

            * {
                color: var(--White);
            }

            h1 {
                font-family: var(--Walter);
            }
        }

        .payment-button-sec {
            max-width: 475px;
            float: right;
            margin-top: -175px;

            @media only screen and (max-width: 768px) {
                & {
                    margin-top: 0;
                }
            }

            .sqr-btn {
                width: 100%;
                background: var(--White);
                color: var(--footerBG);
            }
        }

    }

    @media only screen and (max-width: 650px) {
        .gap.f-wrp {
            &+.gap {
                display: none;
            }
        }
    }

}


.TemplateTwo {
    .ppContact-popup {
        max-width: calc(100% - 20px);
        margin: 0 auto;

        .pp-contact-head {
            display: flex;
            justify-content: space-between;
            height: 50px;
            margin-bottom: 10px;
            align-items: center;

            p {
                font-size: 24px;
                font-weight: 700;
                color: var(--primary);
                margin-top: 0;
                margin-bottom: 0px;

                @media screen and (max-width: 500px) {
                    & {
                        font-size: 20px;
                    }
                }
            }

            button {
                padding: 0;
                min-width: 0;
                min-height: 0;
                max-height: max-content;
                height: max-content;

                svg {
                    path {
                        fill: var(--Text99);
                    }
                }
            }
        }

        .pp-contact-form {
            max-width: 550px;
            margin: 0 auto;
            float: none;
            background: var(--white);
            padding: 30px;
            margin-top: 50px;
            border-radius: 15px;

            label.MuiInputLabel-formControl {
                font-size: 13px;
            }

            .MuiInputBase-root.MuiOutlinedInput-root {
                width: 100%;

            }

            .MuiOutlinedInput-notched {
                border-color: #98948A;
            }

            textarea {
                color: var(--Text99);
            }

            .app-text-input-container {
                .MuiFormControl-root {
                    .MuiInputBase-formControl {
                        input {
                            color: var(--Text99);
                        }

                        input:-internal-autofill-selected {
                            background-color: #fff !important;
                        }

                        input:-webkit-autofill,
                        input:-webkit-autofill:hover,
                        input:-webkit-autofill:focus,
                        textarea:-webkit-autofill,
                        textarea:-webkit-autofill:hover,
                        textarea:-webkit-autofill:focus,
                        select:-webkit-autofill,
                        select:-webkit-autofill:hover,
                        select:-webkit-autofill:focus {
                            -webkit-text-fill-color: var(--Text99) !important;
                            caret-color: var(--Text99) !important;

                            .light-bg & {
                                -webkit-text-fill-color: var(--Text99) !important;
                                caret-color: var(--Text99) !important;
                                -webkit-box-shadow: 0 0 0 300px var(--Text99) inset !important;
                            }
                        }
                    }
                }
            }
        }

        .MuiFormControl-root.MuiTextField-root .MuiOutlinedInput-notchedOutline,
        .MuiFormControl-root .MuiOutlinedInput-notchedOutline {
            border-color: var(--Text99);
        }

        .pp-contact-frm {
            .login-btn {
                background: var(--primary);
                border-radius: 50px;
            }
        }
    }

    &.colorPattern1 {
        --primary: #5D508F;
        --secondary: #DB5A95;
        --primaryLightShade: #5D508F40;
        --white: #FFFFFF;
        --petrol: #103741;
        --Text99: #868799;
        --serviceClr1: #0ecaf0;
        --serviceClr2: #ffc106;
        --serviceClr3: #fe5d37;
        --serviceClr4: #5cb60b;
        --serviceClr5: #c168fe;
        --serviceClr6: #61a5a6;
        --contactContentBG: #5D508F;
        --contactIconBG1: #FFC106;
        --contactIconBG2: #0ECAF0;
        --contactIconBG3: #5CB60B;
        --contactUsBtnClr: #DB5A95;
        --learningContentBG: #5D508F;
        --classIconBG1: #FFC106;
        --classIconBG2: #0ECAF0;
        --classIconBG3: #5CB60B;
        --classIconBG4: #C168FE;
        --classIconBG5: #61A5A6;
        --classIconBG6: #E16279;
        --programBlkBG: #5D508F;
        --programIconClr: #5D508F;
        --activitiesIconBG1: #57509E;
        --activitiesIconBG2: #FFC106;
        --activitiesIconBG3: #0ECAF0;
        --activitiesIconBG4: #FE5D37;
        --activitiesIconBG5: #C168FE;
        --activitiesIconBG6: #5CB60B;
        --activitiesIconBG7: #E06279;
        --activitiesIconBG8: #61A5A6;
        --galleryHeadBG: #DB5A95;
        --galleryBandBG: #5D508F;
        --teacherBorderClr: #DB5A95;
        --footerBG: #DB5A95;
        --footerBandBG: #5D508F;
    }

    &.colorPattern2 {
        --primary: #33828F;
        --secondary: #DB9861;
        --primaryLightShade: #33828F40;
        --white: #FFFFFF;
        --petrol: #103741;
        --Text99: #868799;
        --serviceClr1: #0ecaf0;
        --serviceClr2: #ffc106;
        --serviceClr3: #fe5d37;
        --serviceClr4: #5cb60b;
        --serviceClr5: #c168fe;
        --serviceClr6: #61a5a6;
        --contactContentBG: #33828F;
        --contactIconBG1: #FFC106;
        --contactIconBG2: #0ECAF0;
        --contactIconBG3: #5CB60B;
        --contactUsBtnClr: #DB9861;
        --learningContentBG: #33828F;
        --classIconBG1: #FFC106;
        --classIconBG2: #0ECAF0;
        --classIconBG3: #5CB60B;
        --classIconBG4: #C168FE;
        --classIconBG5: #61A5A6;
        --classIconBG6: #E16279;
        --programBlkBG: #33828F;
        --programIconClr: #33828F;
        --activitiesIconBG1: #57509E;
        --activitiesIconBG2: #FFC106;
        --activitiesIconBG3: #0ECAF0;
        --activitiesIconBG4: #FE5D37;
        --activitiesIconBG5: #C168FE;
        --activitiesIconBG6: #5CB60B;
        --activitiesIconBG7: #E06279;
        --activitiesIconBG8: #61A5A6;
        --galleryHeadBG: #DB9861;
        --galleryBandBG: #33828F;
        --teacherBorderClr: #DB9861;
        --footerBG: #DB9861;
        --footerBandBG: #33828F;

    }

    &.colorPattern3 {
        --primary: #385C8F;
        --secondary: #DBC497;
        --primaryLightShade: #385C8F40;
        --white: #FFFFFF;
        --petrol: #103741;
        --Text99: #868799;
        --serviceClr1: #0ecaf0;
        --serviceClr2: #ffc106;
        --serviceClr3: #fe5d37;
        --serviceClr4: #5cb60b;
        --serviceClr5: #c168fe;
        --serviceClr6: #61a5a6;
        --contactContentBG: #385C8F;
        --contactIconBG1: #FFC106;
        --contactIconBG2: #0ECAF0;
        --contactIconBG3: #5CB60B;
        --contactUsBtnClr: #DBC497;
        --learningContentBG: #385C8F;
        --classIconBG1: #FFC106;
        --classIconBG2: #0ECAF0;
        --classIconBG3: #5CB60B;
        --classIconBG4: #C168FE;
        --classIconBG5: #61A5A6;
        --classIconBG6: #E16279;
        --programBlkBG: #385C8F;
        --programIconClr: #385C8F;
        --activitiesIconBG1: #57509E;
        --activitiesIconBG2: #FFC106;
        --activitiesIconBG3: #0ECAF0;
        --activitiesIconBG4: #FE5D37;
        --activitiesIconBG5: #C168FE;
        --activitiesIconBG6: #5CB60B;
        --activitiesIconBG7: #E06279;
        --activitiesIconBG8: #61A5A6;
        --galleryHeadBG: #DBC497;
        --galleryBandBG: #385C8F;
        --teacherBorderClr: #DBC497;
        --footerBG: #DBC497;
        --footerBandBG: #385C8F;

    }

    &.colorPattern4 {

        --primary: #57509E;
        --secondary: #FE5D37;
        --primaryLightShade: #57509E40;
        --white: #FFFFFF;
        --petrol: #103741;
        --Text99: #868799;
        --serviceClr1: #0ecaf0;
        --serviceClr2: #ffc106;
        --serviceClr3: #fe5d37;
        --serviceClr4: #5cb60b;
        --serviceClr5: #c168fe;
        --serviceClr6: #61a5a6;
        --contactContentBG: #57509E;
        --contactIconBG1: #FFC106;
        --contactIconBG2: #0ECAF0;
        --contactIconBG3: #5CB60B;
        --contactUsBtnClr: #FE5D37;
        --learningContentBG: #57509E;
        --classIconBG1: #FFC106;
        --classIconBG2: #0ECAF0;
        --classIconBG3: #5CB60B;
        --classIconBG4: #C168FE;
        --classIconBG5: #61A5A6;
        --classIconBG6: #E16279;
        --programBlkBG: #57509E;
        --programIconClr: #57509E;
        --activitiesIconBG1: #57509E;
        --activitiesIconBG2: #FFC106;
        --activitiesIconBG3: #0ECAF0;
        --activitiesIconBG4: #FE5D37;
        --activitiesIconBG5: #C168FE;
        --activitiesIconBG6: #5CB60B;
        --activitiesIconBG7: #E06279;
        --activitiesIconBG8: #61A5A6;
        --galleryHeadBG: #FE5D37;
        --galleryBandBG: #57509E;
        --teacherBorderClr: #FE5D37;
        --footerBG: #FE5D37;
        --footerBandBG: #57509E;

    }
}