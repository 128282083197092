.pdf-download {
    display: flex;
    flex-wrap: wrap;
    gap: 35px;

    .each-pdf-download {
        width: 110px;
        display: block;

        p {
            display: block;
            font-size: 12px;
        }
    }
}

.parent-portal {
    .pdf-download {
        display: flex;
        flex-wrap: wrap;
        gap: 35px;

        .each-pdf-download {
            width: 110px;
            display: block;

            p {
                display: block;
                font-size: 12px;
            }
        }
    }
}

.form-upload-sec {
    label {
        display: block;
        border: 1px dashed #FF3D00;
        text-align: center;
        padding: 40px;
        cursor: pointer;
    }

    input {
        display: none;
    }
}

.dailylog-wrp-tab {
    .tab-header {
        .MuiTabs-flexContainer {
            button:last-child {
                position: absolute;
                right: 0;
                top: 0;

                @media only screen and (max-width: 991px) {
                    & {
                        position: relative;
                        right: unset;
                        top: unset;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 991px) {
        .tab-sec-wrp .tab-body {
            border-radius: 0px 0 24px 24px;
        }
    }
}