.payment-status {
    font-weight: 500;
    text-transform: capitalize;
}

.status-pending {
    color: #ffba0a !important;
}

.status-completed {
    color: #1faf38 !important;
}

.status-cancelled {
    color: rgb(113, 124, 241) !important;
}

.status-failed {
    color: #bf0000 !important;
}

@media only screen and (max-width: 767px) {
    .button-sec {
        padding-top: 0 !important;
        .lft-btn-sec,
        .ryt-btn-sec {
            width: 100%;

            button {
                width: 100%;
                max-width: 100% !important;
                min-width: 100% !important;
                margin-top: 20px;
                color: #FFF4EE;
            }
        }
    }
}