.Francisca-teachingPlan-wrp {
    .row {
        &>div {
            margin-bottom: 30px;
        }
    }

    .each-approach-sec {
        display: flex;
        flex-direction: column;
        height: 100%;

        .approach-con-sec {
            background: var(--lytColor2);
            // border: 2px solid var(--Orange);
            border-radius: 12px;
            margin: 0 auto;
            float: none;
            margin-bottom: 10px;
            max-width: 325px;
            height: 100%;
            padding-bottom: 30px;

            .fee-sec-icon {
                width: 100%;
                height: 180px;
                display: block;
                border-radius: 20px;
                overflow: hidden;
                margin: 0 auto;
                // border: 15px solid #fff;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }

            h2 {
                text-align: center;
                font-family: var(--Baloo);
                font-weight: 700;
                margin: 0;
                margin-top: 5px;
                color: var(--Glaucous);
                padding: 15px 15px
            }

            p {
                text-align: center;
                color: var(--Text36);
                margin: 0;
                margin-top: 5px;
                padding-left: 15px;
                padding-right: 15px;
            }

            ul {
                padding-left: 40px;
                padding-right: 15px;
                list-style: disc;
                list-style-position: outside;

                li {
                    list-style: disc;
                    list-style-position: outside;
                    color: var(--Text36);
                }
            }
        }
    }

}