.policy-wrp{
    .rdw-editor-toolbar{
        visibility: visible !important;
        border: none;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 2;
    }
    .rdw-editor-main{
        border: 1px solid #B4AC9C;
        height: 100%;
        padding: 0 10px;
        border-radius: 4px;
        min-height: 275px;
        *{
         font-weight: inherit;   
        }
    }
}