.kathleenTemplates {
    .sqr-btn {
        display: inline-block;
        padding: 10px 40px;
        margin-top: 30px;
        border-radius: 8px;
    }

    .main-banner-wrp {
        .bannerBG {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            min-height: 100vh;

            .scroll-top,
            .scroll-bottom {
                position: absolute;
                left: 0;
            }

            .scroll-top {
                top: -1px;
            }

            .scroll-bottom {
                bottom: -1px;
            }

            @media only screen and (max-width: 650px) {
                & {
                    min-height: calc(100vh - 50px);
                }
            }

            span {
                display: block;
                width: 100%;
                height: 100%;

                img {
                    height: 100%;
                    object-fit: cover;
                    object-position: bottom;
                }

                &.scrollBG {
                    width: 50px;
                }
            }

            @media only screen and (min-width: 650px) {
                & {
                    .mobBG {
                        display: none;
                    }
                }
            }

            @media only screen and (max-width: 650px) {
                & {
                    .pcBG {
                        display: none;
                    }
                }
            }

            &+div {
                z-index: 9;
            }
        }

        .banner-align {
            display: flex;
            min-height: 100vh;
            width: 100%;
            align-items: center;
            height: 100%;

            @media only screen and (max-width: 650px) {
                & {
                    height: calc(100vh - 50px);
                    min-height: auto;
                    align-items: center;
                    padding-bottom: 25px;
                }
            }

            .banner-con-sec {
                max-width: 750px;
                padding: 15px 0 30px;
                @media only screen and (max-width: 650px) {
                    & {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                    }
                }
                .banner-con-box {
                    display: flex;


                    @media only screen and (min-width: 650px) {
                        & {
                            .mob-icon {
                                display: none;
                            }
                        }
                    }

                    @media only screen and (max-width: 650px) {
                        & {
                            .pc-icon {
                                display: none;
                            }

                            .mob-icon {
                                max-width: 230px;
                                width: 100%;
                                margin: 0 auto;
                            }
                        }
                    }

                    .banner-con {
                        flex: 2;

                        h1 {
                            margin: 0;
                            color: var(--White);
                            font-family: var(--Baloo);
                            font-weight: 700;
                            font-size: 52px;
                            line-height: 1.2;
                            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.50);
                        }

                        p {
                            margin-top: 10px;
                            margin-bottom: 0;
                            color: var(--White);
                            max-width: 475px;
                            font-weight: 500;
                        }

                        @media only screen and (max-width: 650px) {
                            & {
                                .pc-icon {
                                    display: none;
                                }

                                h1 {
                                    color: var(--White);
                                    font-size: 24px;
                                    text-align: center;

                                    // br {
                                    //     display: none;
                                    // }
                                }

                                p {
                                    text-align: center;
                                }
                            }
                        }
                    }

                    @media only screen and (max-width: 650px) {
                        & {
                            background: none;
                            flex-direction: column;
                            padding: 0;
                        }
                    }
                }

                .sqr-btn {
                    color: var(--White, #FFFFFF);
                    border-radius: 50px;

                    &:nth-child(1) {
                        background: var(--Orange);
                    }

                    &.child2   {
                        background: var(--Petrol);
                    }

                    &:not(:last-child) {
                        margin-right: 30px;
                    }

                    @media only screen and (max-width: 650px) {
                        & {
                            width: 100%;
                        }
                        &:not(:last-child) {
                            margin-right: 0px;
                        }
                    }
                }
            }

        }
    }

    .service-slider-sec {
        padding: 50px 0;

        .service-sec-header {
            text-align: center;
            padding-bottom: 50px;

            h1 {
                font-family: var(--Baloo);
                color: var(--Petrol, #103741);
                margin-bottom: 15px;
                font-weight: 700;
                margin-top: 0;
                font-size: 32px;
            }

            p {
                margin: 0;
                color: var(--Text36);
            }
        }
    }

    .about-section-wrp {
        .about-grid-wrp.f-wrp {
            display: flex;
            flex-wrap: wrap;
            gap: 100px;
            align-items: center;

            .about-con-sec,
            .about-img-sec {
                flex: 1;
            }

            .about-con-sec {
                text-align: left;

                h1 {
                    color: var(--Petrol);
                    font-family: var(--Baloo);
                    font-size: 32px;
                    line-height: 1;
                    font-weight: 700;

                    img {
                        height: 25px;
                        object-fit: contain;
                        object-position: left;
                        padding-left: 15px;
                        width: auto;
                    }
                }

                p {
                    color: var(--Text);
                }

                span {
                    font-family: var(--Cedarville);
                    color: var(--Purple);
                    font-size: 25px;
                }
            }

            @media only screen and (max-width: 1200px) {
                & {
                    gap: 30px;
                }
            }

            @media only screen and (max-width: 991px) {
                & {
                    flex-direction: column;
                }
            }
        }
    }

    .programs-slider-sec {
        .programs-sec-header {
            h1 {
                font-family: var(--Baloo);
                color: var(--Petrol, #103741);
                margin-bottom: 15px;
                font-weight: 700;
                margin-top: 0;
                font-size: 32px;
                text-align: center;
            }

            P {
                max-width: 660px;
                text-align: center;
                margin: 0 auto;
                color: var(--Text36);
            }
        }
    }

    .gallery-section-wrp {
        .gallery-sec-header {
            text-align: center;

            h1 {
                font-family: var(--Baloo);
                color: var(--Petrol, #103741);
                margin-bottom: 15px;
                font-weight: 700;
                margin-top: 0;
                font-size: 32px;
                text-align: center;
            }

            p {
                margin-top: 0;
            }
        }
    }

    .install-blk-wrp {

        .install-sec-blk {
            display: flex;
            max-width: 1920px;
            border-radius: 24px;
            overflow: hidden;

            @media only screen and (max-width: 767px) {
                & {
                    flex-direction: column;
                }
            }

            .install-con {
                flex: 1;
                background: var(--Orange300);

                &>.f-wrp {
                    max-width: 600px;
                    padding: 30px;
                    margin: 0 auto;
                    float: none;
                    // text-align: center;
                    color: var(--Text);
                }

                h1 {
                    margin-bottom: 10px;
                    font-size: 32px;
                    font-weight: 700;
                }

                h4 {
                    margin-top: 0;
                }

                h1,
                h4 {
                    font-family: var(--Baloo);
                }

                p {
                    max-width: 550px;
                    margin: 0 auto;
                }

                .sqr-btn {
                    background: var(--Orange);
                    color: var(--White);
                    width: 100%;
                    margin-top: 40px;
                }
            }

            .install-img {
                flex: 1.3;

                span {
                    position: relative;
                    width: 100%;
                    display: block;
                    height: 100%;

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: bottom;

                        @media only screen and (max-width: 767px) {
                            & {
                                position: relative;
                            }
                        }
                    }
                }
            }
        }
    }

    .approach-blk-wrp {
        .approach-sec-header {
            h1 {
                font-family: var(--Baloo);
                color: var(--Petrol, #103741);
                margin-bottom: 15px;
                font-weight: 700;
                margin-top: 0;
                font-size: 32px;
                text-align: center;
            }

            P {
                max-width: 660px;
                text-align: center;
                margin: 0 auto;
                color: var(--Text36);
            }
        }
    }

    .info-blk-wrp {

        .info-sec-blk {
            display: flex;
            max-width: 1920px;
            border-radius: 24px;
            overflow: hidden;

            @media only screen and (max-width: 767px) {
                & {
                    flex-direction: column-reverse;
                }
            }

            .info-con {
                flex: 1;
                background: var(--Orange300);

                &>.f-wrp {
                    max-width: 600px;
                    padding: 30px;
                    margin: 0 auto;
                    float: none;
                    // text-align: center;
                    color: var(--Text);
                }

                h1 {
                    margin-bottom: 10px;
                    font-size: 32px;
                    font-weight: 700;
                    margin-top: 0;
                }

                h4 {
                    margin-top: 0;
                }

                h1,
                h4 {
                    font-family: var(--Baloo);
                }

                p {
                    max-width: 550px;
                    margin: 0 auto;
                }

                ul {
                    padding-top: 25px;

                    li {
                        display: flex;

                        &:not(:last-child) {
                            margin-bottom: 25px;
                        }

                        span {
                            width: 25px;
                            height: 25px;
                            margin-right: 7px;

                            svg {
                                height: inherit;
                                width: inherit;
                            }
                        }
                    }
                }

                .sqr-btn {
                    background: var(--Orange);
                    color: var(--White);
                    width: 100%;
                    margin-top: 30px;
                }
            }

            .info-img {
                flex: 1.3;

                span {
                    position: relative;
                    width: 100%;
                    display: block;
                    height: 100%;

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: bottom;

                        @media only screen and (max-width: 767px) {
                            & {
                                position: relative;
                            }
                        }
                    }
                }
            }
        }
    }

    .teachers-blk-wrp {
        .teachers-sec-header {
            h1 {
                font-family: var(--Baloo);
                color: var(--Petrol, #103741);
                margin-bottom: 15px;
                font-weight: 700;
                margin-top: 0;
                font-size: 32px;
                text-align: center;
            }

            P {
                max-width: 660px;
                text-align: center;
                margin: 0 auto;
                color: var(--Text36);
            }
        }
    }

    .payment-blk-wrp {

        .payment-sec-blk {
            display: flex;
            max-width: 1920px;
            border-radius: 24px;
            overflow: hidden;

            @media only screen and (max-width: 767px) {
                & {
                    flex-direction: column;
                }
            }

            .payment-con {
                flex: 1;
                background: var(--Orange300);

                &>.f-wrp {
                    max-width: 600px;
                    padding: 30px;
                    margin: 0 auto;
                    float: none;
                    // text-align: center;
                    color: var(--Text);
                }

                h1 {
                    margin-bottom: 10px;
                    font-size: 32px;
                    font-weight: 700;
                }

                h4 {
                    margin-top: 0;
                }

                h1,
                h4 {
                    font-family: var(--Baloo);
                }

                p {
                    max-width: 550px;
                    margin: 0 auto;
                }

                .sqr-btn {
                    background: var(--Orange);
                    color: var(--White);
                    width: 100%;
                    margin-top: 40px;
                }
            }

            .payment-img {
                flex: 1.3;

                span {
                    position: relative;
                    width: 100%;
                    display: block;
                    height: 100%;

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: bottom;

                        @media only screen and (max-width: 767px) {
                            & {
                                position: relative;
                            }
                        }
                    }
                }
            }
        }
    }

    .testimonial-blk-wrp {
        .testimonial-sec-header {
            h1 {
                font-family: var(--Baloo);
                color: var(--Petrol, #103741);
                margin-bottom: 15px;
                font-weight: 700;
                margin-top: 0;
                font-size: 32px;
                text-align: center;
            }

            P {
                max-width: 660px;
                text-align: center;
                margin: 0 auto;
                color: var(--Text36);
            }
        }
    }
    @media only screen and (max-width: 650px) {
        .gap.f-wrp {
            & + .gap{
                display: none;
            }
        }
    }
}