.preview-popup {
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
        max-width: 100%;

        @media only screen and (max-width: 767px) {
            & {
                margin: 10px;
            }
        }

        .MuiDialogContent-root {
            &::-webkit-scrollbar {
                width: 0px;
            }
        }

        .back-btn-wrp {
            button {
                position: relative;
                float: right;
                width: 20px;
                height: 20px;
                overflow: hidden;
                border-radius: 0;
                color: #fff;

                &::after,
                &::before {
                    content: '';
                    width: 100%;
                    height: 2px;
                    background: #4C483E;
                    position: absolute;
                    left: 0;
                    top: 50%;
                }

                &::after {
                    transform: rotate(45deg);
                }

                &::before {
                    transform: rotate(-45deg);
                }
            }
        }

        .fill-btn {
            background: transparent;
            border-radius: 50px;
            border: 2px solid #FF7940;
            color: #FF7940;
            white-space: nowrap;
            padding: 6px 40px;
            font-size: 14px;
            cursor: pointer;
            margin: 0 auto;
            float: none;
            display: table;
            margin-top: 20px;
        }
    }
}

.view-sec-wrp {

    p,
    h3,
    h4 {
        color: #0F0A01;
        margin: 0;
        margin-bottom: 5px;
    }


    max-width: 991px;
    margin: 0 auto;
    float: none !important;
    background: #fff;
    // margin-top: 20px;
    // padding: 20px;
    border-radius: 8px;
    max-height: calc(100% - 40px);
    width: calc(100% - 30px);
    overflow-y: scroll;

    &.form-view-wrp {
        max-width: 750px;
    }

    &::-webkit-scrollbar {
        width: 0px;
    }

    .panel-form-sec {
        padding-top: 20px;
        padding-bottom: 0;

        .custom-textfield {
            width: 100%;
        }

        .button-sec.f-wrp {
            justify-content: flex-end;
            padding-top: 15px;
        }
    }

    .prof-sec {
        display: flex;

        .prof-img {
            width: 65px;

            img {
                border-radius: 100%;
            }
        }

        .prof-basic {

            h4 {
                margin: 0;
                font-weight: 700;
                margin-bottom: 5px;
            }

            p {
                margin: 0;
                font-size: 12px;
                font-weight: 400;

                b {
                    font-weight: 700;
                }
            }
        }
    }

    .details-section-wrp {
        padding-top: 20px;

        h3 {
            margin: 0;
            font-weight: 700;
            margin-bottom: 5px;
        }

        p {
            font-size: 14px;
            margin: 0;
            margin-bottom: 5px;
            display: flex;
            font-weight: 400;

            b {
                padding-right: 5px;
            }
        }
    }

    .f-wrp {
        float: none;
    }

    .gap {
        padding-top: 15px;
    }

    ul {
        li {
            font-size: 13px;
        }
    }

    .table-wrp {
        overflow-x: auto;

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }

    .auto-grid {
        display: grid;
        grid-template-columns: auto auto auto auto;
        justify-content: space-between;

        @media only screen and (max-width: 991px) {
            display: flex;
            flex-wrap: wrap;
        }

        div {
            display: flex;
            max-width: 182.5px;

            @media only screen and (max-width: 991px) {
                max-width: none;
                padding-right: 5px;
            }
        }
    }


    &.form-view-wrp {
        .prof-sec {
            padding-bottom: 10px;
        }


        h4 {
            margin: 0;
            font-weight: 700;
            margin-bottom: 10px;
            margin-top: 10px;
        }

        p {
            font-size: 13px;
            font-weight: 700;

            b {
                font-weight: 400;
            }
        }

        .details-section {
            width: 100%;
            display: flex;

            @media only screen and (max-width: 767px) {
                & {
                    flex-direction: column;
                    gap: 10px;
                    align-items: flex-start !important;

                    &.sign-blk,
                    &.c4k-img-sec {
                        align-items: center !important;
                    }

                }
            }
        }

        .lft-section {
            flex: 1;
            text-align: left;
            max-width: 10cm;
        }

        .ryt-section {
            flex: 1;
            text-align: left;
            max-width: 10cm;
        }

        .flex-box {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            gap: 5px;
        }

        .flex-box p {
            flex: 1;
            min-width: 4cm;
        }

        .details-section+h3 {
            margin-top: 10px;
        }

        table {
            width: 100%;
            /* font-size: 13px; */
            color: #4C483E;
        }

        table td {
            padding-bottom: 15px;
            font-size: 13px;
        }

        table th {
            font-size: 14px;
        }

        table td span {
            font-size: 12px;
            margin-right: 10px;
        }

        table td b {
            font-size: 12px;
            margin-left: 10px;
        }

        .signature-blk {
            width: 100%;
            padding-top: 15px;
        }

        .signature-blk img {
            width: 100%;
            height: 80px;
            object-fit: contain;
            margin-bottom: 10px;
            display: block;
        }

        .signature-blk h2,
        .signature-blk h3 {
            font-family: 'Updock', cursive;
            font-size: 32px;
            margin: 0;
            font-weight: normal;
            color: #0F0A01;
        }

        .auto-flex {
            display: flex;
            width: 100%;

            @media only screen and (max-width: 767px) {
                & {
                    flex-wrap: wrap;
                }
            }
        }

        .auto-flex span {
            margin-right: 10px;
            white-space: nowrap;
            font-size: 13px;
            font-weight: 700;
        }

        .auto-flex p {
            text-align: left;
        }

        .basic-details {
            width: 100%;
        }

        .basic-details .auto-flex {
            padding-bottom: 6px;
        }

        .signature-blk {
            width: 100%;
            padding-top: 15px;

            @media only screen and (max-width: 767px) {
                & {
                    display: block !important;

                    div {
                        padding-left: 0 !important;
                        float: none !important;
                        min-width: 100% !important;
                        text-align: center;
                    }
                }
            }
        }

        .check-box {
            padding-bottom: 7px;

            p {
                padding-right: 10px;
            }

            ul {
                li {
                    display: block;
                    font-size: 15px;
                    color: #0F0A01;
                    position: relative;
                    padding-left: 25px;

                    &:not(:last-child) {
                        padding-right: 20px;
                    }

                    &::before {
                        content: '';
                        width: 25px;
                        height: 18px;
                        display: inline-block;
                        vertical-align: middle;
                        position: absolute;
                        left: 0;
                        top: 2px;
                        background-image: url('../images/icons/viewUnCheck.svg');
                        background-size: contain;
                        background-repeat: no-repeat;
                    }

                    &.checked::before {
                        background-image: url('../images/icons/viewCheck.svg');
                    }

                }
            }

            &.inline {
                display: flex;
                padding-right: 20px;
                margin-bottom: 7px;

                @media only screen and (max-width: 767px) {
                    & {
                        flex-direction: column;
                        gap: 10px;
                    }
                }

                p {
                    margin: 0;
                }

                ul {
                    li {
                        display: inline-block;

                        @media only screen and (max-width: 767px) {
                            & {
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
                &.li-minWidth{
                    li{
                        min-width: 150px;
                        margin-top: 7px;
                    }

                }
            }
        }

        .img-size,
        .img-size-unchecked {
            width: 12px;
            height: 12px;
            display: inline-block;
            margin-right: 5px;
            margin-top: 2px;
        }

        .parents-rights-sec {
            li {
                img {
                    display: none !important;
                }
            }
        }

        .check-box.inline ul {
            display: flex;
            flex-wrap: wrap;
        }

        .check-box.inline p {
            padding-top: 5px;
        }

        &.emergencyNumbersView {
            table {
                padding-bottom: 15px;

                td {
                    padding-bottom: 5px;

                    b {
                        margin-left: 0;
                    }
                }
            }
        }

        &.writtenPermissionView {
            max-width: 850px;
            .auto-flex.width25 {
                flex-wrap: wrap;

                div {
                    width: 25%;
                    display: flex;
                }
            }

            .auto-flex {
                .flex-box {
                    p {
                        min-width: auto;

                        @media only screen and (max-width: 767px) {
                            & {
                                min-width: 4cm;
                            }
                        }
                    }
                }
            }

            ul {
                padding-left: 15px;

                li {
                    list-style: disc;
                    color: #0F0A01;
                    padding-bottom: 10px;
                }
            }
        }

        &.ECHARView {
            max-width: 991px;

            p {
                font-size: 13px;
            }

            .prof-sec {
                align-items: center;
            }

            .lft-section {
                max-width: 100%;
            }

            .ryt-section {
                max-width: 100%;
            }

            .auto-grid.custom-grid {
                grid-template-columns: max-content;
                grid-template-areas: 'customArea customArea .' 'customArea2 customArea2 .';
                justify-content: unset;

                // p:nth-child(1) {
                //     grid-area: customArea;
                // }

                // p:nth-child(2) {
                //     grid-area: customArea2;
                // }

                p {
                    text-align: left;
                    margin-bottom: 8px;
                    min-width: 100%;
                    padding-right: 20px;

                    @media only screen and (max-width: 991px) {
                        min-width: auto;
                        padding-right: 5px;
                    }

                    @media only screen and (max-width: 550px) {
                        min-width: 100%;
                    }
                }

            }
        }

        &.ppAgreementView {
            max-width: 1070px;
            padding: 30px;

            @media only screen and (max-width: 1100px) {
                & {
                    max-width: 100%;
                    padding: 0;
                }
            }

            table {
                td {
                    text-align: center;
                }

                th {
                    font-weight: 700;
                    padding-bottom: 10px;
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }

            p {
                font-size: 13px;
                font-weight: 400;
                color: #4C483E;

                b {
                    font-weight: 700;
                    color: #0F0A01;
                }
            }

            .auto-flex {
                p {
                    &:not(:last-child) {
                        padding-right: 20px;
                    }
                }
            }

            .prof-sec {
                align-items: center;
            }

            .lft-section {
                max-width: 100%;
            }

            .ryt-section {
                max-width: 100%;
            }

            h2,
            h3,
            h4 {
                color: #4C483E;
            }

            .auto-flex {
                flex-wrap: wrap;
            }

            ul {
                li {
                    font-size: 13px;
                    color: #4C483E;
                }

                &.bullet-list {
                    list-style: disc;
                    list-style-position: inside;
                    padding-left: 15px;

                    li {
                        list-style: disc;
                    }
                }
            }

        }

    }

}

@media only screen and (min-width: 600px) {
    .width175 {
        width: 175px;
    }
}

.flex1-5 {
    flex: 1.5 !important;
}

.childEnrollmentView {
    .flex1-5+.ryt-section {
        h2 {
            display: none;
        }
    }
    h2{
        font-family: 'Times New Roman', Times, serif;
        font-weight: 700;
    }
    p {
        font-size: 15px !important;
        font-family: 'Times New Roman', Times, serif;

        b {
            font-size: 13px;
        }

    }
    .fontWeight-normal{
        p{
            font-weight: 400;
        }
    }
}

.selfEmploymentView{
    .details-section{
        align-items: center;
        .ryt-section{
            padding-left: 10px;
            max-width: 30% !important;
        }
    }
    .number-list{
        li{
            list-style: decimal;
            list-style-position: inside;
            ul{
                li{
                    list-style: disc;
                    list-style-position: inside;
                }
            }
        }
    }
}


@media only screen and (min-width: 767px) {

    .parentCommunicationView,
    .providerCommunicationView {
        min-width: 600px;
    }
}

.emergencyNumbersView,
.specialNeedView,
.disabilityVerificationView {
    &+.fill-btn {
        display: none !important;
    }
}