.TemplatesOne-testimonial-sec-wrp {
    margin: 15px auto;
    max-width: 1070px;
    display: block;
    & > .f-wrp{
        padding-left: 50px;
        padding-right: 50px;
    }

    .swiper-wrapper {
        align-items: center;
    }

    .each-testimonial-items {
        padding: 25px;
        padding-right: 0;
        background: var(--primaryLightShade);
        border-radius: 15px;
        max-width: 475px;
        margin: 0 auto;
        float: none;

        p {
            padding-bottom: 20px;
        }

        h2 {
            font-family: var(--Baloo);
            background: var(--white);
            display: flex;
            align-items: center;
            font-weight: 700;
            font-size: 18px;
            color: var(--petrol);
            position: relative;
            padding-right: 55px;
            border-radius: 40px 0 0 40px;
            span {
                color: var(--white);
                font-family: var(--Baloo);
                font-size: 32px;
                display: flex;
                width: 75px;
                height: 75px;
                border-radius: 100%;
                text-align: center;
                align-items: center;
                justify-content: center;
                margin-right: 15px;
                font-weight: 700;
                &.authImg{
                    overflow: hidden;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
                &.shortName{
                    background: var(--primary);
                    padding: 15px;
                }
                &.quats{
                    position: absolute;
                    right: 10px;
                    margin: 0;
                    width: 40px;
                    top: 0;
                    svg{
                        width: 40px;
                        height: 40px;
                        path{
                            fill: var(--primary);
                        }
                    }
                }
            }
        }

        p {
            color: var(--Text36);
            margin-top: 0;
            padding-right: 25px;
        }
    }
    .swiper-button-prev{
        left: 5px;
    }
    .swiper-button-next{
        right: 5px;
    }
    .swiper-button-prev,
    .swiper-button-next {
        padding: 0;
        background: transparent;
        border: none;
        opacity: 0;
        transition: 0.5s all;

        svg {
            width: 35px;
            height: 35px;

            path {
                fill: var(--primary);
            }
        }

    }

    &:hover {

        .swiper-button-prev,
        .swiper-button-next {
            opacity: 1;
            transition: 0.5s all;
        }
    }

    .swiper-button-prev::after,
    .swiper-button-next::after {
        display: none;
    }

}