.submit-btn {
    display: inline-block;
    height: 45px;
    padding: 0px 30px;
    border-radius: 50px;
    border: 2px solid #FF7940;
    min-width: 140px;
    font-weight: 700;
    background-color: #FF3D00;
    color: #FFF4EE;
    border-color: #FF3D00;
    box-shadow: none;
    max-width: max-content;
    min-width: max-content;
}