.gallery-sec-wrp.TemplatesTwo{
    display: flex;
    float: none;
    margin: 0 auto;
    max-width: 1400px;
    padding-left: 44px;
    padding-right: 44px;

    .gallery-image {
        height: 350px;
        display: block;
        width: 100%;        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .swiper-button-prev{
        left: 5px;
    }
    .swiper-button-next{
        right: 5px;
    }

    .swiper-button-prev,
    .swiper-button-next {
        padding: 0;
        background: transparent;
        border: none;
        opacity: 0;
        transition: 0.5s all;

        svg {
            width: 35px;
            height: 35px;
            path {
                fill: var(--primary);
            }
        }

    }

    &:hover {
        .swiper-button-prev,
        .swiper-button-next {
            opacity: 1;
            transition: 0.5s all;
        }
    }



    .swiper-button-prev::after,
    .swiper-button-next::after {
        display: none;
    }

    &:hover {

        .swiper-button-prev::after,
        .swiper-button-next::after {
            opacity: 1;
            transition: 0.5s all;
        }
    }









}