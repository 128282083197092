.app-btn-reusable {
    width: max-content;
    height: 35px;
    padding: 8px;
    display: flex;
    align-items: center;
    border-radius: 50px;
    background: #FF3D00;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    box-shadow: none;
    min-width: 80px;
    justify-content: center;
    span{
        font-size: 13px;
        margin-right: 0;
        margin-left: 0;
    }
    .MuiLoadingButton-loadingIndicator.MuiLoadingButton-loadingIndicatorStart{
        left: -2px;
        width: 15px;
        height: 15px;
        span{
            width: 15px !important;
            height: 15px !important;
        }
    }
    
}
.app-btn-reusable:hover {
    background-color: #FF3D00 !important;
    opacity: 0.9;
}

.pending,
.complete{
    min-width: 80px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    svg{
        width: 17px;
        height: 17px;
        margin-right: 2px;
    }
}
.pending{
    color: #FFBA0A !important;
    background: #FFF7E2 !important;
}
.complete{
    background: #EDF7ED !important;
    color: #1FAF38 !important;
}

.logout-btn::after{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 9;
}