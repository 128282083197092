.friendlyFamily-Popup {
    &.ppContact-popup {
        max-width: calc(100% - 20px);
        margin: 0 auto;

        .pp-contact-head {
            display: flex;
            justify-content: space-between;
            height: 50px;
            margin-bottom: 10px;
            align-items: center;

            p {
                font-size: 24px;
                font-weight: 700;
                color: var(--Text36);
                margin-top: 0;
                margin-bottom: 0px;

                @media screen and (max-width: 500px) {
                    & {
                        font-size: 20px;
                    }
                }
            }

            button {
                padding: 0;
                min-width: 0;
                min-height: 0;
                max-height: max-content;
                height: max-content;

                svg {
                    path {
                        fill: var(--Text36);
                    }
                }
            }
        }

        .pp-contact-form {
            max-width: 550px;
            margin: 0 auto;
            float: none;
            background: var(--white);
            padding: 30px;
            margin-top: 50px;
            border-radius: 15px;

            label.MuiInputLabel-formControl {
                font-size: 13px;
            }

            .MuiInputBase-root.MuiOutlinedInput-root {
                width: 100%;

            }

            .MuiOutlinedInput-notched {
                border-color: #98948A;
            }

            textarea {
                color: var(--Text36);
            }

            .app-text-input-container {
                .MuiFormControl-root {
                    .MuiInputBase-formControl {
                        input {
                            color: var(--Text36);
                        }

                        input:-internal-autofill-selected {
                            background-color: #fff !important;
                        }

                        input:-webkit-autofill,
                        input:-webkit-autofill:hover,
                        input:-webkit-autofill:focus,
                        textarea:-webkit-autofill,
                        textarea:-webkit-autofill:hover,
                        textarea:-webkit-autofill:focus,
                        select:-webkit-autofill,
                        select:-webkit-autofill:hover,
                        select:-webkit-autofill:focus {
                            -webkit-text-fill-color: var(--Text36) !important;
                            caret-color: var(--Text36) !important;

                            .light-bg & {
                                -webkit-text-fill-color: var(--Text36) !important;
                                caret-color: var(--Text36) !important;
                                -webkit-box-shadow: 0 0 0 300px var(--Text36) inset !important;
                            }
                        }
                    }
                }
            }
        }

        .MuiFormControl-root.MuiTextField-root .MuiOutlinedInput-notchedOutline,
        .MuiFormControl-root .MuiOutlinedInput-notchedOutline {
            border-color: var(--Text36);
        }

        .pp-contact-frm {
            .login-btn {
                background: var(--friendlyButtonBg);
                color: var(--White);
                border-radius: 50px;
                &.Mui-disabled{
                    cursor: not-allowed;
                }
            }
        }
    }
}

