.tab-sec-wrp {
    .tab-header {
        button {
            font-family: 'Andika', sans-serif;
            font-size: 18px;
            color: #5D5848;
            border-radius: 16px 16px 0px 0px;
            background: rgba(15, 10, 1, 0.50);
            min-width: 150px;
            @media only screen and (max-width: 991px) {
                border-radius: 0;
                min-width: auto;
                font-size: 16px;
            }

            &.Mui-selected {
                color: #FFF4EE;
                background: #0F0A01;
            }
        }

        .MuiTabs-indicator {
            background: #0F0A01;
        }

        .MuiTabs-scroller {
            &::-webkit-scrollbar {
                width: 0px;
                height: 0;
            }
        }

        @media only screen and (max-width: 991px) {
            .MuiTabs-scroller {
                overflow-x: scroll !important;
                // width: calc(100% - 25px);
                // min-width: calc(100% - 25px);
                // max-width: calc(100% - 25px);
                border-bottom: 1px solid;
            }
        }
    }

    .tab-body {
        background: #0F0A01;
        border-radius: 0px 24px 24px 24px;
        @media only screen and (max-width: 991px) {
            border-radius: 0 !important;
        }
    }
}