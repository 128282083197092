.Francisca-testimonial-sec-wrp {
    margin: 15px auto;
    max-width: 1070px;
    display: block;
    
    .each-testimonial-items {
        padding: 25px;
        padding-right: 0;
        background: var(--GreenLight);
        border-radius: 15px;
        max-width: 475px;
        margin: 0 auto;
        float: none;
        h2 {
            font-family: var(--Baloo);
            background: var(--White);
            display: flex;
            align-items: center;
            font-weight: 700;
            font-size: 18px;
            color: var(--Glaucous);
            position: relative;
            padding-right: 55px;
            border-radius: 40px 0 0 40px;
            &::after{
                content: '';
                width: 40px;
                height: 100%;
                display: block;
                background: url('../images/icons/quats.svg');
                position: absolute;
                right: 10px;
                top: 0;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }
            span.user {
                display: flex;
                width: 75px;
                height: 75px;
                border-radius: 100%;
                align-items: center;
                justify-content: center;
                margin-right: 15px;
                font-weight: 400;
            }
            span.shortname {
                color: var(--White);
                font-family: var(--Baloo);
                font-size: 28px;
                padding: 15px;
                display: flex;
                background: var(--Green1C);
                width: 75px;
                height: 75px;
                border-radius: 100%;
                letter-spacing: 3px;
                text-align: center;
                align-items: center;
                justify-content: center;
                margin-right: 15px;
                font-weight: 700;
                padding-left: 20px;
            }
        }

        p {
            color: var(--Text36);
            margin-top: 0;
            padding-right: 25px;
        }
    }
    .swiper-button-prev{
        left: 0;
    }
    .swiper-button-next{
        right: 0;
    }
    .swiper-button-prev::after,
    .swiper-button-next::after {
        content: '';
        width: 30px;
        height: 50px;
        display: block;
        object-fit: contain;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 1;
        transition: 0.5s all;
    }

    &:hover {
        .swiper-button-prev::after,
        .swiper-button-next::after {
            opacity: 1;
            transition: 0.5s all;
        }
    }

    .swiper-button-prev::after {
        background-image: url('../images/icons/slideLeft.svg');
    }

    .swiper-button-next::after {
        background-image: url('../images/icons/slideRight.svg');
    }

}