.gallery-sec-wrp.yrene{
    margin: 15px 0;
    padding-bottom: 50px;
    .gallery-image{
        display: block;
        margin: 0 auto;
        border-radius: 100%;
        overflow: hidden;
        transition: 0.5s all;
        position: relative;
        width: 85%;
        padding-bottom: 85%;
        &:hover{
            border-radius: 10px;
            transition: 0.5s all;
        }
        img{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    .swiper-button-prev::after,
    .swiper-button-next::after {
        content: '';
        width: 25px;
        height: 50px;
        display: block;
        object-fit: contain;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0;
        transition: 0.5s all;
    }

    &:hover {
        .swiper-button-prev::after,
        .swiper-button-next::after {
            opacity: 1;
            transition: 0.5s all;
        }
    }

    .swiper-button-prev::after {
        background-image: url('../images/icons/slideLeft.svg');
    }

    .swiper-button-next::after {
        background-image: url('../images/icons/slideRight.svg');
    }
}