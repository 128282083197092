.parent-inbox-wrp {
    padding: 0;

    .red-btn{
        background-color: #FF3D00;
        color: #fff !important;
        margin-bottom: 25px;
        text-transform: none;
        @media only screen and (max-width: 600px) {
            &{
                position: fixed;
                z-index: 1;
                right: 15px;
                bottom: 20px;
                margin: 0;
            }
        }
    }

    .inbox-con-wrp {
        background: #0F0A01;
        border-radius: 24px;
        padding: 24px;

        div {
            color: #FFFFFF !important;
        }
        .app-grid-tool-bar{
            margin-bottom: 20px;
            .MuiButtonBase-root{
                display: none;
            }
        }
        .MuiDataGrid-columnHeaders{
            border-color: transparent;
        }
        // .MuiDataGrid-columnHeaders {
        //     display: none;
        // }

        // .MuiDataGrid-row:nth-child(even) {
        //     background-color: #211e1d;
        // }

        .MuiDataGrid-row {
            .MuiDataGrid-cell:first-child {
                .MuiDataGrid-cellContent {
                    font-weight: 700;
                }
            }
        }
        @media only screen and (max-width: 600px) {
            margin-bottom: 70px;
        }
    }

}

.parent-inbox-view {
    background-color: #fff;
    padding: 24px;
    border-radius: 24px;
    padding-top: 50px;

    // * {
    //     color: #FFFFFF;
    // }

    .prof-sec {
        display: flex;
        margin-top: 25px;

        .prof-img {
            width: 75px;

            img {
                border-radius: 100%;
            }
        }

        .prof-basic {
            padding-left: 20px;

            h4 {
                margin: 0;
                font-weight: 700;
            }

            p {
                margin: 0;
                font-weight: 400;
                font-size: 14px;

                b {
                    font-weight: 700;
                }
            }
        }
    }

    .inbox-content {
        border-top: 1px solid #98948A;
        border-bottom: 1px solid #98948A;
        margin: 25px 0;

        p {
            font-size: 14px;
        }
        .readonly-editor{
            span, *{
                font-weight: inherit;
                font-size: inherit;
                text-decoration: inherit;
                font-family: inherit;
                overflow-wrap: inherit;
                font-style: inherit;
            }
        }
    }
    
}
.new-message{
    .btn-wrp {
        display: flex;
        gap: 10px;
    }
}
.add-new-message-layout{
    h3{
        margin: 0;
    }
    .app-text-input-container {
        .form-group{
            .custom-textfield{
                .MuiInputBase-root{
                    input:-webkit-autofill,
                    input:-webkit-autofill:hover,
                    input:-webkit-autofill:focus,
                    textarea:-webkit-autofill,
                    textarea:-webkit-autofill:hover,
                    textarea:-webkit-autofill:focus,
                    select:-webkit-autofill,
                    select:-webkit-autofill:hover,
                    select:-webkit-autofill:focus {
                        -webkit-text-fill-color: #6C6C6C !important; 
                        caret-color: #6C6C6C !important;
                        -webkit-box-shadow: 0 0 0 300px #fff inset !important;
                    }
                }
            }
        }
    }
    .editorClassName.rdw-editor-main{
        min-height: 100px;
        span, *{
            font-weight: inherit;
            font-family: inherit;
        }
    }
}
.back-btn-wrp {
    button {
        font-size: 13px;
    }

    svg {
        width: 10px;
        height: 14px;
        margin-right: 4px;
    }
}

.modal-popup {
    .MuiDialog-container {
        .MuiPaper-root {
            max-width: 1020px;
        }
    }
}

.inbox-popup {
    .MuiPaper-root.MuiPaper-elevation {
        background: transparent;
        box-shadow: none;

        &::-webkit-scrollbar {
            width: 0px;
        }
    }

    .MuiDialogContent-root {
        padding: 0;
        background: transparent;
        box-shadow: none;

        &::-webkit-scrollbar {
            width: 0px;
        }

        .back-btn-wrp {
            position: absolute;
            left: 15px;
            top: 15px;
            z-index: 1;

            // button {
            //     color: #FFFFFF;
            // }
        }
    }
}